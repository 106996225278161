<template>
  <div id="taxonomies">
    <v-card max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4"> Taxonomies </v-card-title>
        <v-spacer />
        <v-btn
          color="primary"
          class="mx-4"
          @click="createItem"
          :disabled="offline"
        >
          New Taxonomy
        </v-btn>
      </v-row>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <div class="px-4">
        <v-data-table
          :headers="taxonomiesHeaders"
          :items="taxonomies"
          :sort-desc="sortDesc"
          :sort-by="sortBy"
          must-sort
          :single-expand="true"
          :expanded.sync="expanded"
          show-expand
          :search="search"
          :loading="!taxonomySubscriptionSynced && taxonomies?.length === 0"
          loading-text="Loading Taxonomies..."
          no-data-text="No Taxonomies found"
        >
          <template v-slot:[`item.cognitiveLevels`]="{ item }">
            {{ cognitiveLevelSummary(item.cognitiveLevels) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                  :disabled="offline"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Edit Taxonomy</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="createCognitiveLevel(item)"
                  :disabled="offline"
                >
                  mdi-playlist-plus
                </v-icon>
              </template>
              <span>Add Cognitive Level</span>
            </v-tooltip>
            <!-- <v-icon small @click="deleteItem(item)" :disabled="offline">
              mdi-delete
            </v-icon> -->
          </template>
          <template v-slot:[`expanded-item`]="{ headers, item }">
            <td :colspan="headers.length" class="pa-3">
              <v-data-table
                v-if="item.cognitiveLevels"
                :headers="cognitiveLevelHeaders"
                :items="item.cognitiveLevels"
                item-key="tmpid"
                hide-default-footer
              >
                <template v-slot:[`item.color`]="{ item }">
                  <v-icon :color="item.color"> mdi-circle </v-icon>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="editCognitiveLevel(item)"
                        :disabled="offline"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Edit Cognitive Level</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog persistent v-model="dialog" max-width="500px">
      <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent="save">
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.name"
                    label="Name"
                    :rules="formRules.name"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="close"> Cancel </v-btn>
            <v-btn :disabled="!valid" color="primary" @click="save">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogCognitiveLevel" max-width="600px">
      <v-card>
        <v-form
          ref="formCognitiveLevel"
          v-model="validCognitiveLevel"
          @submit.prevent="saveCognitiveLevel"
        >
          <v-card-title>
            <span class="text-h5">{{ formTitleCognitiveLevel }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="editedCognitiveLevel.label"
                    label="Label"
                    :rules="formRules.label"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="editedCognitiveLevel.percentage"
                    label="Label"
                    :rules="formRules.percentage"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="closeDialogCognitiveLevel">
              Cancel
            </v-btn>
            <v-btn
              :disabled="!validCognitiveLevel"
              color="primary"
              @click="saveCognitiveLevel"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center"
          >Are you sure you want to delete this taxonomy?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDelete">Cancel</v-btn>
          <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore } from "aws-amplify";
import { Taxonomy } from "@/models";
import _ from "lodash";

export default {
  name: "AdminTaxonomies",
  data: () => ({
    initiallyLoaded: false,
    taxonomySubscription: null,
    taxonomySubscriptionSynced: false,
    syncedTaxonomyArray: [],
    valid: false,
    validCognitiveLevel: false,
    dialog: false,
    dialogCognitiveLevel: false,
    dialogDelete: false,
    sortBy: "name",
    sortDesc: true,
    search: "",
    taxonomiesHeaders: [
      { text: "Name", value: "name" },
      { text: "Cognitive Levels", value: "cognitiveLevels" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    cognitiveLevelHeaders: [
      { text: "Label", value: "label", sortable: false },
      { text: "Percentage", value: "percentage", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    taxonomies: [],
    editedIndex: -1,
    editedItem: {
      name: "",
    },
    defaultItem: {
      name: "",
    },
    formRules: {
      name: [(v) => !!v || "Name is required"],
      percentage: [(v) => !!v || "Percentage is required"],
    },
    expanded: [],
    editedCognitiveLevel: {
      label: "",
      percentage: null,
    },
    defaultCognitiveLevel: {
      label: "",
      percentage: null,
    },
  }),

  async mounted() {
    try {
      // Sync the Taxonomies from the datastore
      await this.syncTaxonomies();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Taxonomy" : "Edit Taxonomy";
    },
    formTitleCognitiveLevel() {
      return this.editedIndex === -1
        ? "New Cognitive Level"
        : "Edit Cognitive Level";
    },
    currentTaxonomy() {
      return _.find(this.syncedTaxonomyArray, { id: this.expanded[0].id });
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    cognitiveLevelSummary(v) {
      // console.log(v);
      if (v) {
        let summary = "";
        let total = 0;
        v.forEach((cognitiveLevel) => {
          summary +=
            cognitiveLevel.label + ": " + cognitiveLevel.percentage + "%, ";
          total += cognitiveLevel.percentage;
        });
        return summary.slice(0, -2) + " - Total: " + total + "%";
      }
      return "";
    },
    resetFormCognitiveLevel() {
      if (this.$refs.formCognitiveLevel) this.$refs.formCognitiveLevel.reset();
      this.editedCognitiveLevel = Object.assign({}, this.defaultCognitiveLevel);
      this.validCognitiveLevel = false;
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
      this.valid = false;
    },

    createCognitiveLevel(item) {
      this.editedItem = Object.assign({}, item);
      this.resetFormCognitiveLevel();
      this.dialogCognitiveLevel = true;
    },

    createItem() {
      this.resetForm();
      this.dialog = true;
    },

    editCognitiveLevel(cognitiveLevel) {
      this.editedIndex = _.findIndex(
        this.currentTaxonomy.cognitiveLevels,
        function (o) {
          return (
            o.label === cognitiveLevel.label &&
            o.percentage === cognitiveLevel.percentage
          );
        }
      );
      this.editedItem = this.currentTaxonomy;
      this.editedCognitiveLevel = Object.assign({}, cognitiveLevel);
      this.dialogCognitiveLevel = true;
    },

    editItem(item) {
      this.editedIndex = this.taxonomies.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.taxonomies.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      // this.holidays.splice(this.editedIndex, 1);
      await DataStore.delete(this.syncedTaxonomyArray[this.editedIndex]);
      this.closeDelete();
    },

    closeDialogCognitiveLevel() {
      this.dialogCognitiveLevel = false;
      this.$nextTick(() => {
        this.editedCognitiveLevel = Object.assign(
          {},
          this.defaultCognitiveLevel
        );
        this.editedIndex = -1;
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async saveCognitiveLevel() {
      if (this.editedIndex > -1) {
        // Update cognitiveLevel
        let mutableObj = _.cloneDeep(this.editedItem);
        mutableObj.cognitiveLevels[this.editedIndex] =
          this.editedCognitiveLevel;
        this.editedItem = Object.assign({}, mutableObj);
      } else {
        // Create new biweek
        let mutableObj = _.cloneDeep(this.editedItem);
        mutableObj.cognitiveLevels =
          mutableObj.cognitiveLevels && mutableObj.cognitiveLevels.length > 0
            ? mutableObj.cognitiveLevels
            : [];
        mutableObj.cognitiveLevels.push(this.editedCognitiveLevel);
        this.editedItem = Object.assign({}, mutableObj);
      }
      await this.updateTaxonomy();
      this.closeDialogCognitiveLevel();
    },

    async save() {
      if (this.editedIndex > -1) {
        // Update taxonomy
        await this.updateTaxonomy();
      } else {
        // Create new taxonomy
        await this.createTaxonomy();
      }
      this.close();
    },

    async createTaxonomy() {
      try {
        await DataStore.save(
          new Taxonomy({
            name: this.editedItem.name.trim(),
            cognitiveLevels: this.editedItem.cognitiveLevels,
          })
        );
      } catch (error) {
        console.log(error);
      }
    },

    async updateTaxonomy() {
      // console.log(this.editedItem);
      try {
        if (this.editedItem.cognitiveLevels) {
          this.editedItem.cognitiveLevels.forEach((cognitiveLevel) => {
            cognitiveLevel.label = cognitiveLevel.label.trim();
            cognitiveLevel.percentage = parseInt(cognitiveLevel.percentage);
          });
        }
        await DataStore.save(
          Taxonomy.copyOf(
            _.find(this.syncedTaxonomyArray, { id: this.editedItem.id }),
            (updateModel) => {
              (updateModel.name = this.editedItem.name.trim()),
                (updateModel.cognitiveLevels = this.editedItem.cognitiveLevels);
            }
          )
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncTaxonomies() {
      try {
        this.taxonomySubscription = DataStore.observeQuery(Taxonomy).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedTaxonomyArray !== items) {
              this.syncedTaxonomyArray = items;

              // this.taxonomies = items.map((newObj) => {
              //   const newObjCopy = _.cloneDeep(newObj);
              //   if (newObjCopy.cognitiveLevels)
              //     newObjCopy.cognitiveLevels.forEach((cognitiveLevel) => {
              //       cognitiveLevel.label = cognitiveLevel.label.trim();
              //       cognitiveLevel.percentage = parseInt(
              //         cognitiveLevel.percentage
              //       );
              //     });
              //   return newObjCopy;
              // });

              this.taxonomies = _.cloneDeep(items);

              this.taxonomySubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.taxonomySubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    if (this.taxonomySubscription) {
      this.taxonomySubscription.unsubscribe();
    }
  },
};
</script>
