<template>
    <v-snackbar id="offlinewarningsnackbar" top :value="snackbarModel" color="secondary" :timeout="-1" light multi-line
        :vertical="isSmallScreen" :class="isSmallScreen ? 'snackbarsmall' : 'snackbardefault'">
        <v-row no-gutters class="justify-center align-center" :class="isSmallScreen ? 'px-4 pt-4' : 'pl-4 pr-2'"
            style="gap: 16px;">
            <div>
                <v-icon style="color:rgba(0, 0, 0, 0.87);">mdi-alert-circle-outline</v-icon>
            </div>
            <div class="text-center">
                You're offline, functionality may be limited.
            </div>
        </v-row>
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbarModel = false">Close</v-btn>
        </template>
    </v-snackbar>
</template>
  
<script>
export default {
    name: 'OfflineWarning',
    data: () => ({
        snackbarModel: true,
        isSmallScreen: true
    }),
    mounted() {
        window.addEventListener('resize', this.updateWindowSize);
        this.updateWindowSize();
    },
    methods: {
        updateWindowSize() {
            this.isSmallScreen = window.innerWidth < 600;
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateWindowSize);
    }
}
</script>
<style>
#offlinewarningsnackbar .v-snack__content {
    margin: 0 !important;
    padding: 0 !important;
}

#offlinewarningsnackbar .v-snack__wrapper {
    min-width: 0 !important;
}

.snackbarsmall .v-snack__action {
    align-self: center !important;
}
</style>