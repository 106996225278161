<template>
    <v-dialog id="templateprinterdialog" ref="templatePrinterDialog" v-model="printDialog" fullscreen hide-overlay>
        <v-card v-if="template">
            <v-card-title>
                <span class="headline">Preview</span>
            </v-card-title>
            <v-card-text>
                <!-- Your print-friendly content goes here -->
                <div id="printable-content">
                    <h1>{{ template.name }}</h1>
                    <p>Type: {{ template.type }}</p>
                    <p>Total Points: {{ template.totalPoints }}</p>
                    <p>Minutes: {{ template.minutes }}</p>

                    <div v-for="section in template.template" :key="section.name">
                        <h2>{{ section.name }}</h2>

                        <div v-for="field in section.fields" :key="field.label">
                            <p>
                                {{ field.label }} - Points: {{ field.points }} , Difficulty:
                                {{ field.difficulty }}
                            </p>
                            <p style="text-decoration: underline">{{ field.value }}</p>
                        </div>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions class="px-6 pt-0 pb-5">
                <v-spacer></v-spacer>
                <!-- <v-btn color="primary" @click="print">Print</v-btn> -->
                <!-- <v-btn color="primary" @click="print2">Print</v-btn> -->
                <v-btn color="primary" @click="closePrintDialog">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
export default {
    name: "TemplatePrinterDialog",
    props: {
        template: Object, // JSON object to be printed
        openDialog: Function, // Prop to open the print dialog
    },
    data() {
        return {
            printDialog: false,
        };
    },
    methods: {
        openPrintDialog() {
            this.printDialog = true;

            // this.$refs.templatePrinterDialog.scrollTop = 0;
        },
        closePrintDialog() {
            this.printDialog = false;
        },
        print() {
            // Use browser's print functionality
            window.print();
        },
        print2() {
            var contentDiv = document.getElementById("printable-content");
            var contentToPrint = contentDiv.innerHTML;
            const printWindow = window.open("", "_blank");

            if (printWindow) {
                printWindow.document.open();

                // printWindow.document.write(insert styleing here);
                printWindow.document.write(contentToPrint);
                printWindow.document.close();

                printWindow.onload = () => {
                    printWindow.print();
                    printWindow.addEventListener("beforeprint", () => {
                        // Add any beforeprint logic here if needed
                    });
                    printWindow.addEventListener("afterprint", () => {
                        printWindow.close();
                    });
                };
            }
        },
        // downloadPDF() {
        //   try {
        //     var pdf = new jsPDF();
        //     const element = document.getElementById("print");
        //     if (element) {
        //       console.log(element);
        //       pdf.html(element);
        //       pdf.save("page.pdf");
        //     }
        //   } catch (error) {
        //     console.log(error);
        //   }
        // },
    },
};
</script>
  
<style>
/* @media print {
    body {
      visibility: hidden;
      overflow: auto;
      height: auto;
    }
    #printable-content {
      visibility: visible;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: auto;
      border: none !important;
      overflow: visible;
    } */
/* } */
#printable-content {
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 5px;
}
</style>  