<template>
  <div id="how-it-works">
    <section class="grey lighten-3">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">HOW IT WORKS</h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider></v-divider>
          <v-divider></v-divider>
        </v-responsive>

        <v-responsive class="text-left mx-auto mb-8" max-width="720">
          <p>
            Welcome to Miss Anri's ClassNetwork! We're here to simplify your
            teaching and planning experience. Here's how it works:
          </p>
          <span class="text-h5 font-weight-bold"> 1. Sign Up </span>
          <p>
            Begin by creating your account. It's quick and easy. Click
            "Subscribe now" and follow the prompts to set up your profile.
          </p>
          <span class="text-h5 font-weight-bold"> 2. Profile Setup </span>
          <p>
            After signing up, customize your profile. Choose your school,
            province, and other relevant details. This helps us tailor your
            experience to your specific needs.
          </p>
          <div class="vid-div">
            <iframe
              class="responsive-iframe"
              src="//www.youtube.com/embed/6Bt-BRLat-c?rel=0"
              frameborder="0"
            ></iframe>
          </div>
          <span class="text-h5 font-weight-bold">
            3. Explore the resource library
          </span>
          <p>
            Download as many resources as you want. This includes workbooks,
            assessments, classroom décor, and planning.
          </p>
          <span class="text-h5 font-weight-bold">
            4. Explore the Calendar
          </span>
          <p>
            Once your profile is set up, explore the calendar feature. You'll
            find pre-set term dates, holidays, and weeks 1-10 to help you plan
            your days effectively.
          </p>
          <div class="vid-div">
            <iframe
              class="responsive-iframe"
              src="//www.youtube.com/embed/m7PWNOBrpKQ?rel=0"
              frameborder="0"
              style="max-width: 100%"
            ></iframe>
          </div>
          <span class="text-h5 font-weight-bold"> 5. Create your own </span>
          <p>
            Our platform offers a comprehensive lesson-planning tool. Choose
            from a library of templates. Plan your lessons, allocate them to
            your calendar, and adjust as needed.
          </p>
          <p>
            Effortlessly plan assessments using our templates based on the
            revised ATP or CAPS. Customize assessments to suit your requirements
            and select cognitive levels for each question. The system calculates
            marks for you.
          </p>
          <p>
            When your lessons, assessments, and memorandums are ready, simply
            click "Print." You can also save them for future reference. Our
            user-friendly interface ensures a hassle-free experience.
          </p>
          <span class="text-h5 font-weight-bold"> 6. Lesson Planning </span>
          <p>
            Our platform offers a comprehensive lesson planning tool. Choose
            from a library of templates. Plan your lessons, allocate them to
            your calendar, and adjust as needed.
          </p>
          <p>
            Send us your school’s lesson plan template and we will customize it
            for you!
          </p>
          <div class="vid-div">
            <iframe
              class="responsive-iframe"
              src="//www.youtube.com/embed/wwZ2kUT9ZoA?rel=0"
              frameborder="0"
              style="max-width: 100%"
            ></iframe>
          </div>
          <span class="text-h5 font-weight-bold"> 7. Assessment Planning </span>
          <p>
            Effortlessly plan assessments using our templates, all based on the
            revised ATP or CAPS. Customize assessments to suit your requirements
            and select cognitive levels for each question. The system calculates
            marks for you.
          </p>
          <div class="vid-div">
            <iframe
              class="responsive-iframe"
              src="//www.youtube.com/embed/6cdZW9ckUBM?rel=0"
              frameborder="0"
              style="max-width: 100%"
            ></iframe>
          </div>
          <span class="text-h5 font-weight-bold"> 8. Print and Save </span>
          <p>
            When your lessons and assessments + memorandums are ready, simply
            click "Print." You can also save them for future reference. Our
            user-friendly interface ensures a hassle-free experience.
          </p>
          <div class="vid-div">
            <iframe
              class="responsive-iframe"
              src="//www.youtube.com/embed/8xAtIbGsqWI?rel=0"
              frameborder="0"
              style="max-width: 100%"
            ></iframe>
          </div>
          <span class="text-h5 font-weight-bold"> 9. Help and Support </span>
          <p>
            If you ever need assistance or have questions, our dedicated support
            team is here to help. Contact us through our contact page.
          </p>
          <p>
            Join Miss Anri's ClassNetwork today and experience a new level of
            convenience and efficiency in your teaching journey. We're here to
            support you every step of the way.
          </p>
        </v-responsive>
      </v-container>

      <div class="py-12"></div>
    </section>

    <section id="stats">
      <div
        style="
          background-attachment: fixed;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          color: #ffffff;
        "
        class="parallax"
      >
        <v-container fill-height style="padding: 120px 0">
          <v-row class="mx-auto">
            <v-col
              v-for="[value, title] of stats"
              :key="title"
              cols="12"
              md="4"
            >
              <div class="text-center">
                <div
                  class="display-3 font-weight-bold mb-4"
                  v-text="value"
                ></div>

                <div
                  class="title font-weight-regular text-uppercase"
                  v-text="title"
                ></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
export default {
  name: "HowItWorksView",
  data() {
    return {
      prices: [
        {
          icon: "mdi-calendar-month-outline",
          title: "Monthly and Bi-Weekly Planning",
          text: "Plan your teaching schedule with our user-friendly Calendar feature. Seamlessly navigate bi-weekly schedules, ensuring optimal time allocation for lesson planning, assessments, and student engagement.",
          price: "$8/month",
        },
        {
          icon: "mdi-tools",
          title: "Planning Tool",
          text: "Streamline the assessment and lesson planning process with our planning tool. Set question weightings and define cognitive levels. Seamlessly align your lesson plans and assessments with the South African curriculum, ensuring you cover all the necessary topics and learning outcomes.",
          price: "$10/month",
        },
        {
          icon: "mdi-face-agent",
          title: "Teaching Support",
          text: "Experience support with our Teacher Support feature. Connect with experienced educators who are ready to answer your questions, provide guidance, and share best practices for effective teaching.",
          price: "$12/month",
        },
      ],
      stats: [
        ["10K+", "Customers"],
        ["23900+", "Resources sold"],
        ["25K+", "Social Media followers"],
      ],
    };
  },
};
</script>
<style>
.parallax {
  background-image: url("@/assets/app-assets/teacher/teacher-stats-img.jpg");
}
.vid-div {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  margin-bottom: 1.2rem;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
</style>
