import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import WebsiteLayout from "@/layouts/WebsiteLayout.vue";
import AdminLayout from "@/layouts/AdminLayout.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import PrintLayout from "@/layouts/PrintLayout.vue";

import Authenticator from "@/core/services/Authenticator.vue";
import AssessmentPrinter from "@/components/global-components/AssessmentPrinter.vue";
import LessonPrinter from "@/components/global-components/LessonPrinter.vue";

import AppLandingView from "@/views/app/AppLandingView.vue";
import AppProfileView from "@/views/app/AppProfileView.vue";
import AppCalendarView from "@/views/app/AppCalendarView.vue";
import AppPlannerView from "@/views/app/AppPlannerView.vue";
import AppContactSupportView from "@/views/app/AppContactSupportView.vue";
import AppSubjectTemplatesView from "@/views/app/AppSubjectTemplatesView.vue";
import AppAssessmentsView from "@/views/app/AppAssessmentsView.vue";
import AppProductsView from "@/views/app/AppProductsView.vue";

import AdminLandingView from "@/views/admin/AdminLandingView.vue";
import AdminSubjectsView from "@/views/admin/AdminSubjectsView.vue";
import AdminHolidaysView from "@/views/admin/AdminHolidaysView.vue";
import AdminProductsView from "@/views/admin/AdminProductsView.vue";
import AdminTermsView from "@/views/admin/AdminTermsView.vue";
import AdminSchoolsView from "@/views/admin/AdminSchoolsView.vue";
import AdminTaxonomiesView from "@/views/admin/AdminTaxonomiesView.vue";
import AdminSubjectTemplatesView from "@/views/admin/AdminSubjectTemplatesView.vue";
import AdminAssessmentTemplatesView from "@/views/admin/AdminAssessmentTemplatesView.vue";
import AdminAnnouncementsView from "@/views/admin/AdminAnnouncementsView.vue";
import AdminCustomersView from "@/views/admin/AdminCustomersView.vue";

import WebsiteLandingView from "@/views/website/WebsiteLandingView.vue";
import WebsiteAboutView from "@/views/website/AboutView.vue";
import WebsiteTermsView from "@/views/website/TermsView.vue";
import WebsitePricesView from "@/views/website/PricesView.vue";
import WebsiteHowItWorksView from "@/views/website/HowItWorksView.vue";
import WebsiteContactUsView from "@/views/website/ContactUsView.vue";
import WebsiteMeetTheTeamView from "@/views/website/MeetTheTeam.vue";

// import DevTestView from '@/views/website/DevTestView.vue';

import PageNotFoundView from "@/views/PageNotFoundView.vue";

import { Auth, API } from "aws-amplify";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "WebsiteLanding",
    component: WebsiteLandingView,
    meta: { layout: WebsiteLayout },
  },
  {
    path: "/admin",
    name: "AdminLanding",
    component: AdminLandingView,
    meta: { layout: AdminLayout, requiresAuth: true, allowedGroups: ["admin"] },
  },
  {
    path: "/admin/subjects",
    name: "AdminSubjects",
    component: AdminSubjectsView,
    meta: { layout: AdminLayout, requiresAuth: true, allowedGroups: ["admin"] },
  },
  {
    path: "/admin/holidays",
    name: "AdminHolidays",
    component: AdminHolidaysView,
    meta: { layout: AdminLayout, requiresAuth: true, allowedGroups: ["admin"] },
  },
  {
    path: "/admin/customers",
    name: "AdminCustomers",
    component: AdminCustomersView,
    meta: { layout: AdminLayout, requiresAuth: true, allowedGroups: ["admin"] },
  },
  {
    path: "/admin/products",
    name: "AdminProducts",
    component: AdminProductsView,
    meta: { layout: AdminLayout, requiresAuth: true, allowedGroups: ["admin"] },
  },
  {
    path: "/admin/lessontemplates",
    name: "AdminSubjectTemplates",
    component: AdminSubjectTemplatesView,
    meta: { layout: AdminLayout, requiresAuth: true, allowedGroups: ["admin"] },
  },
  {
    path: "/admin/assessmenttemplates",
    name: "AdminAssessmentTemplates",
    component: AdminAssessmentTemplatesView,
    meta: { layout: AdminLayout, requiresAuth: true, allowedGroups: ["admin"] },
  },
  {
    path: "/admin/terms",
    name: "AdminTerms",
    component: AdminTermsView,
    meta: { layout: AdminLayout, requiresAuth: true, allowedGroups: ["admin"] },
  },
  {
    path: "/admin/schools",
    name: "AdminSchools",
    component: AdminSchoolsView,
    meta: { layout: AdminLayout, requiresAuth: true, allowedGroups: ["admin"] },
  },
  {
    path: "/admin/taxonomies",
    name: "AdminTaxonomies",
    component: AdminTaxonomiesView,
    meta: { layout: AdminLayout, requiresAuth: true, allowedGroups: ["admin"] },
  },
  {
    path: "/admin/announcements",
    name: "AdminAnnouncements",
    component: AdminAnnouncementsView,
    meta: { layout: AdminLayout, requiresAuth: true, allowedGroups: ["admin"] },
  },
  {
    path: "/app",
    name: "AppLanding",
    component: AppLandingView,
    meta: {
      layout: AppLayout,
      requiresAuth: true,
      allowedGroups: ["teacher", "admin"],
    },
  },
  {
    path: "/app/profile",
    name: "AppProfile",
    component: AppProfileView,
    meta: { layout: AppLayout, requiresAuth: true },
  },
  {
    path: "/app/calendar",
    name: "AppCalendar",
    component: AppCalendarView,
    meta: {
      layout: AppLayout,
      requiresAuth: true,
      allowedGroups: ["teacher", "admin"],
    },
  },
  {
    path: "/app/lessonplanning",
    name: "AppPlanner",
    component: AppPlannerView,
    meta: {
      layout: AppLayout,
      requiresAuth: true,
      allowedGroups: ["teacher", "admin"],
    },
  },
  {
    path: "/app/support",
    name: "AppSupport",
    component: AppContactSupportView,
    meta: {
      layout: AppLayout,
      requiresAuth: true,
      allowedGroups: ["teacher", "admin"],
    },
  },
  {
    path: "/app/lessons",
    name: "AppSubjectTemplates",
    component: AppSubjectTemplatesView,
    meta: {
      layout: AppLayout,
      requiresAuth: true,
      allowedGroups: ["teacher", "admin"],
    },
  },
  {
    path: "/app/assessments",
    name: "AppAssessments",
    component: AppAssessmentsView,
    meta: {
      layout: AppLayout,
      requiresAuth: true,
      allowedGroups: ["teacher", "admin"],
    },
  },
  {
    path: "/app/products/:category*",
    name: "AppProducts",
    component: AppProductsView,
    meta: {
      layout: AppLayout,
      requiresAuth: true,
      // allowedGroups: ["teacher", "admin"],
    },
  },
  {
    path: "/app/my-library/:category*",
    name: "AppMyProductLibrary",
    component: AppProductsView,
    meta: {
      layout: AppLayout,
      requiresAuth: true,
      allowedGroups: ["teacher"],
    },
  },
  {
    path: "/app/print/assessment/:templateId",
    name: "AssessmentPrinter",
    component: AssessmentPrinter,
    meta: {
      layout: PrintLayout,
      requiresAuth: true,
      allowedGroups: ["teacher", "admin"],
    },
  },
  {
    path: "/app/print/lesson/:templateId",
    name: "LessonPrinter",
    component: LessonPrinter,
    meta: {
      layout: PrintLayout,
      requiresAuth: true,
      allowedGroups: ["teacher", "admin"],
    },
  },
  {
    path: "/about",
    name: "WebsiteAbout",
    component: WebsiteAboutView,
    meta: { layout: WebsiteLayout },
  },
  {
    path: "/terms",
    name: "WebsiteTerms",
    component: WebsiteTermsView,
    meta: { layout: WebsiteLayout },
  },
  {
    path: "/contact-us",
    name: "WebsiteContactUs",
    component: WebsiteContactUsView,
    meta: { layout: WebsiteLayout },
  },
  {
    path: "/prices",
    name: "WebsitePrices",
    component: WebsitePricesView,
    meta: { layout: WebsiteLayout },
  },
  {
    path: "/how-it-works",
    name: "WebsiteHowItWorks",
    component: WebsiteHowItWorksView,
    meta: { layout: WebsiteLayout },
  },
  {
    path: "/meet-the-team",
    name: "WebsiteMeetTheTeam",
    component: WebsiteMeetTheTeamView,
    meta: { layout: WebsiteLayout },
  },
  // {
  //   path: '/developer-test',
  //   name: 'DevTest',
  //   component: DevTestView,
  //   meta: { layout: WebsiteLayout }
  // },
  {
    path: "/auth",
    name: "Authenticator",
    component: Authenticator,
    meta: { layout: WebsiteLayout },
  },
  {
    path: "*",
    name: "NotFound",
    component: PageNotFoundView,
    meta: { layout: WebsiteLayout },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (!requiresAuth) {
    next();
    return;
  }

  try {
    const user = await Auth.currentAuthenticatedUser().catch((error) => {
      // Log any errors
      console.log("error:", error);
    });

    let userGroups = [];

    if (Array.isArray(store.state.userGroups)) {
      userGroups = store.state.userGroups;
    } else {
      await API.post("cognitolambdaapi", "/item/listUserGroups", {
        body: {
          userPoolId: user.pool.userPoolId,
          userName: user.username,
        },
      })
        .then(async (cognitoListUserGroupsResponse) => {
          if (cognitoListUserGroupsResponse.list) {
            userGroups = JSON.parse(cognitoListUserGroupsResponse.list);
          }
        })
        .catch((error) => {
          // Log any errors
          console.log("error:", error);
        });
    }

    const allowedGroups = to.meta.allowedGroups || [];

    if (user) {
      // console.log("user", user.attributes.email);
      if (!store.state.userSignedIn) {
        store.commit("storeUserSignedIn", true);
      store.commit("storeUserEmail", user.attributes.email);
      }
    } else {
      if (store.state.userSignedIn) {
        store.commit("storeUserSignedIn", false);
      store.commit("storeUserEmail", '');
      }
    }

    if (requiresAuth && !user) {
      // Authentication required but not signed in, redirect to the login page
      next({
        path: "/auth",
        query: { redirect: to.fullPath },
      });
    } else if (
      requiresAuth &&
      user &&
      (allowedGroups.length === 0 ||
        userGroups.some((group) => allowedGroups.includes(group)))
    ) {
      // User has the required group(s) to access the route
      next();
    } else {
      // User does not have the required group(s) to access the route
      next({
        path: "/unauthorised",
      });
    }
  } catch (error) {
    // console.log("error", error)
    // User is not authenticated, redirect to the login page
    next({
      path: "/auth",
      query: { redirect: to.fullPath },
    });
  }
});

export default router;
