<template>
  <div id="adminterms">
    <div class="pa-2">
      <v-row justify="space-between">
        <v-col>
          <Terms />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import Terms from "@/components/admin-components/global/Terms.vue";
export default {
  name: "AdminTerms",
  components: { Terms },
  data: () => ({}),
};
</script>