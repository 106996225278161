<template>
  <div id="admintaxonomies">
    <div class="pa-2">
      <v-row justify="space-between">
        <v-col>
          <Taxonomies />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import Taxonomies from "@/components/admin-components/global/Taxonomies.vue";
export default {
  name: "AdminTaxonomies",
  components: { Taxonomies },
  data: () => ({}),
};
</script>