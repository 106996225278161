
const af = {
    headings: {
        home: "Tuis",
        about: "Oor",
        contactUs: "Kontak ons",
        login: "Teken in",
        register: "Registreer",
        signIn: "Teken in",
        signOut: "Teken uit",
        profile: "Profiel",
        howItWorks: "Hoe dit werk",
        pricing: "Prysstelling",
        prices: "Pryse",
        meetTheTeam: "Ontmoet die span",
        subscription: "Intekening",
        package: "Pakket",
        subscribe: "Inteken",
        schedule: "Skedule",
        classes: "Klasse",
        class: "Klas",
        teach: "Onderrig",
        recheck: "Herondersoek",
        validUntil: "Geldig Tot",
        grades: "Grade",
        grade: "Graad",
        downloadQuota: "Aflaai Kwota",
        day: "Dag",
        subject: "Vak",
        resource: "Hulpbron",
        library: "Biblioteek",
        calendar: "Kalender",
        classroom: "Klaslokaal",
        lessons: "Lesse",
        lesson: "Les",
        assessments: "Assesserings",
        assessment: "Assessering",
        support: "Ondersteuning",
        print: "Print",
        category: "Kategorie",
        categories: "Kategorieë",
        featured: "Uitgelig",
        product: "Produk",
        content: "Inhoud",
        planner: "Beplanner",
        headings: "Opskrifte",
        defaults: "Wanbetalings",
        template: "Sjabloon",
        preview: "Voorskou",
        memorandum: "Memorandum",
        questions: "Vrae",
        question: "Vraag",
        section: "Afdeling",
        body: "Liggaam",
        logo: "Logo",
        activity: "Aktiwiteit",
    },
    labels: {
        title: "Titel",
        name: "Naam",
        surname: "Van",
        email: "E-pos",
        password: "Wagwoord",
        confirmPassword: "Bevestig wagwoord",
        language: "Taal",
        province: "Provinsie",
        school: "Skool",
        schoolDaysPerWeek: "Skool dae per week",
        periods: "Periodes",
        periodDuration: "Periode Duur",
        breaks: "Pouses",
        enterNumPressEnter: "Voer 'n nommer in en druk enter",
        startTime: "Begin Tyd",
        endTime: "Eind Tyd",
        actions: "Aksies",
        subject: "Onderwerp",
        message: "Boodskap",
        search: "Soek",
        tags: "Etikette",
        more: "Meer",
        details: "Besonderhede",
        files: "Lêers",
        urls: "URLs",
        complexity: "Kompleksiteit",
        time: "Tyd",
        minutes: "Minute",
        instructions: "Instruksies",
        taxonomy: "Taksonomie",
        number: "Nommer",
        shortNumber: "Nr.",
        marks: "Punte",
        fields: "Velde",
        field: "Veld",
        level: "Vlak",
        size: "Grootte",
        label: "Etiket",
        type: "Tipe",
        repeat: "Herhaal",
    },
    products: {
      addToMyLibrary: "Voeg by My Biblioteek",
      subToGradeToDownload: "Inteken op Graad om af te laai",
      subToDownload: "Inteken om af te laai",
      currentMonthLimit: "Limiet Bereik Vir Hierdie Graad Vir ",
      noContent: "Hierdie Produk het geen inhoud nie.",
    },
    calendar: {
      repeatFor: "Herhaal vir",
      weeksMax: "Week(e) (Maks 12)",
      },
    lessons: {
      createFrom: "Skep 'n nuwe Les van",
      pickLesson: "Kies 'n bestaande les sjabloon",
     },
     templates: { 
      restricted: "Die sjablone hieronder is beperk tot die grade waarop jy ingeteken is en die vakke wat jy by jou klasse gevoeg het.",
      restrictedGrandFathered: "Die sjablone hieronder is beperk tot die vakke wat jy by jou klasse gevoeg het.",
    },
    assessments: {
      createFrom: "Skep 'n nuwe Assessering van",
      pickAssessment: "Kies 'n bestaande assessering sjabloon",
      newSection: "Nuwe Afdeling?",
      notASection: "Nie 'n Afdeling",
    },
    actions: {
        save: "Stoor",
        submit: "Indien",
        cancel: "Kanselleer",
        close: "Maak toe",
        edit: "Wysig",
        delete: "Vee uit",
        add: "Voeg by",
        update: "Opdateer",
        view: "Beskou",
        retry: "Probeer weer",
        ok: "OK",
        yes: "Ja",
        show: "Wys",
        create: "Skep",
    },
    status: {
      cancelled: "Gekanselleer",
      active: "Aktief",
      activateMySub: "Aktiveer my intekening",
      cancelMySub: "Kanselleer my intekening",
      noActiveSub: "Geen aktiewe intekening",
      processingSub: "Ons verwerk jou intekening",
      failedPayment: "Mislukte betaling",
    },
    payfastPackages: {
      perMonth: "per Maand",
      every3Months: "elke 3 Maande",
      every6Months: "elke 6 Maande",
      perYear: "per Jaar",
      perAdditionalGrade: "per Addisionele Graad",
      downloadLimit: "Aflaai limiet",
      perMonthPerGrade: "per Maand per Graad",
      downloadsUnlimited: "Aflaai Onbeperk",
      titleMonthly: "Maandeliks",
      title3Monthly: "3 Maandeliks",
      title6Monthly: "6 Maandeliks",
      titleYearly: "Jaarliks",
      min1GradeRequired: "Minimum 1 graad is benodig",
      newSubSummary: "Nuwe Intekening Opsomming",
      currentSubSummary: "Huidige Intekening Opsomming",
      immediatePayment: "Onmiddelike Betaling",
      nextPayment: "Volgende Betaling",
      amount: "Bedrag",
      updatePackage: "Opdateer Pakket",
    },
    days: {
      today: "Vandag",
      tomorrow: "Môre",
      yesterday: "Gister",
      monday: "Maandag",
      tuesday: "Dinsdag",
      wednesday: "Woensdag",
      thursday: "Donderdag",
      friday: "Vrydag",
      saturday: "Saterdag",
      sunday: "Sondag",
    },
    otherText: {
      thankYou: "Dankie",
      frequency: "Frekwensie",
      new: "Nuwe",
      mine: "Myne",
      my: "My",
      change: "Verander",
      deleteScheduleDay: "Is jy seker jy wil hierdie dag verwyder?",
      deleteSubject: "Is jy seker jy wil hierdie vak verwyder?",
      deleteTemplate: "Is jy seker jy wil hierdie sjabloon verwyder?",
      deleteAssessment: "Are you sure you want to delete this assessment?",
      deleteActivity: "Is jy seker jy wil hierdie aktiwiteit verwyder?",
      your: "Jou",
      family: "Familie",
      in: "in",
      or: "of",
      blank: "Leeg", //Leë
      cognitive: "Kognitiewe",
    },
    movement: {
      back: "Terug",
      forward: "Vorentoe",
      next: "Volgende",
      previous: "Vorige",
      up: "Op",
      down: "Af",
      left: "Links",
      right: "Regs",
    },
    typography: {
      font: "Font",
    },
    helperHints: {
      contactsupport: {
        title: "KONTAK HULP:",
        body: "<p>Stuur ondersteuning 'n boodskap nadat jy die velde ingevul het en die relevante kategorie gekies het, ons ondersteuningspan sal jou binnekort kontak.</p>",
      },
      auth: {
        title: "WAGWOORD:",
        body: "<p>Wagwoordvereistes:</p><ul><li>Minimum lengte 8 karakter(s)</li><li>Bevat ten minste 1 nommer</li><li>Bevat ten minste 1 spesiale karakter</li><li>Bevat ten minste 1 hoofletter</li><li>Bevat ten minste 1 kleinletter</li></ul>",
      },
      subscription: {
        title: "INSKRIPSIE:",
        body: "<p>Bekyk, hernu of gradeer jou intekeningpakket op. Kies wat die beste by jou behoeftes pas!</p>",
      },
      profile: {
        title: "PROFIEL:",
        body: "<p>Dateer jou persoonlike inligting hier op en verseker dat dit akkuraat en op datum is vir 'n beter gebruikerservaring.</p>",
      },
      schedule: {
        title: "SKEDULE:",
        body: `<p>Stroomlyn jou komende week! Kry toegang tot en pas jou skooldae aan om jou akademiese reis beter te struktureer. Dae wat hier bygevoeg word, sal outomaties op jou kalender weerspieël. Let wel: As u nie alle skooldae invoer nie, sal u kalenderredigeervermoë vir daardie datums beperk.</p>`,
      },
      scheduleedit: {
        title: "Pas die besonderhede van jou bestaande skedule aan.",
        body: "<p></p>",
      },
      classes: {
        title: "KLASSE:",
        body: "<p>In hierdie afdeling kan jy klasse direk kies en byvoeg vanaf 'n omvattende lys wat strek van graad R tot graad 12 gebaseer op die Suid-Afrikaanse skoolkurrikulum. Kies maklik die toepaslike grade en vakke en noem jou klasse.</p>",
      },
      classesedit: {
        title: "Pas die besonderhede van jou bestaande klasse aan.",
        body: "<p></p>",
      },
      calendar: {
        title: "KALENDER:",
        body: "<p>Bly op koers met jou opvoedkundige reis! Hier kan jy al jou geskeduleerde skooldae bekyk en aktiwiteite byvoeg. Maak seker dat elke kritieke aktiwiteit bygevoeg word om jou voorbereidings betyds en doeltreffend te hou. Onthou, enige geskeduleerde dae sal uit jou insette in die 'Skedule'-afdeling getrek word. Bly georganiseer en mis nooit weer 'n belangrike datum nie!</p>",
      },
      calendaractivity: {
        title: "Hier kan jy al jou bestaande aktiwiteite wysig.",
        body: "<p></p>",
      },
      lessons: {
        title: "LESSE:",
        body: "<p>Verken ons saamgestelde lesplan-sjablone wat vir verskeie vakke en grade aangepas is. Sodra jy die perfekte pas gekry het, skeduleer dit maklik op jou kalender om jou onderrig georganiseer en op koers te hou. Maak seker dat elke dag die regte inhoud vir jou leerders het.</p>",
      },
      lessonedit: {
        title: "SKEP / WYSIG LES:",
        body: "<p><h3>SKEP</h3> Duik met gemak in kurrikulumbeplanning. Begin van vooraf. Hierdie afdeling laat jou toe om lesse te ontwerp en aan te pas om by jou onderrigstyl en studente se behoeftes te pas. Berei die verhoog vir innemende en praktiese leerervarings.</p>",
      },
      assessments: {
        title: "ASSESSERINGS:",
        body: "<p>Maak persoonlike assesserings of kies uit ons uitgebreide biblioteek van sjablone. Voel vry om enige sjabloon aan te pas om by jou leerders se behoeftes te pas, om 'n omvattende assessering van hul vordering te verseker.</p>",
      },
      assessmentedit: {
        title: "WYSIG ASSESSERING:",
        body: "<p>Pas die besonderhede van jou bestaande assesserings aan. Hier kan jy inhoud verander om in lyn te kom met spesifieke leeruitkomste gebaseer op die KABV-kurrikulum. Maak seker dat jou evaluerings relevant en prakties bly.</p>",
      },
      assessmentcreate: {
        title: "SKEP ASSESSERING:",
        body: "<p>Hierdie afdeling laat jou toe om betekenisvolle assesserings te skep wat in lyn is met jou kurrikulumdoelwitte. Maak seker dat jou studente regverdig en omvattend getoets word.</p>",
      },
    },
    $vuetify: {
        badge: 'badge',
        close: 'Close',
        dataIterator: {
          noResultsText: 'Geen ooreenstemmende resultate is gevind nie',
          loadingText: 'Loading item...',
        },
        dataTable: {
          itemsPerPageText: 'Rye per bladsy:',
          ariaLabel: {
            sortDescending: 'Sorted descending.',
            sortAscending: 'Sorted ascending..',
            sortNone: 'Not sorted.',
            activateNone: 'Activate to remove sorting.',
            activateDescending: 'Activate to sort descending.',
            activateAscending: 'Activate to sort ascending.',
          },
          sortBy: 'Sort by',
        },
        dataFooter: {
          itemsPerPageText: 'Aantal per bladsy:',
          itemsPerPageAll: 'Alles',
          nextPage: 'Volgende bladsy',
          prevPage: 'Vorige bladsy',
          firstPage: 'Eerste bladsy',
          lastPage: 'Laaste bladsy',
          pageText: '{0}-{1} van {2}',
        },
        datePicker: {
          itemsSelected: '{0} gekies',
          nextMonthAriaLabel: 'Volgende maand',
          nextYearAriaLabel: 'Volgende jaar',
          prevMonthAriaLabel: 'Vorige maand',
          prevYearAriaLabel: 'Vorige jaar',
        },
        noDataText: 'Geen data is beskikbaar nie',
        carousel: {
          prev: 'Vorige visuele',
          next: 'Volgende visuele',
          ariaLabel: {
            delimiter: 'Carousel slide {0} of {1}',
          },
        },
        calendar: {
          moreEvents: '{0} meer',
        },
        input: {
          clear: 'Clear {0}',
          prependAction: '{0} prepended action',
          appendAction: '{0} appended action',
        },
        fileInput: {
          counter: '{0} files',
          counterSize: '{0} files ({1} in total)',
        },
        timePicker: {
          am: 'AM',
          pm: 'PM',
        },
        pagination: {
          ariaLabel: {
            wrapper: 'Paginasie-navigasie',
            next: 'Volgende bladsy',
            previous: 'Vorige bladsy',
            page: 'Gaan na bladsy {0}',
            currentPage: 'Huidige bladsy, Bladsy {0}',
          },
        },
        rating: {
          ariaLabel: {
            icon: 'Rating {0} of {1}',
          },
        },
        loading: 'Loading...',
      }
};

export default af;