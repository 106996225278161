<template>
  <div id="appassessmentsview">
    <div class="pa-2">
      <v-row justify="space-between">
        <v-col cols="12">
          <TeacherAssessments />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import TeacherAssessments from "@/components/app-components/teacher/TeacherAssessments.vue";
export default {
  name: "AppAssessmentsView",
  components: { TeacherAssessments },
  data: () => ({}),
};
</script>