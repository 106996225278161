<template>
  <div id="announcements">
    <v-card max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4"> Announcements </v-card-title>
        <v-spacer />
        <v-btn
          color="primary"
          class="mx-4"
          @click="createItem"
          :disabled="offline || !initiallyLoaded"
        >
          New Announcement
        </v-btn>
      </v-row>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <div class="px-4">
        <v-data-table
          :headers="headers"
          :items="syncedAnnouncementArray"
          :sort-desc="sortDesc"
          :sort-by="sortBy"
          must-sort
          :search="search"
          :loading="
            (!announcementSubscriptionSynced &&
              syncedAnnouncementArray?.length === 0) ||
            !initiallyLoaded
          "
          loading-text="Loading Announcements..."
          no-data-text="No Announcements found"
        >
          <!-- <template v-slot:[`item.date`]="{ item }">
            {{ item.date ? formatDate(item.date) : "" }}
          </template> -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              :disabled="offline || !initiallyLoaded"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
              :disabled="offline || !initiallyLoaded"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog persistent v-model="dialog" max-width="500px">
      <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent="save">
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.title"
                    label="Title"
                    :rules="formRules.title"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="editedItem.sendTo"
                    :items="['ALL', ...itemsWithFullName]"
                    item-text="fullName"
                    item-value="id"
                    label="Send To"
                    multiple
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="editedItem.readBy"
                    :items="itemsWithFullName"
                    item-text="fullName"
                    item-value="id"
                    label="Read By"
                    multiple
                    readonly
                    append-icon=""
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="editedItem.message"
                    label="Message"
                    rows="1"
                    auto-grow
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="close"> Cancel </v-btn>
            <v-btn :disabled="!valid" color="primary" @click="save">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center"
          >Are you sure you want to delete this announcement?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDelete">Cancel</v-btn>
          <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore, API, Auth } from "aws-amplify";
import { Announcement } from "@/models";
import _ from "lodash";

export default {
  name: "AdminAnnouncements",
  data: () => ({
    initiallyLoaded: false,

    announcementSubscription: null,
    announcementSubscriptionSynced: false,
    syncedAnnouncementArray: [],

    teacherProfileSubscription: null,
    syncedTeacherProfileArray: [],

    valid: false,
    dialog: false,
    dialogDelete: false,
    sortBy: "title",
    sortDesc: true,
    search: "",
    headers: [
      { text: "Title", value: "title" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    // announcements: [],
    editedIndex: -1,
    editedItem: {
      title: "",
      sendTo: [],
      readBy: [],
      message: "",
    },
    defaultItem: {
      title: "",
      sendTo: [],
      readBy: [],
      message: "",
    },
    formRules: {
      title: [(v) => !!v || "Title is required"],
    },
  }),

  async mounted() {
    try {
      // Sync the Announcements from the datastore
      this.syncAnnouncements();

      await this.getTeacherProfiles();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
      appLoading: (state) => !state.appLoading,
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Announcement" : "Edit Announcement";
    },
    itemsWithFullName() {
      return this.syncedTeacherProfileArray.map((item) => ({
        ...item,
        fullName: `${item.title} ${item.name} ${item.surname}`,
      }));
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
      this.valid = false;
    },

    createItem() {
      this.resetForm();
      this.dialog = true;
    },

    editItem(item) {
      this.editedIndex = this.syncedAnnouncementArray.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.syncedAnnouncementArray.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      // this.announcements.splice(this.editedIndex, 1);
      await DataStore.delete(this.syncedAnnouncementArray[this.editedIndex]);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        // Update schedule
        await this.updateAnnouncement();
      } else {
        // Create new schedule
        await this.createAnnouncement();
      }
      this.close();
    },

    async createAnnouncement() {
      try {
        await DataStore.save(
          new Announcement({
            title: this.editedItem.title.trim(),
            sendTo: this.editedItem.sendTo,
            message: this.editedItem.message,
          })
        );
      } catch (error) {
        console.log(error);
      }
    },

    async updateAnnouncement() {
      try {
        await DataStore.save(
          Announcement.copyOf(
            _.find(this.syncedAnnouncementArray, { id: this.editedItem.id }),
            (updateModel) => {
              updateModel.title = this.editedItem.title.trim();
              updateModel.sendTo = this.editedItem.sendTo;
              updateModel.message = this.editedItem.message;
            }
          )
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncAnnouncements() {
      try {
        this.announcementSubscription = DataStore.observeQuery(
          Announcement
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedAnnouncementArray !== items) {
              this.syncedAnnouncementArray = items;

              this.announcementSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.announcementSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async getTeacherProfiles() {
      try {
        // this.teacherProfileSubscription = DataStore.observeQuery(
        //   TeacherProfile
        // ).subscribe(
        //   async (snapshot) => {
        //     const { items, isSynced } = snapshot;
        //     if (isSynced && items && this.syncedTeacherProfileArray !== items) {
        //       this.syncedTeacherProfileArray = items;
        //     }
        //   },
        //   (error) => {
        //     console.log(error);
        //   }
        // );
        const user = await Auth.currentAuthenticatedUser().catch(() => {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
        });

        if (!user) return;

        await API.get("adminlambdaapi", "/item/queryTeacherProfile", {
          queryStringParameters: {
            userPoolId: user.pool.userPoolId,
            userName: user.username,
          },
        })
          .then(async (response) => {
            if (response.teacherProfiles)
              this.syncedTeacherProfileArray = response.teacherProfiles;
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    if (this.announcementSubscription) {
      this.announcementSubscription.unsubscribe();
    }
    if (this.teacherProfileSubscription) {
      this.teacherProfileSubscription.unsubscribe();
    }
  },
};
</script>
