<template>
  <div id="applanding">
    <section>
      <div class="py-1"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">SECURE APP LANDING PAGE</h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider></v-divider>

          <v-divider></v-divider>
        </v-responsive>
      </v-container>
    </section>
  </div>
</template>

<script lang="ts">
export default {
  name: "AppLandingView",
  data: () => ({}),
};
</script>