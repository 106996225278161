<template>
  <div v-if="initiallyLoaded" class="mx-4 my-2">
    <AssessmentTemplatePrinterExample
      v-if="assessmentType === 'Questions'"
      :template="currentTeacherAssessmentTemplate"
      :fontSize="fontSize"
      :assessmentImgSrc="assessmentImgSrc"
    />
    <AssessmentTemplateMemoPrinterExample
      v-else
      :template="currentTeacherAssessmentTemplate"
      :fontSize="fontSize"
      :assessmentImgSrc="assessmentImgSrc"
    />

    <div class="print-copyright-footer">
      <span>
        Made with &copy; {{ new Date().getFullYear() }} Miss Anri's
        ClassNetwork.
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore, Storage } from "aws-amplify";
import {
  TeacherAssessmentTemplate,
  //  School,
  Subject,
} from "@/models";
import _ from "lodash";
import AssessmentTemplatePrinterExample from "@/components/global-components/AssessmentTemplatePrinterExample.vue";
import AssessmentTemplateMemoPrinterExample from "@/components/global-components/AssessmentTemplateMemoPrinterExample.vue";

export default {
  name: "AssesmentPrinter",
  components: {
    AssessmentTemplatePrinterExample,
    AssessmentTemplateMemoPrinterExample,
  },
  data() {
    return {
      initiallyLoaded: false,
      assessmentType: "Questions",
      teacherAssessmentTemplateSubscription: null,
      schoolSubscription: null,
      subjectSubscription: null,
      syncedTeacherAssessmentTemplateArray: [],
      // syncedSchoolArray: [],
      syncedSubjectArray: [],
      teacherAssessmentTemplates: [],
      currentTeacherAssessmentTemplate: {},
      assessmentImgSrc: "",
      assessmentImgSrcLocal: "",
      fontSize: 17, // default font size
      minFontSize: 14, // minimum font size
      maxFontSize: 20, // maximum font size
    };
  },
  async mounted() {
    try {
      document.addEventListener("changeType", this.changeType);
      document.addEventListener("increaseFontSize", this.increaseFontSize);
      document.addEventListener("decreaseFontSize", this.decreaseFontSize);
      document.addEventListener(
        "changeTemplateImgSrc",
        this.changeTemplateImgSrc
      );
      // Sync the TeacherAssessmentTemplates from the datastore
      await this.syncTeacherAssessmentTemplates();
    } catch (error) {
      console.log(error);
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
    }),
    // formattedTotalTime() {
    //   const totalMinutes = typeof this.teacherAssessmentTemplates[0].totalMinutes !== 'string' ? this.teacherAssessmentTemplates[0].totalMinutes : parseInt(this.teacherAssessmentTemplates[0].totalMinutes);
    //   const hours = Math.floor(totalMinutes / 60);
    //   const minutes = totalMinutes % 60;

    //   if (hours === 0) {
    //     return `${minutes} Minute${minutes > 1 ? 's' : ''}`;
    //   } else if (minutes === 0) {
    //     return `${hours} Hour${hours > 1 ? 's' : ''}`;
    //   } else {
    //     return `${hours} Hour${hours > 1 ? 's' : ''} ${minutes} Minute${minutes > 1 ? 's' : ''}`;
    //   }
    // },
    // isNewSection() {
    //   return questionIndex => {
    //     const question = this.teacherAssessmentTemplates[0].questions[questionIndex];
    //     return question && question.sectionNumber !== undefined;
    //   };
    // },
  },

  methods: {
    async changeTemplateImgSrc(event) {
      if (event.detail > "") {
        if (this.assessmentImgSrcLocal > "") {
          this.assessmentImgSrc = this.assessmentImgSrcLocal;
        } else {
          this.assessmentImgSrcLocal = await Storage.get(event.detail, {
            level: "public",
          });
          this.assessmentImgSrc = this.assessmentImgSrcLocal;
        }
      } else {
        this.assessmentImgSrc = "";
      }
    },

    increaseFontSize() {
      if (this.fontSize < this.maxFontSize) {
        this.fontSize += 1;
      }
    },

    decreaseFontSize() {
      if (this.fontSize > this.minFontSize) {
        this.fontSize -= 1;
      }
    },

    changeType() {
      if (this.assessmentType === "Questions") this.assessmentType = "Memo";
      else this.assessmentType = "Questions";
    },

    async syncTeacherAssessmentTemplates() {
      try {
        this.teacherAssessmentTemplateSubscription = DataStore.observeQuery(
          TeacherAssessmentTemplate,
          (tst) => tst.id("eq", this.$route.params.templateId)
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              this.syncedTeacherAssessmentTemplateArray !== items
            ) {
              this.syncedTeacherAssessmentTemplateArray = items;

              this.currentTeacherAssessmentTemplate = _.cloneDeep(items[0]);

              // if (this.syncedSubjectArray && this.syncedSubjectArray.length > 0 && this.teacherAssessmentTemplates && this.teacherAssessmentTemplates.length > 0) {
              //   this.teacherAssessmentTemplates.forEach((item) => {
              //     const subject = _.find(this.syncedSubjectArray, { id: item.subjectID });
              //     // const school = _.find(this.syncedSchoolArray, { id: item.schoolID });
              //     if (item.subject !== subject) item.subject = subject || { grade: "", subject: "" };
              //     // if (item.school !== school) item.school = school || { name: "", provinces: [], isGeneric: false };
              //   });
              // }

              // // Sync the Schools from the datastore
              // await this.syncSchools();

              // Sync the Subjects from the datastore
              await this.syncSubjects();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    // async syncSchools() {
    //   try {
    //     const templateSchoolId = this.teacherAssessmentTemplates[0].schoolID;
    //     console.log(templateSchoolId)
    //     this.schoolSubscription = DataStore.observeQuery(School, (s) => s.id("eq", templateSchoolId)).subscribe(
    //       async (snapshot) => {
    //         const { items, isSynced } = snapshot;
    //         if (isSynced && items && items.length > 0 && this.syncedSchoolArray !== items) {
    //           this.syncedSchoolArray = items;

    //           this.teacherAssessmentTemplates.forEach((item) => {
    //             const school = _.find(items, { id: item.schoolID });
    //             if (item.school !== school) item.school = school || { name: "", provinces: [], isGeneric: false };
    //           });

    //           // Sync the Subjects from the datastore
    //           await this.syncSubjects();

    //         }
    //       }
    //     );
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },

    async syncSubjects() {
      try {
        this.subjectSubscription = DataStore.observeQuery(Subject, (sub) =>
          sub.id("eq", this.currentTeacherAssessmentTemplate.subjectID)
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedSubjectArray !== items) {
              this.syncedSubjectArray = items;

              const mutableObj = _.cloneDeep(
                this.currentTeacherAssessmentTemplate
              );

              mutableObj.subject = items[0];

              this.currentTeacherAssessmentTemplate = Object.assign(
                {},
                mutableObj
              );

              // this.teacherAssessmentTemplates.forEach((item) => {
              //   const subject = _.find(items, { id: item.subjectID });
              //   if (item.subject !== subject) item.subject = subject || { grade: "", subject: "" };
              // });

              if (!this.initiallyLoaded) {
                this.initiallyLoaded = true;
              }
            } else if (this.offline) {
              if (!this.initiallyLoaded) {
                this.initiallyLoaded = true;
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    document.removeEventListener("changeType", this.changeType);
    document.removeEventListener("increaseFontSize", this.increaseFontSize);
    document.removeEventListener("decreaseFontSize", this.decreaseFontSize);
    document.removeEventListener(
      "changeTemplateImgSrc",
      this.changeTemplateImgSrc
    );

    if (this.teacherAssessmentTemplateSubscription) {
      this.teacherAssessmentTemplateSubscription.unsubscribe();
    }
    if (this.subjectSubscription) {
      this.subjectSubscription.unsubscribe();
    }
  },
};
</script>
