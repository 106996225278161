<template>
  <div id="appsubjecttemplatesview">
    <div class="pa-2">
      <v-row justify="space-between">
        <v-col cols="12">
          <TeacherSubjectTemplates />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import TeacherSubjectTemplates from "@/components/app-components/teacher/TeacherSubjectTemplates.vue";
export default {
  name: "AppSubjectTemplatesView",
  components: { TeacherSubjectTemplates },
  data: () => ({}),
};
</script>