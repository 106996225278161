<template>
  <div id="websitelayout">
    <v-app-bar app clipped-left>
      <v-img
        class="mr-3"
        src="@/assets/logo.png"
        max-height="50"
        max-width="93"
        contain
      ></v-img>
      <v-spacer></v-spacer>
      <div
        v-if="
          (!userSignedIn && screenWidth > 878) ||
          (userSignedIn && screenWidth > 1137)
        "
        class="d-flex flex-row"
        style="gap: 12px"
      >
        <v-btn text to="/"
          ><v-icon class="mr-2">mdi-home</v-icon
          >{{ $i18n.t("headings.home") }}</v-btn
        >
        <v-btn text to="/prices"
          ><v-icon class="mr-2">mdi-currency-usd</v-icon
          >{{ $i18n.t("headings.prices") }}</v-btn
        >
        <v-btn text to="/how-it-works"
          ><v-icon class="mr-2">mdi-help-circle-outline</v-icon
          >{{ $i18n.t("headings.howItWorks") }}</v-btn
        >
        <v-btn text to="/about"
          ><v-icon class="mr-2">mdi-information-outline</v-icon
          >{{ $i18n.t("headings.about") }}</v-btn
        >
        <v-btn text to="/meet-the-team"
          ><v-icon class="mr-2">mdi-account-heart-outline</v-icon
          >{{ $i18n.t("headings.meetTheTeam") }}</v-btn
        >
        <v-btn text to="/contact-us"
          ><v-icon class="mr-2">mdi-email-outline</v-icon
          >{{ $i18n.t("headings.contactUs") }}</v-btn
        >
        <v-btn
          v-if="!userSignedIn"
          outlined
          dark
          to="/app/products"
          color="primary"
          ><v-icon class="mr-2">mdi-login-variant</v-icon
          >{{ $i18n.t("headings.signIn") }}</v-btn
        >
        <v-btn
          v-else
          outlined
          dark
          @click="$store.dispatch('signOut')"
          color="secondary"
          ><v-icon class="mr-2">mdi-logout-variant</v-icon
          >{{ $i18n.t("headings.signOut") }}</v-btn
        >
        <v-btn fab small icon v-if="userSignedIn" to="/app/products">
          <v-icon color="primary">mdi-account-outline</v-icon>
        </v-btn>
      </div>
      <div
        v-else-if="screenWidth > 622"
        class="d-flex flex-row"
        style="gap: 12px"
      >
        <v-btn icon to="/"><v-icon>mdi-home</v-icon></v-btn>
        <v-btn icon to="/prices"><v-icon>mdi-currency-usd</v-icon></v-btn>
        <v-btn icon to="/how-it-works"
          ><v-icon>mdi-help-circle-outline</v-icon></v-btn
        >
        <v-btn icon to="/about"><v-icon>mdi-information-outline</v-icon></v-btn>
        <v-btn icon to="/meet-the-team"
          ><v-icon>mdi-account-heart-outline</v-icon></v-btn
        >
        <v-btn icon to="/contact-us"><v-icon>mdi-email-outline</v-icon></v-btn>
        <v-btn v-if="!userSignedIn" icon to="/app/products" color="primary"
          ><v-icon>mdi-login-variant</v-icon></v-btn
        >
        <v-btn v-else icon @click="$store.dispatch('signOut')" color="secondary"
          ><v-icon>mdi-logout-variant</v-icon></v-btn
        >
        <v-btn v-if="userSignedIn" icon to="/app/products"
          ><v-icon color="primary">mdi-account-outline</v-icon></v-btn
        >
        <v-app-bar-nav-icon @click="rightDrawer = true"></v-app-bar-nav-icon>
      </div>
      <div v-else>
        <v-app-bar-nav-icon @click="rightDrawer = true"></v-app-bar-nav-icon>
      </div>
    </v-app-bar>

    <v-main fluid fill-height>
      <router-view />
    </v-main>

    <v-navigation-drawer v-model="rightDrawer" right app temporary>
      <div class="d-flex flex-row pa-2">
        <v-img class="mr-3" src="@/assets/logo-full.svg" contain></v-img>
      </div>
      <v-responsive class="mx-auto" width="56">
        <v-divider></v-divider>

        <v-divider></v-divider>
      </v-responsive>
      <v-list nav dense>
        <v-list-item-group
          v-model="rightDrawerListGroup"
          active-class="secondary"
        >
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Home</v-list-item-title
            >
          </v-list-item>

          <v-list-item v-if="userSignedIn" to="/app/products">
            <v-list-item-icon>
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Profile</v-list-item-title
            >
          </v-list-item>

          <v-list-item to="/prices">
            <v-list-item-icon>
              <v-icon>mdi-currency-usd</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Prices</v-list-item-title
            >
          </v-list-item>

          <v-list-item to="/how-it-works">
            <v-list-item-icon>
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >How It Works</v-list-item-title
            >
          </v-list-item>

          <v-list-item to="/about">
            <v-list-item-icon>
              <v-icon>mdi-information-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >About</v-list-item-title
            >
          </v-list-item>

          <v-list-item to="/meet-the-team">
            <v-list-item-icon>
              <v-icon>mdi-account-heart-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Meet the Team</v-list-item-title
            >
          </v-list-item>

          <v-list-item to="/contact-us">
            <v-list-item-icon>
              <v-icon>mdi-email-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Contact Us</v-list-item-title
            >
          </v-list-item>

          <v-list-item v-if="!userSignedIn" to="/app/products">
            <v-list-item-icon>
              <v-icon>mdi-login-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Sign In</v-list-item-title
            >
          </v-list-item>

          <v-list-item v-else @click="$store.dispatch('signOut')">
            <v-list-item-icon>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Sign Out</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <Footer :showLegal="false" />
  </div>
</template>

<script>
import Vue from "vue";
import Footer from "@/components/global-components/Footer.vue";
// import store from "@/store";
import { mapState } from "vuex";

export default Vue.extend({
  name: "WebsiteLayout",
  data: () => ({
    drawer: false,
    rightDrawer: false,
    rightDrawerListGroup: null,
  }),
  methods: {},
  components: {
    Footer,
  },
  computed: {
    screenWidth() {
      let width = this.$vuetify.breakpoint.width;
      return width;
    },
    ...mapState({
      userSignedIn: (state) => state.userSignedIn,
    }),
  },
});
</script>
