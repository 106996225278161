<template>
  <v-app>
    <component :is="layoutComponent">
      <router-view />
    </component>
    <!-- <v-btn @click="createEmailTemplate">Template</v-btn> -->
    <CircularLoader v-if="appLoading" />
    <OfflineWarning v-if="offline" />
    <v-dialog persistent v-model="dialogSessionWarning" max-width="505px">
      <v-card>
        <v-card-title class="justify-center" style="word-break: break-word"
          >Miss Anri's ClassNetwork is open in another window. Click "Use Here"
          to use Miss Anri's ClassNetwork in this window.</v-card-title
        >
        <v-card-actions>
          <v-btn
            depressed
            color="secondary"
            class="black--text"
            @click="sessionWarningClose"
            >Close</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="sessionWarningUseHere"
            >Use Here</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Vue from "vue";
import store from "@/store";
import { mapState } from "vuex";
import CircularLoader from "@/components/global-components/CircularLoader.vue";
import OfflineWarning from "@/components/global-components/OfflineWarning.vue";
import NetworkStatus from "@/mixins/networkStatus.js";
import { DataStore, Auth, API, syncExpression } from "aws-amplify";
import { TeacherProfile, Announcement, Product } from "@/models";
// import AWS from "aws-sdk";
import _ from "lodash";

export default Vue.extend({
  name: "App",
  components: { CircularLoader, OfflineWarning },
  mixins: [NetworkStatus],
  data: () => ({
    initiallySynced: false,
    localUserGroups: [],
    teacherProfileSubscription: null,
    announcementSubscription: null,
    syncedTeacherProfileModel: null,
    dialogSessionWarning: false,
    newTeacherSessionToken: "",
    dataStoreConfig: "",
  }),

  async beforeMount() {
    try {
      this.getUserGroups();

      const user = await Auth.currentAuthenticatedUser().catch(() => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
      });

      store.commit("storeUserSignedIn", user ? true : false);
      store.commit("storeUserEmail", user ? user.attributes.email : "");

      if (!user) {
        // console.log("dataStoreConfig = public");
        this.dataStoreConfig = "public";
        DataStore.configure({
          syncExpressions: [
            syncExpression(
              Product,
              () => {
                const currentDateTime = new Date().toISOString();

                return (product) =>
                  product.and((product) => [
                    product.thumbnails("ne", []),
                    product.thumbnails("ne", null),
                    product.publishDate("lt", currentDateTime),
                    product.featured("eq", true),
                  ]);
              },
              {
                limit: 999,
              }
            ),
          ],
        });
        // DataStore.start();
      } else if (user && !this.teacherProfileSubscription) {
        this.syncTeacherProfile();
      }
    } catch (error) {
      console.log(error);
    }
  },

  watch: {
    userSignedIn: {
      handler: async function (val) {
        if (val) {
          if (this.dataStoreConfig === "public") {
            // console.log("dataStoreConfig = private");
            await DataStore.stop();
            this.dataStoreConfig = "private";
            DataStore.configure({
              syncExpressions: [
                syncExpression(Product, (product) => {
                  return product;
                }),
              ],
            });
          }
          this.syncTeacherProfile();
        }
      },
      immediate: true,
    },
  },

  computed: {
    layoutComponent() {
      return this.$route && this.$route.meta && this.$route.meta.layout;
    },

    ...mapState({
      offline: (state) => !state.isOnline,
      userGroups: (state) => state.userGroups,
      userSignedIn: (state) => state.userSignedIn,
      userEmail: (state) => state.userEmail,
      appLoading: (state) => state.appLoading,
      teacherProfileSchoolID: (state) => state.teacherProfileSchoolID,
      teacherProfileProvince: (state) => state.teacherProfileProvince,
      teacherProfileID: (state) => state.teacherProfileID,
      teacherProfilePayfastSubscriptionToken: (state) =>
        state.teacherProfilePayfastSubscriptionToken,
      teacherProfilePayfastSubscriptionResponse: (state) =>
        state.teacherProfilePayfastSubscriptionResponse,
      teacherProfileAppSyncVersion: (state) =>
        state.teacherProfileAppSyncVersion,
    }),
  },

  methods: {
    // createEmailTemplate() {
    //   // const AWS = require("aws-sdk");
    //   AWS.config.update({
    //     region: "af-south-1",
    //     accessKeyId: "",
    //     secretAccessKey: "",
    //   });
    //   var ses = new AWS.SES();
    //   var params = {
    //     Template: {
    //       TemplateName: "ContactUsSupportTemplate",
    //       SubjectPart: "New Miss Anri's ClassNetwork Support Request",
    //       TextPart: `Name: {{inputName}}
    // Email: {{inputEmail}}
    // Category: {{inputCategory}}
    // Subject: {{inputSubject}}
    // Message: {{inputMessage}}`,
    //       HtmlPart:
    //         "<p>Name: {{inputName}}</p><p>Email: {{inputEmail}}</p><p>Category: {{inputCategory}}</p><p>Subject: {{inputSubject}}</p><p>Message: {{inputMessage}}</p>",
    //     },
    //   };
    //   ses.createTemplate(params, function (err, data) {
    //     if (err) console.log(err, err.stack);
    //     else console.log(data);
    //   });
    // },

    async getUserGroups() {
      const user = await Auth.currentAuthenticatedUser().catch(() => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
      });

      if (user) {
        await API.post("cognitolambdaapi", "/item/listUserGroups", {
          body: {
            userPoolId: user.pool.userPoolId,
            userName: user.username,
          },
        })
          .then(async (cognitoListUserGroupsResponse) => {
            if (cognitoListUserGroupsResponse.list) {
              this.localUserGroups = JSON.parse(
                cognitoListUserGroupsResponse.list
              );
              store.commit("storeUserGroups", this.localUserGroups);
            }
          })
          .catch((error) => {
            // Log any errors
            console.log("error:", error);
          });
      }
    },

    sessionWarningClose() {
      store.dispatch("signOut");
      this.dialogSessionWarning = false;
    },

    async sessionWarningUseHere() {
      this.newTeacherSessionToken = Date.now().toString();
      // console.log("this.newTeacherSessionToken:", this.newTeacherSessionToken);
      localStorage.setItem("teacherSessionToken", this.newTeacherSessionToken);

      await DataStore.save(
        TeacherProfile.copyOf(this.syncedTeacherProfileModel, (updateModel) => {
          updateModel.sessionToken = this.newTeacherSessionToken;
        })
      );

      this.dialogSessionWarning = false;
    },

    async checkPayfastSubscription() {
      // console.log(
      //   "App.checkPayfastSubscription",
      //   this.teacherProfilePayfastSubscriptionToken
      // );
      if (!this.teacherProfilePayfastSubscriptionToken) {
        return;
      }

      let subscriptionStillValid = false;

      await API.get(
        "payfastsubscriptionapi",
        `/payfast/status/${this.teacherProfilePayfastSubscriptionToken}`
      )
        .then(async (payfastStatusResponse) => {
          // console.log("payfastStatusResponse:", payfastStatusResponse);
          if (
            this.teacherProfilePayfastSubscriptionResponse !==
            payfastStatusResponse.success.data.response
          ) {
            await DataStore.save(
              TeacherProfile.copyOf(
                this.syncedTeacherProfileModel,
                (updateModel) => {
                  updateModel.payfastSubscriptionResponse =
                    typeof payfastStatusResponse.success.data.response ===
                    "object"
                      ? payfastStatusResponse.success.data.response
                      : // {
                        //     ...payfastStatusResponse.success.data.response,
                        //     run_date: "2024-07-21T00:00:00+02:00",
                        //   }
                        null;
                }
              )
            );

            // store.commit(
            //   "storeTeacherProfilePayfastSubscriptionResponse",
            //   payfastStatusResponse.success.data.response
            // );
          }

          const user = await Auth.currentAuthenticatedUser().catch(() => {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
          });

          if (user) {
            if (payfastStatusResponse.success.data.response.run_date) {
              const runDate = new Date(
                payfastStatusResponse.success.data.response.run_date
              );
              const currentDate = new Date();
              subscriptionStillValid = runDate > currentDate;
              // Compare the dates
            }

            if (
              payfastStatusResponse.success.data.response?.status_text ===
                "ACTIVE" ||
              subscriptionStillValid
            ) {
              if (!this.localUserGroups.includes("teacher")) {
                // User is not part of the 'teacher' group
                // console.log('User is not a teacher');

                await API.post("cognitolambdaapi", "/item/addUserToGroup", {
                  body: {
                    groupName: "teacher",
                    userPoolId: user.pool.userPoolId,
                    userName: user.username,
                  },
                })
                  .then(() => {
                    this.localUserGroups.push("teacher");
                    // console.log('this.localUserGroups: ', this.localUserGroups)
                    store.commit("storeUserGroups", this.localUserGroups);
                  })
                  .catch((error) => {
                    // Log any errors
                    console.log("error:", error);
                  });
              }
            } else if (this.localUserGroups.includes("teacher")) {
              await API.post("cognitolambdaapi", "/item/removeUserFromGroup", {
                body: {
                  groupName: "teacher",
                  userPoolId: user.pool.userPoolId,
                  userName: user.username,
                },
              })
                .then(() => {
                  let index = this.localUserGroups.indexOf("teacher");

                  if (index !== -1) {
                    this.localUserGroups.splice(index, 1);
                  }

                  // console.log("this.localUserGroups: ", this.localUserGroups);
                  store.commit("storeUserGroups", this.localUserGroups);
                })
                .catch((error) => {
                  // Log any errors
                  console.log("error:", error);
                });
            }
          }

          // console.log(payfastStatusResponse);
          store.commit("storeTeacherProfilePayfastSubscriptionResponse", {
            ...payfastStatusResponse.success.data.response,
            // run_date: "2024-07-21T00:00:00+02:00",
            subscriptionStillValid,
          });
        })
        .catch(async (error) => {
          store.commit(
            "storeTeacherProfilePayfastSubscriptionResponse",
            this.syncedTeacherProfileModel
              ? {
                  ...this.syncedTeacherProfileModel.payfastSubscriptionResponse,
                  subscriptionStillValid,
                }
              : null
          );

          console.error(error.message);
        });
    },

    async syncTeacherProfile() {
      try {
        this.teacherProfileSubscription = DataStore.observeQuery(
          TeacherProfile
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              JSON.stringify(items[0]) !==
                JSON.stringify(this.syncedTeacherProfileModel)
            ) {
              const newSyncedTPM = items[0];
              this.syncedTeacherProfileModel = newSyncedTPM;

              // console.log("newSyncedTPM:", newSyncedTPM);

              store.commit("storeTeacherProfileModel", newSyncedTPM);

              if (newSyncedTPM.translateI18n > "") {
                const newLang = newSyncedTPM.translateI18n;
                // newSyncedTPM.language === "Afrikaans" ? "af" : "en";
                this.$vuetify.lang.current = newLang;
                this.$i18n.locale = newLang;
              }

              if (
                !this.initiallySynced ||
                this.teacherProfilePayfastSubscriptionToken !==
                  newSyncedTPM.payfastSubscriptionToken
              ) {
                store.commit(
                  "storeTeacherProfilePayfastSubscriptionToken",
                  newSyncedTPM.payfastSubscriptionToken
                );
                this.checkPayfastSubscription();

                this.initiallySynced = true;
              }

              if (this.teacherProfileID !== newSyncedTPM.id) {
                store.commit("storeTeacherProfileID", items[0].id);
              }

              if (typeof newSyncedTPM._version === "string") {
                if (
                  this.teacherProfileAppSyncVersion !== newSyncedTPM._version
                ) {
                  store.commit(
                    "storeTeacherProfileAppSyncVersion",
                    newSyncedTPM._version
                  );
                }
              } else if (newSyncedTPM._version) {
                if (
                  this.teacherProfileAppSyncVersion !==
                  newSyncedTPM._version.toString()
                ) {
                  store.commit(
                    "storeTeacherProfileAppSyncVersion",
                    newSyncedTPM._version.toString()
                  );
                }
              }

              if (this.teacherProfileProvince !== newSyncedTPM.province) {
                store.commit(
                  "storeTeacherProfileProvince",
                  newSyncedTPM.province
                );
              }

              if (
                this.teacherProfileSchoolID !==
                newSyncedTPM.teacherProfileSchoolId
              ) {
                store.commit(
                  "storeTeacherProfileSchoolID",
                  newSyncedTPM.teacherProfileSchoolId
                );
              }

              const currentSessionTokenGet = JSON.parse(
                localStorage.getItem("teacherSessionToken")
              );
              const currentSessionToken = currentSessionTokenGet
                ? currentSessionTokenGet.toString()
                : "";

              if (typeof newSyncedTPM.sessionToken === "string") {
                if (currentSessionToken === "") {
                  this.newTeacherSessionToken = Date.now().toString();

                  localStorage.setItem(
                    "teacherSessionToken",
                    this.newTeacherSessionToken
                  );

                  await DataStore.save(
                    TeacherProfile.copyOf(newSyncedTPM, (updateModel) => {
                      updateModel.sessionToken = this.newTeacherSessionToken;
                    })
                  );
                } else if (newSyncedTPM.sessionToken !== currentSessionToken) {
                  this.dialogSessionWarning = true;
                }
              } else if (this.newTeacherSessionToken === "") {
                this.newTeacherSessionToken = Date.now().toString();

                localStorage.setItem(
                  "teacherSessionToken",
                  this.newTeacherSessionToken
                );

                await DataStore.save(
                  TeacherProfile.copyOf(newSyncedTPM, (updateModel) => {
                    updateModel.sessionToken = this.newTeacherSessionToken;
                  })
                );
              }

              if (
                newSyncedTPM.payfastSubscriptionPackage
                  ?.proRataPaymentSuccessful === true &&
                newSyncedTPM.sessionToken === currentSessionToken
              ) {
                const newPackage = _.cloneDeep(
                  newSyncedTPM.payfastSubscriptionPackage
                );
                delete newPackage.proRataPaymentSuccessful;

                const recurring_amount = (
                  (newPackage.grades.length > 1
                    ? newPackage.perGrade * (newPackage.grades.length - 1) +
                      newPackage.amount
                    : newPackage.amount) * 100
                ).toFixed(0);

                const bodyToUpdate = {
                  teacherProfileID: newSyncedTPM.id,
                  package: newPackage,
                  payfastSubscriptionToken:
                    newSyncedTPM.payfastSubscriptionToken,
                  recurring_amount: recurring_amount,
                };

                await this.updatePayfastSubscription(bodyToUpdate);
              }

              this.syncAnnouncements(newSyncedTPM.id);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncAnnouncements(teacherProfileID) {
      try {
        this.announcementSubscription = DataStore.observeQuery(
          Announcement,
          (a) =>
            a.and((a) => [
              a.or((a) => [
                a.sendTo("contains", teacherProfileID),
                a.sendTo("contains", "ALL"),
              ]),
              a.readBy("notContains", teacherProfileID),
            ])
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items) {
              if (items.length > 0) {
                store.commit("storeNotReadAnnouncements", items);
              } else {
                store.commit("storeNotReadAnnouncements", null);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async updatePayfastSubscription(bodyToUpdate) {
      try {
        await API.post("payfastsubscriptionapi", "/payfast/update", {
          body: bodyToUpdate,
        })
          .catch((error) => {
            // Log any errors
            console.log("error:", error);

            // DataStore.save(
            //   TeacherProfile.copyOf(
            //     this.syncedTeacherProfileModel,
            //     (updateModel) => {
            //       updateModel.payfastSubscriptionPackage =
            //         typeof this.syncedTeacherProfileModel
            //           .payfastSubscriptionPackage === "object"
            //           ? {
            //               ...this.syncedTeacherProfileModel
            //                 .payfastSubscriptionPackage,
            //               pendingPackage: bodyToUpdate.package,
            //             }
            //           : { pendingPackage: bodyToUpdate.package };
            //     }
            //   )
            // );
          })
          .then(async (response) => {
            // console.log("response:", response);
            // console.log(
            //   "response.success:",
            //   response.success ? "true" : "false"
            // );

            if (
              response?.success
              //  && (!amountToPay || amountToPay <= 0)
            ) {
              DataStore.save(
                TeacherProfile.copyOf(
                  this.syncedTeacherProfileModel,
                  (updateModel) => {
                    updateModel.payfastSubscriptionPackage =
                      bodyToUpdate.package;
                  }
                )
              );
            }
            //  else {
            //   DataStore.save(
            //     TeacherProfile.copyOf(
            //       this.syncedTeacherProfileModel,
            //       (updateModel) => {
            //         updateModel.payfastSubscriptionPackage =
            //           typeof this.syncedTeacherProfileModel
            //             .payfastSubscriptionPackage === "object"
            //             ? {
            //                 ...this.syncedTeacherProfileModel
            //                   .payfastSubscriptionPackage,
            //                 pendingPackage: bodyToUpdate.package,
            //               }
            //             : { pendingPackage: bodyToUpdate.package };
            //       }
            //     )
            //   );
            // }

            this.checkPayfastSubscription();
          });
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    if (this.teacherProfileSubscription) {
      this.teacherProfileSubscription.unsubscribe();
    }
  },
});
</script>
