<template>
  <v-dialog persistent v-model="dialogTermsAndConditions">
    <v-card
      style="overflow-y: scroll; max-height: 90vh"
      id="termsAndConditionsCard"
    >
      <v-card-title class="justify-center text-h5">
        ClassNetwork Terms and Conditions
      </v-card-title>
      <termsAndConditions />
      <v-card-title class="justify-center text-h5">
        Privacy Policy
      </v-card-title>
      <privacyPolicy />
      <v-card-title class="justify-center text-h5">
        Refund, Returns and Subscription Policy
      </v-card-title>
      <refundReturnPolicy />

      <div class="px-5" v-if="subscriptionComp">
        <v-checkbox
          v-model="termsAndConditionsAccepted"
          label="I have read and accept the terms and conditions."
        ></v-checkbox>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="closeDialogTermsAndConditions">{{
          subscriptionComp ? "Cancel" : "Close"
        }}</v-btn>
        <v-btn
          v-if="subscriptionComp"
          color="primary"
          :disabled="!termsAndConditionsAccepted"
          @click="acceptTAC"
          >Subscribe</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import termsAndConditions from "@/assets/app-assets/global/termsAndConditions.vue";
import privacyPolicy from "@/assets/app-assets/global/privacyPolicy.vue";
import refundReturnPolicy from "@/assets/app-assets/global/refundReturnPolicy.vue";

export default {
  name: "TemplatePrinterDialog",
  components: {
    termsAndConditions,
    privacyPolicy,
    refundReturnPolicy,
  },
  props: {
    subscriptionComp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogTermsAndConditions: true,
      termsAndConditionsAccepted: false,
    };
  },
  computed: {},
  methods: {
    acceptTAC() {
      this.$emit("acceptTAC");
    },
    closeDialogTermsAndConditions() {
      this.$emit("closeDialogTermsAndConditions");
    },
  },
};
</script>

<style>
#termsAndConditionsCard li {
  margin-bottom: 0.6em;
}

ol.numbered-list {
  margin: 0;
  padding: 0;
}

ol.numbered-list > li {
  display: table;
  counter-increment: item;
}

ol.numbered-list > li:before {
  display: table-cell;
  padding-right: 0.6em;
}

ol.list-1 > li:before {
  content: "1." counters(item, ".") ". ";
}

ol.list-2 > li:before {
  content: "2." counters(item, ".") ". ";
}

ol.list-3 > li:before {
  content: "3." counters(item, ".") ". ";
}

ol.list-4 > li:before {
  content: "4." counters(item, ".") ". ";
}

ol.list-5 > li:before {
  content: "5." counters(item, ".") ". ";
}

ol.list-6 > li:before {
  content: "6." counters(item, ".") ". ";
}

ol.list-7 > li:before {
  content: "7." counters(item, ".") ". ";
}

/* .numbered-list li ol>li {
    margin: 0;
}

.numbered-list li ol>li:before {
    content: counters(item, ".") " ";
} */
</style>
