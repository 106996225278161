<template>
  <div id="pagenotfound">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6" offset-md="3">
          <v-card class="elevation-12 text-center">
            <v-card-title> 404 </v-card-title>
            <v-card-subtitle> Page Not Found </v-card-subtitle>
            <v-card-text>
              <p>
                The page you are looking for might have been removed, had its
                name changed, or is temporarily unavailable.
              </p>
              <v-btn color="primary" to="/"> Go to Home </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
      
<script lang="ts">
export default {
  name: "PageNotFoundView",
  data: () => ({}),
};
</script>