<template>
  <div id="adminsubjecttemplates">
    <div class="pa-2">
      <v-row justify="space-between">
        <v-col cols="12">
          <SubjectTemplates />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import SubjectTemplates from "@/components/admin-components/global/SubjectTemplates.vue";
export default {
  name: "AdminSubjectTemplates",
  components: { SubjectTemplates },
  data: () => ({}),
};
</script>