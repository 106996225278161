import { mapState } from "vuex";

export default {
    data: function () {
        return {
            isOnline: navigator.onLine
        }
    },

    computed: {
        ...mapState({
            isStateOnline: (state) => state.isOnline,
        }),
    },

    // Watch for changes in isOnline and isOffline
    watch: {
        isOnline: {
            handler: async function (val) {
                if (val || navigator.onLine) {
                    if (!this.isStateOnline) this.$store.commit("storeIsOnline", true); // Update this based on how you have defined storeDevice
                } else {
                    if (this.isStateOnline) this.$store.commit("storeIsOnline", false); // Update this based on how you have defined storeDevice
                }
            },
            immediate: true,
        },
    },

    mounted: function () {
        try {
            if (typeof window !== 'undefined') {
                // Initial state
                this.$store.commit("storeIsOnline", navigator.onLine); // Update the initial status

                // Attach event listeners
                window.addEventListener('online', this.onlineHandler);
                window.addEventListener('offline', this.offlineHandler);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    },

    beforeDestroy: function () {
        if (typeof window !== 'undefined') {
            window.removeEventListener('online', this.onlineHandler);
            window.removeEventListener('offline', this.offlineHandler);
        }
    },

    methods: {
        onlineHandler: function () {
            this.isOnline = true;
            if (!this.isStateOnline) this.$store.commit("storeIsOnline", true);
        },
        offlineHandler: function () {
            this.isOnline = false;
            if (this.isStateOnline) this.$store.commit("storeIsOnline", false);
        }
    }
}