<template>
  <div id="announcementmessage">
    <v-alert prominent color="secondary" icon="mdi-bell">
      <v-row align="center">
        <v-col class="grow">
          <span
            style="font-size: x-large"
            v-html="announcementMessageComp"
          ></span>
        </v-col>
        <v-col class="shrink">
          <v-btn :disabled="loading" color="primary" @click="readAnnouncement"
            >Read</v-btn
          >
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore } from "aws-amplify";
import { Announcement } from "@/models";

export default {
  name: "AnnouncementMessage",

  data: () => ({
    loading: false,
  }),

  props: {
    announcement: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState({
      teacherProfileID: (state) => state.teacherProfileID,
    }),

    announcementMessageComp() {
      // Regular expression to match URLs
      const urlRegex = /([^\S]|^)(((https?:\/\/)|(www.))(\S+))/gi;

      // Domain of your application for checking internal URLs
      const appDomain = window.location.origin;

      console.log("appDomain", appDomain);

      return this.announcement.message.replace(
        urlRegex,
        function (match, space, url) {
          let hyperlink = url;

          // Check and prepend 'https://' if the URL starts with 'www'
          if (!hyperlink.match("^https?://")) {
            hyperlink = "https://" + hyperlink;
          }

          // Check if the URL is internal
          if (hyperlink.includes(appDomain)) {
            // Extract the path without the domain and protocol to use in router-link
            // const internalPath = hyperlink.split(appDomain)[1];
            return `${space}<a href="${hyperlink}">${url}</a>`;
          } else {
            // For external links, use a regular <a> tag with target="_blank"
            return `${space}<a href="${hyperlink}" target="_blank">${url}</a>`;
          }
        }
      );
    },
  },

  //   mounted() {
  //     console.log(this.announcement);
  //   },

  methods: {
    async readAnnouncement() {
      try {
        this.loading = true;

        await DataStore.save(
          Announcement.copyOf(this.announcement, (updateModel) => {
            updateModel.readBy = this.announcement.readBy
              ? [...this.announcement.readBy, this.teacherProfileID]
              : [this.teacherProfileID];
          })
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
