<template>
  <div id="terms">
    <section id="terms-section">
      <div class="py-12"></div>

      <v-container>
        <!-- <v-card-title class="justify-center text-h5">
      ClassNetwork Terms and Conditions
    </v-card-title> -->
        <h2 class="display-2 font-weight-bold mb-3">
          ClassNetwork Terms and Conditions
        </h2>

        <termsAndConditions />
        <!-- <v-card-title class="justify-center text-h5"> Privacy Policy </v-card-title> -->
        <h2 class="display-2 font-weight-bold mb-3">Privacy Policy</h2>
        <privacyPolicy />
        <!-- <v-card-title class="justify-center text-h5">
      Refund and Returns Policy
    </v-card-title> -->
        <h2 class="display-2 font-weight-bold mb-3">
          Refund and Returns Policy
        </h2>
        <refundReturnPolicy />
      </v-container>
    </section>
  </div>
</template>

<script>
import termsAndConditions from "@/assets/app-assets/global/termsAndConditions.vue";
import privacyPolicy from "@/assets/app-assets/global/privacyPolicy.vue";
import refundReturnPolicy from "@/assets/app-assets/global/refundReturnPolicy.vue";

export default {
  name: "TermsView",
  components: {
    termsAndConditions,
    privacyPolicy,
    refundReturnPolicy,
  },
  data: () => ({}),
};
</script>
