<template>
  <div id="adminannouncements">
    <div class="pa-2">
      <v-row justify="space-between">
        <v-col>
          <Announcements />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import Announcements from "@/components/admin-components/global/Announcements.vue";
export default {
  name: "AdminAnnouncements",
  components: { Announcements },
  data: () => ({}),
};
</script>
