<template>
    <!-- Your print-friendly content goes here -->
    <div v-if="template" :style="printCSS" id="lessonTemplatePrinterExample">
        <div>
            <v-row no-gutters class="py-2">
                <v-col cols="5" class="py-0 d-flex flex-column">
                    <span v-if="template.subject" class="font-weight-bold">{{
                        headingValue("Grade") +
                        " " +
                        template.subject.grade +
                        " " +
                        template.subject.subject
                    }}</span>
                    <span class="font-weight-bold">{{ template.name }}</span>
                </v-col>
                <v-col cols="5" class="py-0 d-flex flex-column">
                    <span class="font-weight-bold">{{ headingValue("Date") }}:</span>
                    <span class="font-weight-bold">{{ headingValue("Term") }}:</span>
                    <span class="font-weight-bold">{{ headingValue("Total") }}:&emsp;&emsp;/{{
                        template.totalPoints
                    }}</span>
                    <span class="font-weight-bold">{{
                        headingValue("Time") + ": " + formattedTotalTime
                    }}</span>
                </v-col>
                <v-col cols="2" class="py-0 d-flex flex-column align-end">
                    <div v-if="lessonImgSrc !== ''" class="pb-2">
                        <img :src="lessonImgSrc" style="max-height: 48px" />
                    </div>
                    <div>
                        <img src="@/assets/logo-full.svg" style="max-height: 48px" />
                    </div>
                </v-col>
            </v-row>

            <hr class="my-1" />

            <div v-if="template.template?.length > 0">
                <div v-for="(section, sectionIndex) in template.template" :key="sectionIndex">
                    <h2>{{ section.name }}</h2>

                    <div v-for="(field, fieldIndex) in section.fields" :key="fieldIndex">
                        <p>
                            {{ field.label
                            /* + ' - ' + headingValue("Difficulty") + ': ' + field.difficulty */
                            }}
                        </p>
                        <p style="text-decoration: underline">{{ formattedFieldValue(field.value) }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";

export default {
    name: "LessonTemplatePrinterExample",
    props: {
        template: Object, // JSON object to be printed
        fontSize: {
            type: Number,
            default: 12,
        }, // Font size to be used in the content
        lessonImgSrc: {
            type: String,
            default: '',
        },
    },
    // data() {
    //     return {
    //     };
    // },
    methods: {
        formattedFieldValue(fieldValue) {
            return Array.isArray(fieldValue) ? fieldValue.map(String).join(', ') : fieldValue;
        },
    },
    computed: {

        formattedTotalTime() {
            const totalMinutes =
                typeof this.template.minutes !== "string"
                    ? this.template.minutes
                    : parseInt(this.template.minutes);
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            if (hours === 0) {
                return `${minutes} Minute${minutes > 1 ? "s" : ""}`;
            } else if (minutes === 0) {
                return `${hours} Hour${hours > 1 ? "s" : ""}`;
            } else {
                return `${hours} Hour${hours > 1 ? "s" : ""} ${minutes} Minute${minutes > 1 ? "s" : ""
                    }`;
            }
        },
        printCSS() {
            if (this.template.fontFamily && this.template.fontFamily !== 'Default') {
                return {
                    fontSize: this.fontSize + 'px',
                    fontFamily: this.template.fontFamily
                }
            } else return { fontSize: this.fontSize + 'px' }
        },
        headingValue() {
            return (param) => {
                if (this.template.headings) {
                    const foundItem = _.find(this.template.headings, { label: param });
                    return foundItem ? foundItem.value : param;
                } else return param;
            };
        },
    },
};
</script>

<style></style>  