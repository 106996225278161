import { Image } from "@tiptap/extension-image";
import { mergeAttributes } from "@tiptap/core";

export const ResizableImage = Image.extend({
    name: 'ResizableImage',

    addAttributes() {
        return {
            ...this.parent?.(),
            width: {
                default: 'auto',
            },
            height: {
                default: 'auto',
            },
            textAlign: {
                default: 'left', // default alignment
            }
        };
    },

    // addCommands() {
    //     return {
    //         setImageAlignment: (alignment) => ({ commands }) => {
    //             return commands.updateAttributes('ResizableImage', { textAlign: alignment });
    //         },
    //     };
    // },

    renderHTML({ node, HTMLAttributes }) {
        // console.log('HTMLAttributes: ', HTMLAttributes)
        // const alignmentStyles = {
        //     'left': "text-align: left;",
        //     'center': "text-align: center;",
        //     'right': "text-align: right;"
        // };

        // const figureStyle = `width: ${HTMLAttributes.width}; height: ${HTMLAttributes.height}; text-align: ${HTMLAttributes.textAlign};`;
        const figureStyle = `text-align: ${node.attrs.textAlign};`;

        // const { style } = HTMLAttributes;
        return [
            "figure",
            {
                style: figureStyle,
                class: 'ResizableImageFig'
            },
            ["img", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes
                // , {style: figureStyle}
            )]
        ];
    },
});

// import Image from '@tiptap/extension-image';
// // import { Storage } from "@aws-amplify/storage"
// // import { VueNodeViewRenderer } from '@tiptap/vue-2';
// // import ResizableImageTemplate from '@/components/global-components/rich-text-editor/ResizableImageTemplate.vue';

// export default Image.extend({
//     name: 'ResizableImage',

//     parseHTML() {
//         return [
//             {
//                 tag: 'div.ResizableImage',
//                 getAttrs: (element) => {
//                     const imgElement = element.querySelector('img');
//                     return {
//                         alignment: element.style.textAlign || 'left',
//                         src: imgElement ? imgElement.getAttribute('src') || '' : '',
//                         width: imgElement ? imgElement.getAttribute('width') || '100%' : '100%',
//                         height: imgElement ? imgElement.getAttribute('height') || 'auto' : 'auto',
//                         isDraggable: imgElement ? imgElement.getAttribute('is-draggable') || true : true,
//                         alt: imgElement ? imgElement.getAttribute('alt') || '' : '',
//                         title: imgElement ? imgElement.getAttribute('title') || '' : '',
//                         imageSrcData: imgElement ? imgElement.getAttribute('image-src-data') || '' : '',
//                     };
//                 },
//             },
//         ];
//     },

//     renderHTML({ node }) {
//         const divAttrs = {
//             style: `text-align: ${node.attrs.alignment}`,
//             class: 'ResizableImage',
//         };

//         const imgAttrs = {
//             src: node.attrs.src,
//             width: node.attrs.width,
//             height: node.attrs.height,
//             'is-draggable': node.attrs.isDraggable,
//             alt: node.attrs.alt,
//             title: node.attrs.title,
//             'image-src-data': node.attrs.imageSrcData,
//         };

//         return ['div', divAttrs, ['img', imgAttrs]];
//     },

//     addAttributes() {
//         return {
//             ...this.parent?.(),

//             alignment: {
//                 default: 'left',
//                 // parseHTML: (element) => ({
//                 //     alignment: element.getAttribute('alignment') || 'left',
//                 // }),
//                 renderHTML: (attributes) => {
//                     return {
//                         'alignment': attributes.alignment
//                     };
//                 }
//             },
//             src: {
//                 default: '',
//                 renderHTML: (attributes) => ({
//                     src: attributes.src
//                 })
//             },
//             width: {
//                 default: '100%',
//                 // parseHTML: (element) => ({
//                 //     width: element.querySelector('img')?.getAttribute('width') || '100%',
//                 // }),
//                 renderHTML: (attributes) => ({
//                     width: attributes.width,
//                 }),
//             },
//             height: {
//                 default: 'auto',
//                 // parseHTML: (element) => ({
//                 //     height: element.querySelector('img')?.getAttribute('height') || 'auto',
//                 // }),
//                 renderHTML: (attributes) => ({
//                     height: attributes.height,
//                 }),
//             },
//             isDraggable: {
//                 default: true,
//                 // parseHTML: (element) => ({
//                 //     isDraggable: element.querySelector('img')?.getAttribute('is-draggable') || true,
//                 // }),
//                 renderHTML: (attributes) => ({
//                     'is-draggable': attributes.isDraggable,
//                 }),
//             },
//             alt: {
//                 default: '',
//                 // parseHTML: (element) => ({
//                 //     alt: element.querySelector('img')?.getAttribute('alt') || '',
//                 // }),
//                 renderHTML: (attributes) => ({
//                     alt: attributes.alt,
//                 }),
//             },
//             title: {
//                 default: '',
//                 // parseHTML: (element) => ({
//                 //     title: element.querySelector('img')?.getAttribute('title') || '',
//                 // }),
//                 renderHTML: (attributes) => ({
//                     title: attributes.title,
//                 }),
//             },
//             imageSrcData: {
//                 default: '',
//                 // parseHTML: (element) => ({
//                 //     imageSrcData: element.getAttribute('image-src-data') || '',
//                 // }),
//                 renderHTML: (attributes) => ({
//                     'image-src-data': attributes.imageSrcData,
//                 }),
//             },
//         };
//     },

//     addCommands() {
//         return {
//             // Inherit all the commands of the Image extension.
//             // This way we can add images as always:
//             // this.editor.chain().focus()
//             //      .setImage({
//             //          src: 'https://source.unsplash.com/8xznAGy4HcY/800x400',
//             //          width: '80',
//             //          height: '40'
//             //      })
//             //      .run();
//             ...this.parent?.(),

//             // New command that is going to be called like:
//             // this.editor.chain().focus().toggleResizable().run();
//             toggleResizable:
//                 () =>
//                     ({ tr }) => {
//                         const { node } = tr?.selection;

//                         if (node?.type?.name === 'ResizableImage') {
//                             node.attrs.isDraggable = !node.attrs.isDraggable;
//                         }
//                     }
//         };
//     },

//     // addNodeView() {
//     //     return VueNodeViewRenderer(ResizableImageTemplate);
//     // }
// });