<template>
  <div id="teacherschedule">
    <v-card max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4">
          {{ $i18n.t("headings.schedule") }}
          <helper topic="schedule" />
        </v-card-title>
        <v-spacer />
        <v-btn
          color="primary"
          class="mx-4"
          @click="createItem"
          :disabled="offline"
        >
          {{ $i18n.t("otherText.new") + " " + $i18n.t("headings.day") }}
        </v-btn>
      </v-row>
      <div class="px-4">
        <v-data-table
          :headers="headers"
          :items="schedule"
          :sort-desc="sortDesc"
          :sort-by="sortBy"
          @update:options="updateSortOptions"
          :loading="
            !teacherScheduleSubscriptionSynced && schedule?.length === 0
          "
          loading-text="Loading Schedules..."
          no-data-text="No Schedules found"
        >
          <!-- <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            
          </v-toolbar>
        </template> -->
          <template v-slot:[`item.startTime`]="{ item }">
            {{ item.startTime ? formatTimeAMPM(item.startTime) : "" }}
          </template>
          <template v-slot:[`item.endTime`]="{ item }">
            {{ item.endTime ? formatTimeAMPM(item.endTime) : "" }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              :disabled="offline"
            >
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" :disabled="offline">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
      <!-- <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="syncTeacherProfile"> Cancel </v-btn>
      <v-btn color="primary" @click="saveTeacherProfile" :disabled="!valid">
        Save
      </v-btn>
    </v-card-actions> -->
    </v-card>
    <v-dialog persistent v-model="dialog" max-width="500px">
      <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="resetForm">
                  New Day
                </v-btn>
              </template> -->
      <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent="save">
          <v-card-title>
            <span class="text-h5"
              >{{ formTitle }}
              <helper topic="scheduleedit" />
            </span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="editedItem.day"
                    :items="days"
                    :label="`${$i18n.t('headings.day')}*`"
                    :rules="formRules.day"
                    required
                  ></v-select>
                  <!-- <v-text-field
                          v-model="editedItem.day"
                          label="Day"
                        ></v-text-field> -->
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.periods"
                    :label="`${$i18n.t('labels.periods')}`"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.periodDuration"
                    :label="`${$i18n.t('labels.periodDuration')}`"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-combobox
                    v-model="editedItem.breakDurations"
                    type="number"
                    class="hide-items-dropdown"
                    :label="`${$i18n.t('labels.breaks')}`"
                    multiple
                    small-chips
                    deletable-chips
                    :placeholder="`${$i18n.t('labels.enterNumPressEnter')}`"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <!-- <v-text-field v-model="editedItem.startTime" label="Start Time"></v-text-field> -->
                  <v-dialog
                    ref="dialogStartTime"
                    v-model="timePickerStartModal"
                    :return-value.sync="editedItem.startTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.startTime"
                        :label="`${$i18n.t('labels.startTime')}`"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="formRules.time"
                        required
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="timePickerStartModal"
                      v-model="editedItem.startTime"
                      full-width
                      format="24hr"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="timePickerStartModal = false"
                      >
                        {{ $i18n.t("actions.cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.dialogStartTime.save(editedItem.startTime)
                        "
                      >
                        {{ $i18n.t("actions.ok") }}
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <!-- <v-text-field v-model="editedItem.endTime" label="End Time"></v-text-field> -->
                  <v-dialog
                    ref="dialogEndTime"
                    v-model="timePickerEndModal"
                    :return-value.sync="editedItem.endTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.endTime"
                        :label="`${$i18n.t('labels.endTime')}`"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="formRules.day"
                        required
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="timePickerEndModal"
                      v-model="editedItem.endTime"
                      full-width
                      format="24hr"
                      :min="editedItem.startTime"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="timePickerEndModal = false"
                      >
                        {{ $i18n.t("actions.cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogEndTime.save(editedItem.endTime)"
                      >
                        {{ $i18n.t("actions.ok") }}
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="close">
              {{ $i18n.t("actions.cancel") }}
            </v-btn>
            <v-btn color="primary" @click="save" :disabled="!valid">
              {{ $i18n.t("actions.save") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center">{{
          $i18n.t("otherText.deleteScheduleDay")
        }}</v-card-title>
        <v-card-actions>
          <v-btn color="primary" @click="closeDelete">{{
            $i18n.t("actions.cancel")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="deleteItemConfirm">{{
            $i18n.t("actions.yes")
          }}</v-btn>
          <!-- <v-spacer></v-spacer> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore } from "aws-amplify";
import { TeacherSchedule } from "@/models";
import _ from "lodash";
import Helper from "@/components/global-components/Helper.vue";

export default {
  name: "TeacherScheduler",
  components: { Helper },
  data: () => ({
    initiallyLoaded: false,
    teacherScheduleSubscription: null,
    teacherScheduleSubscriptionSynced: false,
    syncedDataModelArray: [],
    timePickerStartModal: false,
    timePickerEndModal: false,
    dialog: false,
    dialogDelete: false,
    valid: false,
    sortBy: "day",
    sortDesc: true,
    headers: [],
    schedule: [],
    editedIndex: -1,
    editedItem: {
      day: "",
      periods: null,
      periodDuration: null,
      breakDurations: [],
      startTime: null,
      endTime: null,
    },
    defaultItem: {
      day: "",
      periods: null,
      periodDuration: null,
      breakDurations: [],
      startTime: null,
      endTime: null,
    },
    days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    formRules: {},
  }),

  async mounted() {
    try {
      this.headers = [
        {
          text: this.$i18n.t("headings.day"),
          sortable: false,
          value: "day",
          sort: (a, b) => this.customSortWeekDay(a, b),
        },
        {
          text: this.$i18n.t("labels.periods"),
          value: "periods",
          sortable: false,
        },
        {
          text: this.$i18n.t("labels.periodDuration"),
          value: "periodDuration",
          sortable: false,
        },
        {
          text: this.$i18n.t("labels.breaks"),
          value: "breakDurations",
          sortable: false,
        },
        {
          text: this.$i18n.t("labels.startTime"),
          value: "startTime",
          sortable: false,
        },
        {
          text: this.$i18n.t("labels.endTime"),
          value: "endTime",
          sortable: false,
        },
        {
          text: this.$i18n.t("labels.actions"),
          value: "actions",
          sortable: false,
        },
      ];

      this.formRules = {
        day: [
          (v) => !!v || "A day is required",
          (v) =>
            !this.schedule.some(
              (scheduleItem) =>
                scheduleItem.day === v && scheduleItem.id !== this.editedItem.id
            ) || v + " already exists",
        ],
        time: [(v) => !!v || "Time is required"],
      };

      await this.syncTeacherSchedule();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
      teacherProfileID: (state) => state.teacherProfileID,
    }),

    formTitle() {
      return this.editedIndex === -1
        ? this.$i18n.t("otherText.new") + " " + this.$i18n.t("headings.day")
        : this.$i18n.t("actions.edit") + " " + this.$i18n.t("headings.day");
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
      this.valid = false;
    },

    updateSortOptions(options) {
      // Default sorting for other columns
      this.sortBy = options.sortBy;
      this.sortDesc = options.sortDesc;
    },

    customSortWeekDay(a, b) {
      const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

      // Convert weekdays to their corresponding numerical values
      a = weekdays.indexOf(a);
      b = weekdays.indexOf(b);

      // if (this.sortDesc) {
      //   // Switch the order for descending
      //   [a, b] = [b, a];
      // }
      return b - a;
    },

    createItem() {
      this.resetForm();
      this.dialog = true;
    },

    editItem(item) {
      this.editedIndex = this.schedule.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.schedule.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.schedule.splice(this.editedIndex, 1);
      await DataStore.delete(
        _.find(this.syncedDataModelArray, { id: this.editedItem.id })
      );
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        // Update schedule
        await this.updateTeacherSchedule();
      } else {
        // Create new schedule
        await this.createTeacherSchedule();
      }
      this.close();
    },

    async syncTeacherSchedule() {
      try {
        this.teacherScheduleSubscription = DataStore.observeQuery(
          TeacherSchedule
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && items !== this.syncedDataModelArray) {
              this.syncedDataModelArray = items;

              this.schedule = items.map((newObj) => {
                // const oldObj = oldVal.find(o => o.id === newObj.id) || {};

                const newObjCopy = _.cloneDeep(newObj);
                newObjCopy.startTime = this.formatTime(newObj.startTime);
                newObjCopy.endTime = this.formatTime(newObj.endTime);

                return newObjCopy;
              });

              this.teacherScheduleSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.teacherScheduleSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async createTeacherSchedule() {
      try {
        if (this.teacherProfileID !== "") {
          this.editedItem.breakDurations =
            this.editedItem.breakDurations.map(Number);

          if (
            !this.editedItem.periods ||
            isNaN(parseInt(this.editedItem.periods))
          ) {
            delete this.editedItem.periods;
          } else {
            this.editedItem.periods = parseInt(this.editedItem.periods);
          }
          if (
            !this.editedItem.periodDuration ||
            isNaN(parseInt(this.editedItem.periodDuration))
          ) {
            delete this.editedItem.periodDuration;
          } else {
            this.editedItem.periodDuration = parseInt(
              this.editedItem.periodDuration
            );
          }

          this.editedItem.endTime = this.convertToAwsTimeFormat(
            this.editedItem.endTime
          );
          this.editedItem.startTime = this.convertToAwsTimeFormat(
            this.editedItem.startTime
          );

          await DataStore.save(
            new TeacherSchedule({
              day: this.editedItem.day,
              periods: this.editedItem.periods,
              periodDuration: this.editedItem.periodDuration,
              breakDurations: this.editedItem.breakDurations,
              startTime: this.editedItem.startTime,
              endTime: this.editedItem.endTime,
              teacherprofileID: this.teacherProfileID,
            })
          );
        }
        // else {
        //   alert("Please create your Profile before creating a schedule.");
        // }
      } catch (error) {
        console.log(error);
      }
    },

    async updateTeacherSchedule() {
      try {
        this.editedItem.breakDurations =
          this.editedItem.breakDurations.map(Number);

        if (
          !this.editedItem.periods ||
          isNaN(parseInt(this.editedItem.periods))
        ) {
          delete this.editedItem.periods;
        } else {
          this.editedItem.periods = parseInt(this.editedItem.periods);
        }
        if (
          !this.editedItem.periodDuration ||
          isNaN(parseInt(this.editedItem.periodDuration))
        ) {
          delete this.editedItem.periodDuration;
        } else {
          this.editedItem.periodDuration = parseInt(
            this.editedItem.periodDuration
          );
        }

        this.editedItem.endTime = this.convertToAwsTimeFormat(
          this.editedItem.endTime
        );
        this.editedItem.startTime = this.convertToAwsTimeFormat(
          this.editedItem.startTime
        );

        await DataStore.save(
          TeacherSchedule.copyOf(
            _.find(this.syncedDataModelArray, { id: this.editedItem.id }),
            (updateModel) => {
              (updateModel.day = this.editedItem.day),
                (updateModel.periods = this.editedItem.periods),
                (updateModel.periodDuration = this.editedItem.periodDuration),
                (updateModel.breakDurations = this.editedItem.breakDurations),
                (updateModel.startTime = this.editedItem.startTime),
                (updateModel.endTime = this.editedItem.endTime);
              // updateModel.teacherprofileID = this.teacherProfileID
            }
          )
        );
      } catch (error) {
        console.log(error);
      }
    },

    formatTime(time) {
      if (time !== "00:00:00.000") {
        let [hours, minutes] = time.split(":");
        const formattedTime = hours + ":" + minutes;
        return formattedTime;
      }
      return ""; // Return an empty string or whatever you want when time is null
    },

    formatTimeAMPM(time) {
      if (time !== "00:00:00.000") {
        let [hours, minutes] = time.split(":");
        hours = parseInt(hours, 10);
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const formattedTime = hours + ":" + minutes + " " + ampm;
        return formattedTime;
      }
      return ""; // Return an empty string or whatever you want when time is null
    },

    convertToAwsTimeFormat(timeString) {
      if (timeString) {
        let [hours, minutes] = timeString.split(":");
        return `${hours}:${minutes}:00.000`;
      } else {
        return `00:00:00.000`;
      }
    },
  },

  beforeDestroy() {
    if (this.teacherScheduleSubscription) {
      this.teacherScheduleSubscription.unsubscribe();
    }
  },
};
</script>
<style>
.hide-items-dropdown .v-input__append-inner {
  display: none !important;
}

.hide-items-dropdown .v-select__selections {
  min-height: 32px !important;
}
</style>
