<template>
    <div id="circularloader">
        <v-overlay :value="overlay">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>

export default {
    name: "CircularLoader",
    data: () => ({
        overlay: true
    })
};
</script>