<template>
  <div id="appproducts">
    <div class="pa-2">
      <v-row justify="space-between">
        <v-col cols="12" md="12">
          <TeacherProducts
            @openCloseCartDialog="openCloseCartDialog"
          ></TeacherProducts>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import TeacherProducts from "@/components/app-components/teacher/TeacherProducts.vue";

export default {
  name: "AppProductsView",
  components: { TeacherProducts },
  data: () => ({}),
  methods: {
    openCloseCartDialog() {
      this.$emit("openCloseCartDialog");
    },
  },
};
</script>
