<template>
  <div id="admincustomers">
    <div class="pa-2">
      <v-row justify="space-between">
        <v-col>
          <Customers />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import Customers from "@/components/admin-components/global/Customers.vue";
export default {
  name: "AdminCustomers",
  components: { Customers },
  data: () => ({}),
};
</script>
