import Vue from 'vue'
import App from './App.vue'

import VTiptap from "@peepi/vuetify-tiptap";

import '@aws-amplify/ui-vue';
import { applyPolyfills, defineCustomElements } from '@aws-amplify/ui-components/loader';

import { Amplify, AuthModeStrategyType, DataStore } from 'aws-amplify';
import awsconfig from './aws-exports';
import { ConsoleLogger } from '@aws-amplify/core';

import router from './router'
import store from './store'
import './registerServiceWorker'

import {vuetify, i18n} from './plugins/vuetify/vuetify.js'
import '@/sass/main.scss';

import { VueReCaptcha } from 'vue-recaptcha-v3'

ConsoleLogger.LOG_LEVEL = 'ERROR'; // 'OFF'/'ERROR'/'WARN'/'INFO'

// await DataStore.stop();

Amplify.configure({
  ...awsconfig,
  // Auth: {
  //   region: 'eu-west-2',
  //   aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  //   authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
  // },
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
    region: 'eu-west-2',
  },
  Storage: {
    AWSS3: {
      bucket: 'missanriscn-storage-c4dd76e0', //REQUIRED -  Amazon S3 bucket name
      region: 'eu-west-2', //OPTIONAL -  Amazon service region
    }
  }
})

Vue.use(VTiptap);


Vue.use(VueReCaptcha, {
  siteKey: '6LdMEJMmAAAAAAR969iuiDARS96O0GOtzOqM7QRV',
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true
  }
})

Vue.config.productionTip = false

Vue.config.ignoredElements = [/amplify-\w*/];

applyPolyfills().then(() => {
  defineCustomElements(window);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')