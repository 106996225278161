<template>
  <!-- Root div with an ID 'applayout' -->
  <div id="applayout">
    <!-- Application bar component -->
    <v-app-bar app flat id="applayout-app-bar">
      <!-- Burger Icon -->
      <v-app-bar-nav-icon
        @click="leftDrawer = !leftDrawer"
      ></v-app-bar-nav-icon>
      <!-- Spacer to push menu buttons right -->
      <v-spacer />
      <!-- Menu icon buttons -->
      <div v-if="screenWidth > 259" class="d-flex flex-row" style="gap: 12px">
        <!-- <v-btn icon to="/"><v-icon>mdi-home</v-icon></v-btn> -->
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="changeVuetifyLang()">
              <v-icon>mdi-translate</v-icon>
            </v-btn>
          </template>
          <span>Change language</span>
        </v-tooltip> -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="$store.dispatch('signOut')"
              color="secondary"
              class="black--text"
            >
              <v-icon>mdi-logout-variant</v-icon>
            </v-btn>
          </template>
          <span>{{ $i18n.t("headings.signOut") }}</span>
        </v-tooltip>
      </div>
      <!-- Router link to the root path with the logo image -->
      <router-link to="/">
        <v-img
          class="ml-3"
          src="@/assets/logo.png"
          max-height="50"
          max-width="93"
          contain
        ></v-img>
      </router-link>
    </v-app-bar>

    <!-- Main content area -->
    <v-main fluid fill-height id="applayout-v-main">
      <!-- <TACUpdateNotice /> -->
      <div v-if="notReadAnnouncements">
        <AnnouncementMessage :announcement="notReadAnnouncements[0]" />
      </div>
      <!-- Router view to render the component matched by the route -->
      <router-view @openCloseCartDialog="openCloseCartDialog"></router-view>
    </v-main>

    <!-- Left navigation drawer -->
    <v-navigation-drawer
      v-model="leftDrawer"
      left
      app
      id="applayout-nav-drawer"
    >
      <!-- Top section with a full logo -->
      <div class="d-flex flex-row pa-2">
        <v-img class="mr-3" src="@/assets/logo-full.svg" contain></v-img>
      </div>
      <!-- Dividers and contents inside the drawer -->
      <v-responsive class="mx-auto" width="56">
        <v-divider></v-divider>
        <v-divider></v-divider>
      </v-responsive>
      <!-- Navigation list -->
      <v-list nav dense>
        <v-list-item-group active-class="secondary">
          <!-- <v-list-item to="/app/products">
            <v-list-item-icon>
              <v-icon>mdi-bookshelf</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Resource Library</v-list-item-title
            >
          </v-list-item> -->

          <v-list-group
            v-if="userGroups?.includes('teacher')"
            class="mb-1"
            prepend-icon="mdi-bookshelf"
            no-action
            color="rgba(0, 0, 0, 0.87)"
          >
            <template v-slot:activator>
              <!-- <v-list-item-title class="v-btn" style="justify-content: start"
                >Products</v-list-item-title
              > -->
              <div class="d-flex" style="width: 100%">
                <v-btn to="/app/products" class="px-0" plain>
                  <v-list-item-title
                    class="v-btn"
                    style="justify-content: start"
                    >{{
                      $i18n.t("headings.resource") +
                      " " +
                      $i18n.t("headings.library")
                    }}</v-list-item-title
                  >
                </v-btn>
              </div>
            </template>
            <v-list-item to="/app/my-library">
              <v-list-item-icon>
                <v-icon>mdi-book-check</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="v-btn" style="justify-content: start">{{
                $i18n.t("otherText.my") + " " + $i18n.t("headings.library")
              }}</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-item v-else to="/app/products">
            <v-list-item-icon>
              <v-icon>mdi-bookshelf</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start">{{
              $i18n.t("headings.resource") + " " + $i18n.t("headings.library")
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item to="/app/profile">
            <v-list-item-icon>
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start">{{
              $i18n.t("headings.profile")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/app/calendar">
            <v-list-item-icon>
              <v-icon>mdi-calendar-multiselect-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start">{{
              $i18n.t("headings.calendar")
            }}</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item to="/app/lessonplanning">
            <v-list-item-icon>
              <v-icon>mdi-book-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start">Lesson Planning</v-list-item-title>
          </v-list-item> -->

          <v-list-group
            prepend-icon="mdi-file-outline"
            no-action
            color="rgba(0, 0, 0, 0.87)"
          >
            <template v-slot:activator>
              <v-list-item-title class="v-btn" style="justify-content: start">{{
                $i18n.t("otherText.my") + " " + $i18n.t("headings.classroom")
              }}</v-list-item-title>
            </template>
            <v-list-item to="/app/lessons">
              <v-list-item-icon>
                <v-icon>mdi-file-document-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="v-btn" style="justify-content: start">{{
                $i18n.t("otherText.my") + " " + $i18n.t("headings.lessons")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/app/assessments">
              <v-list-item-icon>
                <v-icon>mdi-file-document-edit-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="v-btn" style="justify-content: start">{{
                $i18n.t("otherText.my") + " " + $i18n.t("headings.assessments")
              }}</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item to="/admin/lessontemplates">
              <v-list-item-title class="v-btn" style="justify-content: start">Lessons</v-list-item-title>
            </v-list-item> -->
          </v-list-group>

          <!-- <v-list-item to="/app/lessons">
            <v-list-item-icon>
              <v-icon>mdi-file-document-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >My Lessons</v-list-item-title
            >
          </v-list-item>
          <v-list-item to="/app/assessments">
            <v-list-item-icon>
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >My Assessments</v-list-item-title
            >
          </v-list-item> -->
          <v-list-item to="/app/support">
            <v-list-item-icon>
              <v-icon>mdi-account-question-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start">{{
              $i18n.t("headings.support")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$store.dispatch('signOut')">
            <v-list-item-icon>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start">{{
              $i18n.t("headings.signOut")
            }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <Footer :showLegal="false" id="applayout-footer" />
  </div>
</template>

<script>
import Vue from "vue";
import Footer from "@/components/global-components/Footer.vue";
import { mapState } from "vuex";
import AnnouncementMessage from "@/components/app-components/global/AnnouncementMessage.vue";
// import TACUpdateNotice from "@/components/app-components/global/TACUpdateNotice.vue";

export default Vue.extend({
  name: "AppLayout",
  data: () => ({
    leftDrawer: false, // State of the left navigation drawer
    // leftDrawerListGroup: null, // Currently selected list item group in left drawer
  }),
  components: {
    Footer,
    AnnouncementMessage,
    // TACUpdateNotice,
  },

  computed: {
    ...mapState({
      userGroups: (state) => state.userGroups,
      notReadAnnouncements: (state) => state.notReadAnnouncements,
    }),
    // Computed property for the screen width
    screenWidth() {
      let width = this.$vuetify.breakpoint.width;
      return width;
    },
  },

  methods: {
    changeVuetifyLang() {
      console.log("Changing language:", this.$vuetify.lang.current);
      const newLang = this.$vuetify.lang.current === "en" ? "af" : "en";
      this.$vuetify.lang.current = newLang;
      this.$i18n.locale = newLang; // Add this line
      console.log("Changed language:", this.$vuetify.lang.current);
    },
  },
});
</script>

<style>
/* .productsvlist .v-list-item__icon {
  margin-right: 16px !important;
} */
</style>
