import Vue from 'vue'
import Vuex from 'vuex'
import {
  Auth,
  DataStore
} from 'aws-amplify'
// import router from "@/router";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isOnline: true,
    appLoading: false,
    userSignedIn: false,
    userGroups: null,
    userEmail: '',
    syncedTeacherProfileModel: null,
    teacherProfileID: '',
    teacherProfileProvince: '',
    teacherProfileSchoolID: '',
    teacherProfilePayfastSubscriptionToken: '',
    teacherProfilePayfastSubscriptionResponse: null,
    teacherProfileAppSyncVersion: '',
    notReadAnnouncements: null,
    // teacherProfilePayfastSubscriptionPackage: null,
    // teacherProfileProductSubcriptions: 50,
  },
  getters: {
  },
  mutations: {
    storeNotReadAnnouncements(state, payload) {
      state.notReadAnnouncements = payload
    },
    storeIsOnline(state, payload) {
      if (state.isOnline !== payload) state.isOnline = payload
    },
    storeUserGroups(state, payload) {
      state.userGroups = payload
    },
    storeAppLoading(state, payload) {
      state.appLoading = payload
    },
    storeUserSignedIn(state, payload) {
      state.userSignedIn = payload
    },
    storeUserEmail(state, payload) {
      // console.log('storeUserEmail', payload)
      state.userEmail = payload
    },
    storeTeacherProfileModel(state, payload) {
      // console.log('storeTeacherProfileModel', payload)
      state.syncedTeacherProfileModel = payload
    },
    storeTeacherProfileID(state, payload) {
      // console.log('storeTeacherProfileID', payload)
      state.teacherProfileID = payload
    },
    storeTeacherProfilePayfastSubscriptionToken(state, payload) {
      // console.log('storeTeacherProfilePayfastSubscriptionToken', payload)
      state.teacherProfilePayfastSubscriptionToken = payload
    },
    storeTeacherProfilePayfastSubscriptionResponse(state, payload) {
      // console.log('storeTeacherProfilePayfastSubscriptionResponse', payload)
      state.teacherProfilePayfastSubscriptionResponse = payload
    },
    storeTeacherProfileAppSyncVersion(state, payload) {
      // console.log('storeTeacherProfileAppSyncVersion', payload)
      state.teacherProfileAppSyncVersion = payload
    },
    storeTeacherProfileProvince(state, payload) {
      // console.log('storeTeacherProfileProvince', payload)
      state.teacherProfileProvince = payload
    },
    storeTeacherProfileSchoolID(state, payload) {
      // console.log('storeTeacherProfileSchoolID', payload)
      state.teacherProfileSchoolID = payload
    },
    // storeTeacherProfilePayfastSubscriptionPackage(state, payload) {
    //   // console.log('storeTeacherProfilePayfastSubscriptionPackage', payload)
    //   state.teacherProfilePayfastSubscriptionPackage = payload
    // },
    // storeTeacherProfileProductSubcriptions(state, payload) {
    //   // console.log('storeTeacherProfileProductSubcriptions', payload)
    //   state.teacherProfileProductSubcriptions = payload
    // }
  },
  actions: {
    async signOut() {
      await Auth.signOut()
      await DataStore.clear()
      
      this.commit('storeUserSignedIn', false)
      this.commit('storeUserGroups', null)
      this.commit('storeUserEmail', '')
      this.commit('storeTeacherProfileID', '')
      this.commit('storeTeacherProfileProvince', '')
      this.commit('storeTeacherProfileSchoolID', '')
      this.commit('storeTeacherProfilePayfastSubscriptionToken', '')
      this.commit('storeTeacherProfilePayfastSubscriptionResponse', '')
      this.commit('storeTeacherProfileAppSyncVersion', '')
      this.commit('storeNotReadAnnouncements', null)
      
      // if (router.currentRoute.path !== '/') {
      //   router.push({ path: '/' })
      // }
      
      window.location.href = '/'
    },
  },
  modules: {
  }
})
