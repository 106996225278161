<template>
  <div id="teacherplanner">
    <v-card max-width="100%" outlined>
      <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
        <v-row no-gutters class="align-center">
          <v-card-title class="text-h4"> Planner </v-card-title>
          <v-spacer />
          <!-- <v-btn color="primary" class="mx-4" @click="createItem" :disabled="offline">
          {{listOrSelect === 'list' ? ''}}
        </v-btn> -->
        </v-row>
        <v-card-text>
          <v-row>
            <!-- <v-col cols="12" sm="3">
              <v-select v-model="selectedTemplateType" :items="templateTypes" label="Type"
                :disabled="offline || syncedSubjectTemplateArray.length < 1"
                @change="teacherSubjectTemplate = null"></v-select>
            </v-col> -->
            <v-col cols="12" sm="4">
              <v-select
                v-model="selectedGrade"
                :items="grades"
                label="Grade"
                :disabled="offline || syncedSubjectTemplateArray.length < 1"
                @change="teacherSubjectTemplate = null"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="selectedSubject"
                :items="subjects"
                item-text="subject"
                item-value="id"
                label="Subject"
                :disabled="offline || syncedSubjectTemplateArray.length < 1"
                @change="teacherSubjectTemplate = null"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="teacherSubjectTemplate"
                :items="subjectTemplates"
                :label="subjectTemplatesSelectLabel"
                :disabled="offline || syncedSubjectTemplateArray.length < 1"
                :item-text="subjectTemplatesItemText"
                no-data-text="No Results found"
                return-object
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              v-if="selectedGrade && selectedSubject && teacherSubjectTemplate"
            >
              <v-text-field
                v-model="teacherSubjectTemplate.name"
                label="Your Template Name"
                hint="The name of your new template"
                required
                :rules="formRules.name"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-stepper v-model="activeStep" flat v-if="teacherSubjectTemplate">
          <v-stepper-header>
            <template
              v-for="(templateStep, index) in teacherSubjectTemplate.template"
            >
              <v-stepper-step
                :complete="activeStep > index + 1"
                :key="'step-' + index"
                :step="index + 1"
              >
                {{ templateStep.name }}
              </v-stepper-step>
              <v-divider
                v-if="index < teacherSubjectTemplate.template.length - 1"
                :key="'divider-' + index"
              ></v-divider>
            </template>
          </v-stepper-header>
          <v-progress-linear
            :value="progress"
            height="10"
            color="primary"
          ></v-progress-linear>

          <v-stepper-items>
            <template
              v-for="(templateStep, index) in teacherSubjectTemplate.template"
            >
              <v-stepper-content :key="'content-' + index" :step="index + 1">
                <v-form :ref="'form' + index">
                  <template v-for="(field, fieldIndex) in templateStep.fields">
                    <v-text-field
                      v-if="field.type === 'Text'"
                      :key="'text-' + index + '-' + fieldIndex"
                      v-model="field.value"
                      :label="field.label"
                      :hint="field.hint"
                      :placeholder="field.placeholder"
                      @dblclick="copyPlaceholderToValue(field)"
                    ></v-text-field>
                    <v-textarea
                      v-if="field.type === 'Textarea'"
                      :key="'textarea-' + index + '-' + fieldIndex"
                      v-model="field.value"
                      :label="field.label"
                      :hint="field.hint"
                      :placeholder="field.placeholder"
                      @dblclick="copyPlaceholderToValue(field)"
                      auto-grow
                    ></v-textarea>
                    <v-select
                      v-if="field.type === 'Select'"
                      :key="'select-' + index + '-' + fieldIndex"
                      v-model="field.value"
                      :label="field.label"
                      :items="field.items"
                      :hint="field.hint"
                      :placeholder="field.placeholder"
                    ></v-select>
                    <v-radio-group
                      v-if="field.type === 'Radio'"
                      :key="'radio-' + index + '-' + fieldIndex"
                      v-model="field.value"
                      :label="field.label"
                      :hint="field.hint"
                    >
                      <v-radio
                        v-for="(item, itemIndex) in field.items"
                        :key="'item-' + itemIndex"
                        :label="item"
                        :value="item"
                      ></v-radio>
                    </v-radio-group>
                    <template v-if="field.type === 'Checkbox'">
                      <v-checkbox
                        v-for="(item, itemIndex) in field.items"
                        :key="
                          'checkbox-' +
                          index +
                          '-' +
                          fieldIndex +
                          '-' +
                          itemIndex
                        "
                        v-model="field.value"
                        :label="item"
                        :value="item"
                      ></v-checkbox>
                    </template>
                  </template>
                  <v-btn
                    class="mr-2 black--text"
                    color="secondary"
                    @click="previousStep"
                    v-if="activeStep > 1"
                    >Back</v-btn
                  >
                  <v-btn
                    color="primary"
                    @click="nextStep"
                    v-if="activeStep < teacherSubjectTemplate.template.length"
                    >Next</v-btn
                  >
                  <v-btn
                    color="primary"
                    @click="submitForm"
                    v-if="activeStep === teacherSubjectTemplate.template.length"
                    :disabled="progress < 100 || !valid || offline"
                    >Save</v-btn
                  >
                </v-form>
              </v-stepper-content>
            </template>
          </v-stepper-items>
        </v-stepper>
        <v-card-text>
          <v-btn v-if="teacherSubjectTemplate" @click="openPrintDialog"
            >Preview Template</v-btn
          >
          <TemplatePrinterDialog
            ref="printableDialog"
            :template="teacherSubjectTemplate"
            :open-dialog="openPrintDialog"
          />
        </v-card-text>
      </v-form>
    </v-card>
    <v-dialog persistent v-model="dialogFormSubmit" max-width="500px">
      <v-card>
        <v-card-title class="justify-center">{{
          dialogFormSubmitTitle
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" to="/app/subjecttemplates"
            >Go to my new Template</v-btn
          >
          <v-btn color="primary" @click="closeDialogFormSubmit">Close</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore } from "aws-amplify";
import {
  SubjectTemplate,
  School,
  TeacherSubjectTemplate,
  Subject,
  TeacherClassSubject,
} from "@/models";
import _ from "lodash";

import TemplatePrinterDialog from "@/components/global-components/TemplatePrinterDialog.vue";

export default {
  name: "TeacherPlanner",
  data: () => ({
    initiallyLoaded: false,
    dialogFormSubmit: false,
    subjectTemplateSubscription: null,
    syncedSubjectTemplateArray: [],
    teacherClassSubjectSubscription: null,
    syncedTeacherClassSubjectIDArray: [],
    syncedTeacherClassSubjectArray: [],
    subjectSubscription: null,
    syncedSubjectArray: [],
    selectedGrade: null,
    selectedSubject: null,
    templateTypes: ["Lesson"],
    activeStep: 1,
    teacherSubjectTemplate: null,
    valid: false,
    formRules: {
      name: [(v) => !!v || "Name is required"],
    },
  }),

  components: {
    TemplatePrinterDialog,
  },

  async mounted() {
    try {
      // Sync the TeacherClassSubject from the datastore
      await this.syncTeacherClassSubject();
    } catch (error) {
      console.log(error);
    }
  },

  watch: {
    teacherProfileSchoolID: {
      handler: async function (val) {
        if (!this.subjectTemplateSubscription && val) {
          await this.syncSubjectTemplates(val);
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapState({
      teacherProfileSchoolID: (state) => state.teacherProfileSchoolID,
      teacherProfileProvince: (state) => state.teacherProfileProvince,
      offline: (state) => !state.isOnline,
    }),

    dialogFormSubmitTitle() {
      // console.log(this.teacherSubjectTemplate)
      if (this.teacherSubjectTemplate && this.teacherSubjectTemplate.type) {
        return (
          "You successfully created a new " +
          this.teacherSubjectTemplate.type.toLowerCase() +
          " Template!"
        );
      } else return "You successfully created a new Template!";
    },

    grades() {
      return this.syncedSubjectArray.map((s) => s.grade);
    },

    subjects() {
      return this.syncedSubjectArray.filter(
        (s) => s.grade === this.selectedGrade
      );
    },

    subjectTemplates() {
      return _.cloneDeep(
        this.syncedSubjectTemplateArray.filter(
          (st) => st.subjectID === this.selectedSubject
        )
      );
    },

    subjectTemplatesSelectLabel() {
      if (this.subjectTemplates.length === 0) {
        return "No results found";
      } else {
        return `Select from ${this.subjectTemplates.length} lesson${
          this.subjectTemplates.length > 1 ? "'s" : ""
        }`;
      }
    },

    subjectTemplatesItemText() {
      return (item) =>
        item.type === "Lesson"
          ? item.minutes + " minutes"
          : item.minutes + " minutes - " + item.totalPoints + " poits";
    },

    progress() {
      let completedFields = 0;
      let totalFields = 0;

      for (let step of this.teacherSubjectTemplate.template) {
        for (let field of step.fields) {
          totalFields++;
          if (Array.isArray(field.value)) {
            if (field.value.length > 0) {
              completedFields++;
            }
          } else if (field.value) {
            completedFields++;
          }
        }
      }
      // Return the percentage of completed fields.
      return (completedFields / totalFields) * 100;
    },
  },

  methods: {
    openPrintDialog() {
      // Open the print dialog in the PrintableDialog component
      this.$refs.printableDialog.openPrintDialog();
    },

    async syncTeacherClassSubject() {
      try {
        this.teacherClassSubjectSubscription = DataStore.observeQuery(
          TeacherClassSubject
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              this.syncedTeacherClassSubjectArray !== items
            ) {
              // console.log("TeacherClassSubject synced", items);
              this.syncedTeacherClassSubjectArray = items;

              this.syncedTeacherClassSubjectIDArray = items.map(
                (ts) => ts.subjectID
              );

              // Sync the Subjects from the datastore
              await this.syncSubjects();
            } else if (this.offline) {
              if (!this.initiallyLoaded) {
                this.initiallyLoaded = true;
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSubjectTemplates(teacherProfileSchoolIDParam) {
      if (!teacherProfileSchoolIDParam) {
        return;
      }
      try {
        const schools = await DataStore.query(School, (s) =>
          s.and((s) => [
            s.isGeneric("eq", true),
            s.provinces("contains", this.teacherProfileProvince),
          ])
        );
        const schoolIdsArray = schools.map((school) => school.id);

        const predicate = (subjectTemplate) =>
          subjectTemplate.or((subjectTemplate) => [
            subjectTemplate.schoolID("eq", teacherProfileSchoolIDParam),
            subjectTemplate.schoolID("contains", schoolIdsArray),
          ]);

        this.subjectTemplateSubscription = DataStore.observeQuery(
          SubjectTemplate,
          predicate
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              this.syncedSubjectTemplateArray !== items
            ) {
              this.syncedSubjectTemplateArray = items;
            } else if (this.offline) {
              if (!this.initiallyLoaded) {
                this.initiallyLoaded = true;
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSubjects() {
      try {
        const predicate = (subject) =>
          subject.or((subject) =>
            this.syncedTeacherClassSubjectIDArray.reduce(
              (c, id) => c.id("eq", id),
              subject
            )
          );

        this.subjectSubscription = DataStore.observeQuery(
          Subject,
          predicate
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              this.syncedSubjectArray !== items
            ) {
              this.syncedSubjectArray = items;

              if (!this.initiallyLoaded) {
                this.initiallyLoaded = true;
              }
            } else if (this.offline) {
              if (!this.initiallyLoaded) {
                this.initiallyLoaded = true;
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    nextStep() {
      if (this.activeStep < this.teacherSubjectTemplate.template.length) {
        this.activeStep++;
      }
    },

    previousStep() {
      if (this.activeStep > 1) {
        this.activeStep--;
      }
    },

    async submitForm() {
      try {
        this.teacherSubjectTemplate.template.forEach((templateObj) => {
          templateObj.name = templateObj.name.trim();
        });

        await DataStore.save(
          new TeacherSubjectTemplate({
            name: this.teacherSubjectTemplate.name.trim(),
            type: "Lesson",
            complexity: this.teacherSubjectTemplate.complexity,
            totalPoints: this.teacherSubjectTemplate.totalPoints,
            minutes: this.teacherSubjectTemplate.minutes,
            template: this.teacherSubjectTemplate.template,
            schoolID: this.teacherSubjectTemplate.schoolID,
            subjectID: this.teacherSubjectTemplate.subjectID,
          })
        );
        this.dialogFormSubmit = true;
      } catch (error) {
        console.log(error);
      }
    },

    closeDialogFormSubmit() {
      // if (this.$refs.form) this.$refs.form.reset();
      this.selectedGrade = null;
      this.selectedSubject = null;
      this.teacherSubjectTemplate = null;
      this.activeStep = 1;
      this.valid = false;
      this.dialogFormSubmit = false;
    },

    copyPlaceholderToValue(field) {
      if (!field.value) {
        field.value = field.placeholder;
      }
    },
  },

  beforeDestroy() {
    if (this.subjectTemplateSubscription) {
      this.subjectTemplateSubscription.unsubscribe();
    }
    if (this.teacherClassSubjectSubscription) {
      this.teacherClassSubjectSubscription.unsubscribe();
    }
    if (this.subjectSubscription) {
      this.subjectSubscription.unsubscribe();
    }
  },
};
</script>
