<template>
  <v-card id="teacherprofileform" max-width="100%" outlined>
    <v-form ref="form" v-model="valid" @submit.prevent="saveTeacherProfile">
      <v-card-title class="text-h4">
        {{ $i18n.t("headings.profile") }}
        <helper topic="profile" />
        <!-- <v-spacer></v-spacer>
        
        <v-img :src="userData.userImg ? userData.userImg : require('@/assets/user-profile-img-blank.png')"
                max-height="100" max-width="100" style="border-radius: 9999px;" cover>
                <v-btn @click="updateUserImg" :disabled="!isOnline || isLoading || isImageLoading || !userData.userImg"
                  :loading="isLoading || isImageLoading"
                  style="padding: 0; width: -webkit-fill-available; background: none !important; height: -webkit-fill-available;">
                </v-btn>
              </v-img> -->
      </v-card-title>
      <v-card-text>
        <!-- Provide a light theme inside the form -->

        <v-text-field
          v-model="teacherProfileInputs.title"
          :label="`${$i18n.t('labels.title')}*`"
          :rules="formRules.title"
          required
        ></v-text-field>

        <v-text-field
          v-model="teacherProfileInputs.name"
          :label="`${$i18n.t('labels.name')}*`"
          :rules="formRules.name"
          required
        ></v-text-field>

        <v-text-field
          v-model="teacherProfileInputs.surname"
          :label="`${$i18n.t('labels.surname')}*`"
          :rules="formRules.surname"
          required
        ></v-text-field>

        <v-select
          v-model="teacherProfileInputs.language"
          :items="languages"
          :label="`${$i18n.t('labels.language')}*`"
        ></v-select>

        <v-select
          v-model="teacherProfileInputs.province"
          :items="provinces"
          :label="`${$i18n.t('labels.province')}*`"
        ></v-select>

        <v-autocomplete
          v-model="teacherProfileInputs.School"
          return-object
          :items="filteredSchoolArray"
          :item-text="schoolName"
          :label="`${$i18n.t('labels.school')}`"
        ></v-autocomplete>

        <v-slider
          v-model="teacherProfileInputs.daysPerWeek"
          :rules="formRules.daysPerWeek"
          required
          min="1"
          max="5"
          :label="`${$i18n.t('labels.schoolDaysPerWeek')}*`"
          class="mt-9"
          thumb-label="always"
        >
        </v-slider>
      </v-card-text>
      <v-card-actions class="px-4 pb-4 pt-0">
        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn color="primary"> Cancel </v-btn> -->
        <v-btn
          color="primary"
          type="submit"
          :loading="loadingTeacherProfile"
          :disabled="
            !initiallyLoaded || loadingTeacherProfile || !valid || offline
          "
        >
          {{ $i18n.t("actions.save") }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { DataStore } from "aws-amplify";
import { TeacherProfile, School } from "@/models";
import _ from "lodash";

import Helper from "@/components/global-components/Helper.vue";

export default {
  name: "TeacherProfileForm",
  components: { Helper },
  data: () => ({
    initiallyLoaded: false,
    // isImageLoading: false,
    loadingTeacherProfile: true,
    teacherProfileSubscription: null,
    syncedTeacherProfileArray: [],
    schoolSubscription: null,
    syncedSchoolArray: [],
    userHasProfile: false,
    valid: true, // Form validation status
    submitting: false, // Is form currently submitting
    submitted: false, // Has form been submitted
    // Form fields
    teacherProfileInputs: {
      title: "",
      name: "",
      surname: "",
      language: "",
      province: "",
      School: null,
      daysPerWeek: null,
      status: "",
    },
    formRules: {
      title: [(v) => !!v || "Title is required"],
      name: [(v) => !!v || "Name is required"],
      surname: [(v) => !!v || "Surname is required"],
      daysPerWeek: [(v) => !!v || "Days Per Week is required"],
    },
    languages: ["Afrikaans", "English", "Xhosa", "Zulu", "Sotho"],
    provinces: [
      "Eastern Cape",
      "Free State",
      "Gauteng",
      "KwaZulu-Natal",
      "Limpopo",
      "Mpumalanga",
      "Northern Cape",
      "North West",
      "Western Cape",
    ],
  }),

  async mounted() {
    try {
      await this.syncTeacherProfile();
      await this.syncSchools();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
      teacherProfileID: (state) => state.teacherProfileID,
    }),

    filteredSchoolArray() {
      return this.syncedSchoolArray.filter((school) =>
        school.provinces.some(
          (province) => province === this.teacherProfileInputs.province
        )
      );
    },
  },

  methods: {
    //     updateUserImg() {
    //   try {
    //     isLoading = true;
    //     deleteBase64StringsByRecipeIdOrEmail('user', userData.value.email);

    //     const inputElement = document.createElement('input');
    //     inputElement.type = 'file';
    //     inputElement.accept = 'image/*';
    //     inputElement.addEventListener('change', async (event) => {
    //       if (event.target instanceof HTMLInputElement && event.target.files && event.target.files.length > 0) {
    //         const file = event.target.files[0];
    //         const img = new Image();
    //         img.src = URL.createObjectURL(file);
    //         img.onload = async () => {
    //           const newWidth = 500; // Final image width
    //           const newHeight = 500; // Final image height
    //           const canvas = document.createElement('canvas');
    //           canvas.width = newWidth;
    //           canvas.height = newHeight;
    //           const ctx = canvas.getContext('2d');

    //           // Check if ctx is not null before using it
    //           if (!ctx) {
    //             console.error('Unable to get canvas rendering context');
    //             return;
    //           }

    //           // Calculate dimensions
    //           const dimension = Math.min(img.width, img.height);
    //           const x = (img.width - dimension) / 2;
    //           const y = (img.height - dimension) / 2;

    //           // Draw image on canvas
    //           ctx.drawImage(img, x, y, dimension, dimension, 0, 0, size, size);

    //           // Convert the canvas to a file
    //           canvas.toBlob(async (blob) => {
    //             // Check if blob is not null before using it
    //             if (!blob) {
    //               console.error('Unable to create blob from canvas');
    //               return;
    //             }

    //             const newFile = new File([blob], 'resized-' + file.name, { type: 'image/png' });

    //             // Upload the new file here

    //               isImageLoading.value = true;
    //               if (typeof userDataModel.userImgURL === 'string' && userDataModel.userImgURL !== '') {
    //                 await Storage.remove(userDataModel.userImgURL, { level: 'private' })
    //               }

    //               const newUserImgPut = await Storage.put(
    //                 "/UserImg-" + generateRandomImgString(newFile.name),
    //                 newFile,
    //                 { level: "private" }
    //               );
    //               await DataStore.save(UserData.copyOf(userDataModel, item => {
    //                 item.userImgURL = newUserImgPut.key;
    //               }));

    //               const reader = new FileReader();

    //               reader.onload = (event) => {
    //                 if (event && event.target && event.target.result) {
    //                   // Cast the result to a string
    //                   newUserImgString.value = event.target.result as string;
    //                 }
    //               };

    //               reader.readAsDataURL(newFile);

    //           }, 'image/png');
    //         };
    //       }
    //     });

    //     document.body.appendChild(inputElement);
    //     inputElement.click();
    //     isLoading.value = false;
    //   } catch (error) {
    //     console.log(error);
    //     isLoading.value = false;
    //   }
    // },

    schoolName(item) {
      return `${item.name}`;
    },

    async saveTeacherProfile() {
      this.loadingTeacherProfile = true;
      try {
        if (this.userHasProfile) {
          await this.updateTeacherProfile();
        } else {
          await this.createTeacherProfile();
        }
      } catch (error) {
        console.log(error);
      }
      // finally {
      //   await this.$nextTick();
      //   this.loadingTeacherProfile = false;
      // }
    },

    async createTeacherProfile() {
      try {
        await DataStore.save(
          new TeacherProfile({
            title: this.teacherProfileInputs.title.trim(),
            name: this.teacherProfileInputs.name.trim(),
            surname: this.teacherProfileInputs.surname.trim(),
            language: this.teacherProfileInputs.language,
            province: this.teacherProfileInputs.province,
            daysPerWeek: this.teacherProfileInputs.daysPerWeek,
            status: "Active",
            TeacherSchedules: [],
            TeacherClassSubjects: [],
            School: this.teacherProfileInputs.School,
            type: "TeacherProfile",
          })
        )
          .then(() => {
            this.userHasProfile = true;
            this.loadingTeacherProfile = false;
          })
          .catch((error) => {
            console.log(error);
            this.loadingTeacherProfile = false;
          });
      } catch (error) {
        console.log(error);
      }
    },

    async updateTeacherProfile() {
      try {
        await DataStore.save(
          TeacherProfile.copyOf(
            this.syncedTeacherProfileArray[0],
            (updateModel) => {
              (updateModel.title = this.teacherProfileInputs.title.trim()),
                (updateModel.name = this.teacherProfileInputs.name.trim()),
                (updateModel.surname =
                  this.teacherProfileInputs.surname.trim()),
                (updateModel.language = this.teacherProfileInputs.language),
                (updateModel.province = this.teacherProfileInputs.province),
                (updateModel.daysPerWeek =
                  this.teacherProfileInputs.daysPerWeek),
                (updateModel.status = "Active"),
                (updateModel.School = this.teacherProfileInputs.School);
            }
          )
        )
          .then(() => {
            this.loadingTeacherProfile = false;
          })
          .catch((error) => {
            console.log(error);
            this.loadingTeacherProfile = false;
          });
      } catch (error) {
        console.log(error);
      }
    },

    async syncTeacherProfile() {
      try {
        this.teacherProfileSubscription = DataStore.observeQuery(
          TeacherProfile
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedTeacherProfileArray !== items) {
              this.loadingTeacherProfile = true;

              this.syncedTeacherProfileArray = items;

              if (items.length > 0) {
                this.userHasProfile = true;

                for (const key in this.teacherProfileInputs) {
                  if (
                    Object.prototype.hasOwnProperty.call(
                      this.teacherProfileInputs,
                      key
                    ) &&
                    Object.prototype.hasOwnProperty.call(items[0], key) &&
                    this.teacherProfileInputs[key] !== items[0][key]
                  ) {
                    this.teacherProfileInputs[key] = items[0][key];
                  }
                }

                if (this.syncedSchoolArray.length > 0) {
                  const school = _.find(this.syncedSchoolArray, {
                    id: items[0].teacherProfileSchoolId,
                  });
                  if (school && this.teacherProfileInputs.School !== school)
                    this.teacherProfileInputs.School = school;
                }
              }

              this.loadingTeacherProfile = false;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSchools() {
      try {
        this.schoolSubscription = DataStore.observeQuery(School).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              this.syncedSchoolArray !== items
            ) {
              this.syncedSchoolArray = items;

              if (this.syncedTeacherProfileArray.length > 0) {
                const school = _.find(items, {
                  id: this.syncedTeacherProfileArray[0].teacherProfileSchoolId,
                });
                if (school && this.teacherProfileInputs.School !== school)
                  this.teacherProfileInputs.School = school;
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    if (this.teacherProfileSubscription) {
      this.teacherProfileSubscription.unsubscribe();
    }
    if (this.schoolSubscription) {
      this.schoolSubscription.unsubscribe();
    }
  },
};
</script>
