<template>
  <div v-if="template" :style="printCSS" id="assessmentTemplatePrinterExample">
    <div>
      <v-row no-gutters class="py-2">
        <v-col cols="5" class="py-0 d-flex flex-column">
          <span v-if="template.subject" class="font-weight-bold">{{
            headingValue("Grade") +
            " " +
            template.subject.grade +
            " " +
            template.subject.subject
          }}</span>
          <span class="font-weight-bold">{{ template.title }}</span>
        </v-col>
        <v-col cols="5" class="py-0 d-flex flex-column">
          <span class="font-weight-bold">{{ headingValue("Date") }}:</span>
          <span class="font-weight-bold">{{ headingValue("Term") }}:</span>
          <span class="font-weight-bold">{{ headingValue("Total") }}:&emsp;&emsp;/{{
            template.totalMarks
          }}</span>
          <span class="font-weight-bold">{{
            headingValue("Time") + ": " + formattedTotalTime
          }}</span>
        </v-col>
        <v-col cols="2" class="py-0 d-flex flex-column align-end">
          <div v-if="assessmentImgSrc !== ''" class="pb-2">
            <img :src="assessmentImgSrc" style="max-height: 48px" />
          </div>
          <div>
            <img src="@/assets/logo-full.svg" style="max-height: 48px" />
          </div>
        </v-col>
      </v-row>

      <hr class="my-1" />

      <div v-if="template.instructions && template.instructions.length > 0">
        <h3>{{ headingValue("Information and Instructions") }}</h3>

        <ol>
          <li>
            <div class="tiptap">
              <table>
                <thead>
                  <tr>
                    <th v-if="showSectionsForTable">
                      {{ headingValue("Sections") }}
                    </th>
                    <th>{{ headingValue("Questions") }}</th>
                    <th>{{ headingValue("Marks") }}</th>
                    <th>
                      {{ headingValue("Marks") + " " + headingValue("Award") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(questionForTable, qIndex) in template.questions" :key="qIndex">
                    <td v-if="showSectionsForTable">
                      {{
                        questionForTable.sectionNumber !== "" ||
                        questionForTable.sectionTitle !== ""
                        ? headingValue("Section") +
                        " " +
                        (questionForTable.sectionNumber !== ""
                          ? questionForTable.sectionNumber
                          : "") +
                        (questionForTable.sectionNumber !== "" &&
                          questionForTable.sectionTitle !== ""
                          ? ":"
                          : "") +
                        (questionForTable.sectionTitle !== ""
                          ? questionForTable.sectionTitle
                          : "")
                        : ""
                      }}
                    </td>
                    <td>
                      {{
                        headingValue("Question") +
                        " " +
                        questionForTable.number +
                        (questionForTable.title !== ""
                          ? ": " + questionForTable.title
                          : "")
                      }}
                    </td>
                    <td>
                      {{ questionForTable.marks + " " + headingValue("Marks") }}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
          <li v-for="(instruction, instructionIndex) in template.instructions" :key="instructionIndex">
            {{ instruction }}
          </li>
        </ol>
      </div>

      <div v-if="template.questions && template.questions.length > 0">
        <div v-for="(question, questionIndex) in template.questions" :key="questionIndex">
          <div v-if="isNewSection(questionIndex)">
            <h3>
              {{
                headingValue("Section") +
                " " +
                question.sectionNumber +
                (question.sectionTitle !== "" ? ": " + question.sectionTitle : "")
              }}
            </h3>
          </div>
          <div class="d-flex">
            <h3>
              {{
                headingValue("Question") +
                " " +
                question.number +
                (question.title !== "" ? ": " + question.title : "")
              }}
            </h3>
            <v-spacer />
            <div style="min-width: fit-content">
              <h3>( {{ question.marks + " " + headingValue("Marks") }} )</h3>
            </div>
          </div>

          <div class="pt-2 tiptap" v-html="question.richText"></div>

          <div v-for="(field, fieldIndex) in question.fields" :key="fieldIndex">
            <div class="d-flex">
              <div v-if="field.number !== ''" style="min-width: fit-content; text-align: right">
                <p class="mr-2">{{ field.number }}</p>
              </div>
              <div class="d-flex flex-column mr-2" style="width: -webkit-fill-available">
                <p v-if="field.question && field.question !== ''" style="width: 100%">
                  {{ field.question }}
                </p>
                <div v-if="field.richText !== '<p></p>'" v-html="field.richText" class="tiptap" style="width: 100%"></div>
              </div>
              <div v-if="field.marks" style="min-width: fit-content">
                <p class="font-weight-bold">( {{ field.marks }} )</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
// import { Storage } from "@aws-amplify/storage"
import _ from "lodash";

export default {
  name: "AssessmentTemplatePrinterExample",
  props: {
    template: Object, // JSON object to be printed
    fontSize: {
      type: Number,
      default: 12,
    }, // Font size to be used in the content
    assessmentImgSrc: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    // localTemplate: {}
  }),
  mounted() {
    // this.localTemplate = _.cloneDeep(this.template);
  },
  watch: {
    // template: {
    //     handler: function (val) {
    //         console.log("UPDATE LocalTemplate")
    //         this.localTemplate = _.cloneDeep(val);
    //     },
    //     immediate: true,
    //     deep: true,
    // },
    // localTemplate: {
    //     handler: async function (newVal, oldVal) {
    //         if (oldVal !== newVal) {
    //             console.log("UPDATE-SELF LocalTemplate")
    //             newVal.questions.forEach(async (question) => {
    //                 const parser = new DOMParser();
    //                 const doc = parser.parseFromString(question.richText, 'text/html');
    //                 const imgElements = doc.querySelectorAll('img');
    //                 const promises = [];
    //                 imgElements.forEach((img) => {
    //                     const imageSrcData = img.getAttribute('image-src-data');
    //                     const imageSrc = img.getAttribute('src');
    //                     if (!imageSrc || (imageSrc && imageSrc === '')) {
    //                         if (imageSrcData && imageSrcData.startsWith('http')) {
    //                             img.setAttribute('src', imageSrcData);
    //                         } else if (imageSrcData) {
    //                             // Create a promise for each image load operation
    //                             const promise = Storage.get(imageSrcData, { level: 'public' })
    //                                 .then((newSrc) => {
    //                                     img.setAttribute('src', newSrc);
    //                                 })
    //                                 .catch((error) => {
    //                                     console.error(error);
    //                                 });
    //                             promises.push(promise);
    //                         }
    //                     }
    //                 });
    //                 // Wait for all promises to complete before returning the modified HTML
    //                 await Promise.all(promises);
    //                 question.richText = doc.body.innerHTML;
    //             });
    //         }
    //     },
    //     immediate: true,
    // },
  },
  computed: {
    printCSS() {
      if (this.template.fontFamily && this.template.fontFamily !== 'Default') {
        return {
          fontSize: this.fontSize + 'px',
          fontFamily: this.template.fontFamily
        }
      } else return { fontSize: this.fontSize + 'px' }
    },
    headingValue() {
      return (param) => {
        if (this.template.headings) {
          const foundItem = _.find(this.template.headings, { label: param });
          return foundItem ? foundItem.value : param;
        } else return param;
      };
    },

    showSectionsForTable() {
      // Check if any question has a non-empty sectionTitle or sectionNumber
      return this.template.questions.some(
        (question) =>
          question.sectionTitle !== "" || question.sectionNumber !== ""
      );
    },
    formattedTotalTime() {
      const totalMinutes =
        typeof this.template.totalMinutes !== "string"
          ? this.template.totalMinutes
          : parseInt(this.template.totalMinutes);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      if (hours === 0) {
        return `${minutes} Minute${minutes > 1 ? "s" : ""}`;
      } else if (minutes === 0) {
        return `${hours} Hour${hours > 1 ? "s" : ""}`;
      } else {
        return `${hours} Hour${hours > 1 ? "s" : ""} ${minutes} Minute${minutes > 1 ? "s" : ""
          }`;
      }
    },
    isNewSection() {
      return (questionIndex) => {
        const question = this.template.questions[questionIndex];
        return question && question.sectionNumber !== undefined;
      };
    },
  },
  methods: {
    // processedRichText(richText) {
    //     const parser = new DOMParser();
    //     const doc = parser.parseFromString(richText, 'text/html');
    //     const imgElements = doc.querySelectorAll('img');
    //     const promises = [];
    //     imgElements.forEach((img) => {
    //         const imageSrcData = img.getAttribute('image-src-data');
    //         if (imageSrcData && imageSrcData.startsWith('http')) {
    //             img.setAttribute('src', imageSrcData);
    //         } else if (imageSrcData) {
    //             // Create a promise for each image load operation
    //             const promise = Storage.get(imageSrcData, { level: 'public' })
    //                 .then((newSrc) => {
    //                     img.setAttribute('src', newSrc);
    //                 })
    //                 .catch((error) => {
    //                     console.error(error);
    //                 });
    //             promises.push(promise);
    //         }
    //     });
    //     // Wait for all promises to complete before returning the modified HTML
    //     await Promise.all(promises);
    //     // Now you can safely access the modified HTML
    //     const modifiedHtml = doc.body.innerHTML;
    //     console.log(modifiedHtml);
    //     return modifiedHtml;
    // },
  },
};
</script>

<style lang="scss">
/* Table-specific styling */
.tiptap {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    max-width: 100% !important;
    width: auto !important;
    min-width: 200px !important;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      >* {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  overflow-x: auto;
}

#assessmentTemplatePrinterExample {
  font-family: 'Arial';
}
</style>