<template>
  <div id="adminholidays">
    <div class="pa-2">
      <v-row justify="space-between">
        <v-col>
          <Holidays />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import Holidays from "@/components/admin-components/global/Holidays.vue";
export default {
  name: "AdminHolidays",
  components: { Holidays },
  data: () => ({}),
};
</script>