<template>
  <div id="contactus">
    <ContactForm />
  </div>
</template>
      
<script lang="ts">
import ContactForm from "@/components/global-components/ContactForm.vue";

export default {
  components: { ContactForm },
  name: "ContactUsView",
  data: () => ({}),
};
</script>