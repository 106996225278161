<template>
  <v-dialog max-width="450px" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        class="ml-2"
        v-bind="attrs"
        v-on="on"
        color="secondary"
        id="helper-icon"
        >mdi-help-circle-outline
      </v-icon>
    </template>
    <v-card max-height="77%">
      <v-card-title> {{ showTopic.title }} </v-card-title>
      <v-card-text> <span v-html="showTopic.body"></span> </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "HelperComponent",
  props: {
    topic: String,
  },
  data() {
    return {
      topics: {
        contactsupport: {
          title: "CONTACT SUPPORT:",
          body: "<p>Send support a message after filling in the fields and selecting the relevant category, our support team will contact you shortly.</p>",
        },
        auth: {
          title: "PASSWORD:",
          body: "<p>Password requirements:</p><ul><li>Minimum length 8 character(s)</li><li>Contains at least 1 number</li><li>Contains at least 1 special character</li><li>Contains at least 1 uppercase letter</li><li>Contains at least 1 lowercase letter</li></ul>",
        },
        subscription: {
          title: "SUBSCRIPTION:",
          body: "<p>View, renew, or upgrade your subscription package. Choose what fits your needs best!</p>",
        },
        profile: {
          title: "PROFILE:",
          body: "<p>Update your personal information here, ensuring it's accurate and up-to-date for a better user experience.</p>",
        },
        schedule: {
          title: "SCHEDULE:",
          body: `<p>Streamline your upcoming week! Access and tailor your school days to better structure your academic journey. Days added here will automatically reflect on your calendar. Note: Failing to input all school days will limit your calendar editing capabilities for those dates.</p>`,
        },
        scheduleedit: {
          title: "Adjust the details of your existing schedule.",
          body: "<p></p>",
        },
        classes: {
          title: "CLASSES:",
          body: "<p>In this section, you can select and add classes directly from a comprehensive list spanning from Grade R to Grade 12 based on the South African school curriculum. Easily pick the appropriate grades and subjects and name your classes.</p>",
        },
        classesedit: {
          title: "Adjust the details of your existing classes.",
          body: "<p></p>",
        },
        calendar: {
          title: "CALENDAR:",
          body: "<p>Stay on track with your educational journey! Here, you can view all your scheduled school days and add activities. Ensure every critical activity is added to keep your preparations timely and effective. Remember, any scheduled days will be pulled from your input in the 'Schedule' section. Stay organized and never miss an important date again!</p>",
        },
        calendaractivity: {
          title: "Here, you can edit all your existing activities.",
          body: "<p></p>",
        },
        lessons: {
          title: "LESSONS:",
          body: "<p>Explore our curated lesson plan templates tailored to various subjects and grades. Once you've found the perfect fit, easily schedule it on your calendar to keep your teaching organized and on track. Ensure each day has the right content for your learners.</p>",
        },
        lessonedit: {
          title: "CREATE / EDIT LESSON:",
          body: "<p><h3>CREATE</h3> Dive into curriculum planning with ease. Start from scratch. This section lets you design and tailor lessons to fit your teaching style and students' needs. Set the stage for engaging and practical learning experiences.</p>",
        },
        assessments: {
          title: "ASSESSMENTS:",
          body: "<p>Craft personalized assessments or select from our extensive library of templates. Feel free to modify any template to suit your learners’ needs, ensuring a comprehensive assessment of their progress.</p>",
        },
        assessmentedit: {
          title: "EDIT ASSESSMENT:",
          body: "<p>Adjust the details of your existing assessments. Here, you can modify content to align with specific learning outcomes based on the CAPS curriculum. Ensure your evaluations remain relevant and practical.</p>",
        },
        assessmentcreate: {
          title: "CREATE ASSESSMENT:",
          body: "<p>This section allows you to create meaningful assessments that align with your curriculum objectives. Ensure your students are tested fairly and comprehensively.</p>",
        },
      },
    };
  },
  computed: {
    showTopic() {
      // return this.topics[this.topic];
      return this.$i18n.t(`helperHints.${this.topic}`);
    },
  },
};
</script>
