<template>
  <div id="appplanner">
    <div class="pa-2">
      <v-row justify="space-between">
        <v-col cols="12" md="12">
          <TeacherPlanner />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import TeacherPlanner from "@/components/app-components/teacher/TeacherPlanner.vue";

export default {
  name: "AppPlannerView",
  components: { TeacherPlanner },
  data: () => ({}),
};
</script>