<template>
  <div id="meet-the-team">
    <section id="about-us">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">
          {{ $i18n.t("headings.meetTheTeam") }}
        </h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row class="pt-6" justify="center">
          <v-col
            v-for="({ avatar, name, role }, i) in members"
            :key="i"
            cols="12"
            md="4"
          >
            <v-avatar class="elevation-12 mb-12" size="300">
              <v-img :src="avatar"></v-img>
            </v-avatar>
            <h2 class="font-weight-bold">{{ name }}</h2>
            {{ role }}
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>
    <!--  <section id="our-vision">
      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">Our Vision</h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-responsive class="mx-auto font-weight-light mb-8" max-width="720">
          At Miss Anri's ClassNetwork, we have a vision: to redefine education
          through innovative technology. We believe every learner deserves an
          exceptional education, and every educator should have the tools to
          make it possible. Our platform is designed to bridge the gap between
          traditional teaching methods and the limitless possibilities of modern
          technology.
        </v-responsive>

        <v-avatar class="elevation-12 mb-12" size="128">
          <v-img :src="require('@/assets/logo-square.png')"></v-img>
        </v-avatar>
      </v-container>
    </section>
    <section id="the-team">
      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">Meet the Team</h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider></v-divider>

          <v-divider></v-divider>
        </v-responsive>
        <h3 class="font-weight-bold mb-3">Anri Dempers - Founder and Owner</h3>

        <v-responsive class="mx-auto font-weight-light mb-8" max-width="720">
          Anri Dempers, our founder, is an educator passionate about creating
          positive change in education. Miss Anri understands the challenges
          teachers face daily. Her vision for Miss Anri's ClassNetwork stems
          from a genuine desire to simplify educators' lives and amplify the
          impact of their teaching. Our team is a dynamic blend of experienced
          educators, tech enthusiasts, and creative minds. We're united by a
          shared commitment to excellence in education and a genuine love for
          what we do. Together, we work tirelessly to develop cutting-edge
          platforms like Miss Anri’s ClassNetwork to support educators in South
          Africa and beyond.
        </v-responsive>

        <v-avatar class="elevation-12 mb-12" size="128">
          <v-img :src="require('@/assets/logo-square.png')"></v-img>
        </v-avatar>
      </v-container>
    </section>

    <section id="what-we-believe">
      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">What We Believe</h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-responsive
          class="text-left mx-auto font-weight-light mb-8"
          max-width="720"
        >
          <ul>
            <li>
              <strong>Empowerment:</strong> We believe in empowering educators
              with the latest technology to inspire the next generation of
              leaders.
            </li>
            <li>
              <strong>Innovation:</strong> We're dedicated to staying at the
              forefront of educational technology, continuously improving our
              platforms to meet your evolving needs.
            </li>
            <li>
              <strong>Simplicity:</strong> Our user-friendly interfaces save
              educators time, making their work more efficient and enjoyable.
            </li>
            <li>
              <strong>Community:</strong> We're building a supportive community
              of educators where ideas are shared, collaboration thrives, and
              learning never stops.
            </li>
          </ul>
        </v-responsive>

        <v-avatar class="elevation-12 mb-12" size="128">
          <v-img :src="require('@/assets/logo-square.png')"></v-img>
        </v-avatar>
      </v-container>

      <div class="py-12"></div>
    </section> -->
  </div>
</template>

<script lang="ts">
export default {
  name: "MeetTheTeamView",
  data: () => ({
    members: [
      {
        avatar: require("@/assets/team/AnriDempers.png"),
        name: "Anri Dempers",
        role: "Founder & Owner",
      },
      {
        avatar: require("@/assets/team/ChristoDuPreez.png"),
        name: "Christo Du Preez",
        role: "Lead Developer",
      },
      {
        avatar: require("@/assets/team/JohanDuPreez.png"),
        name: "Johan Du Preez",
        role: "Full Stack Developer",
      },
      {
        avatar: require("@/assets/team/IngeWessels.png"),
        name: "Inge Wessels",
        role: "Quality Controller Intermediate Phase",
      },
      {
        avatar: require("@/assets/team/NizeViveiros.png"),
        name: "Nize Viveiros",
        role: "Quality Controller Foundation Phase",
      },
      {
        avatar: require("@/assets/team/MarinaSaayman.png"),
        name: "Marina Saayman",
        role: "Administrative Officer",
      },
      {
        avatar: require("@/assets/team/NadineMoffatt.png"),
        name: "Nadine Moffatt",
        role: "Marketing & Brand Manager",
      },
      {
        avatar: require("@/assets/team/NatashaCoetzee.png"),
        name: "Natasha Coetzee",
        role: "Educational Resource Developer Specialist",
      },
      {
        avatar: require("@/assets/team/ClarisaVanZyl.png"),
        name: "Clarisa Van Zyl",
        role: "Educational Resource Developer Specialist",
      },
    ],
  }),
};
</script>
