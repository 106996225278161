<template>
  <div id="schools">
    <v-card max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4"> Schools </v-card-title>
        <v-spacer />
        <v-btn
          color="primary"
          class="mx-4"
          @click="createItem"
          :disabled="offline"
        >
          New School
        </v-btn>
      </v-row>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <div class="px-4">
        <v-data-table
          :headers="headers"
          :items="schools"
          :sort-desc="sortDesc"
          :sort-by="sortBy"
          must-sort
          :search="search"
          :loading="!schoolSubscriptionSynced && schools?.length === 0"
          loading-text="Loading Schools..."
          no-data-text="No Schools found"
        >
          <template v-slot:[`item.isGeneric`]="{ item }">
            <v-simple-checkbox
              v-model="item.isGeneric"
              disabled
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              :disabled="offline"
            >
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" :disabled="offline">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog persistent v-model="dialog" max-width="500px">
      <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent="save">
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.name"
                    label="Name"
                    :rules="formRules.name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    v-model="editedItem.isGeneric"
                    label="Is this a generic school? Templates linked to this school will be avalable to all teachers."
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="editedItem.provinces"
                    :items="provinces"
                    label="Select Provinces"
                    multiple
                    chips
                    hint="What are the linked provinces for this school?"
                    persistent-hint
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="close"> Cancel </v-btn>
            <v-btn :disabled="!valid" color="primary" @click="save">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center"
          >Are you sure you want to delete this school?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDelete">Cancel</v-btn>
          <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore } from "aws-amplify";
import { School } from "@/models";
import _ from "lodash";

export default {
  name: "AdminSchools",
  data: () => ({
    initiallyLoaded: false,
    schoolSubscription: null,
    schoolSubscriptionSynced: false,
    syncedSchoolArray: [],
    valid: false,
    dialog: false,
    dialogDelete: false,
    sortBy: "name",
    sortDesc: true,
    search: "",
    headers: [
      { text: "Name", value: "name" },
      { text: "Provinces", value: "provinces" },
      { text: "Generic", value: "isGeneric" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    schools: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      provinces: [],
      isGeneric: false,
    },
    defaultItem: {
      name: "",
      provinces: [],
      isGeneric: false,
    },
    formRules: {
      name: [(v) => !!v || "Name is required"],
    },
    provinces: [
      "Eastern Cape",
      "Free State",
      "Gauteng",
      "KwaZulu-Natal",
      "Limpopo",
      "Mpumalanga",
      "Northern Cape",
      "North West",
      "Western Cape",
    ],
  }),

  async mounted() {
    try {
      // Sync the Schools from the datastore
      await this.syncSchools();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New School" : "Edit School";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
      this.valid = false;
    },

    createItem() {
      this.resetForm();
      this.dialog = true;
    },

    editItem(item) {
      this.editedIndex = this.schools.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.schools.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      // this.holidays.splice(this.editedIndex, 1);
      await DataStore.delete(this.syncedSchoolArray[this.editedIndex]);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        // Update school
        await this.updateSchool();
      } else {
        // Create new school
        await this.createSchool();
      }
      this.close();
    },

    async syncSchools() {
      try {
        this.schoolSubscription = DataStore.observeQuery(School).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedSchoolArray !== items) {
              this.syncedSchoolArray = items;

              this.schools = _.cloneDeep(items);

              this.schoolSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.schoolSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async createSchool() {
      try {
        await DataStore.save(
          new School({
            name: this.editedItem.name.trim(),
            provinces: this.editedItem.provinces,
            isGeneric: this.editedItem.isGeneric,
          })
        );
      } catch (error) {
        console.log(error);
      }
    },

    async updateSchool() {
      try {
        await DataStore.save(
          School.copyOf(
            _.find(this.syncedSchoolArray, { id: this.editedItem.id }),
            (updateModel) => {
              (updateModel.name = this.editedItem.name.trim()),
                (updateModel.provinces = this.editedItem.provinces),
                (updateModel.isGeneric = this.editedItem.isGeneric);
            }
          )
        );
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    if (this.schoolSubscription) {
      this.schoolSubscription.unsubscribe();
    }
  },
};
</script>
