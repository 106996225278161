<template>
  <div id="prices">
    <section id="pricing">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">PRICING PLANS</h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider></v-divider>
          <v-divider></v-divider>
        </v-responsive>

        <v-row>
          <v-col
            v-for="({ icon, title, text, price, hide }, i) in prices"
            :key="i"
            cols="12"
            md="3"
          >
            <v-card
              v-if="!hide"
              class="py-12 px-4"
              color="grey lighten-5"
              height="100%"
            >
              <v-theme-provider dark>
                <div>
                  <v-avatar color="primary" size="88">
                    <v-icon large>{{ icon }}</v-icon>
                  </v-avatar>
                </div>
              </v-theme-provider>

              <v-card-title
                class="justify-center font-weight-black text-uppercase"
                >{{ title }}</v-card-title
              >
              <v-card-text class="text-body-1">{{ text }}</v-card-text>
              <v-card-text class="text-body-2 font-weight-bold">{{
                price
              }}</v-card-text>
              <v-card-text>
                <v-btn outlined color="black" to="/app/profile"
                  >Subscribe Now</v-btn
                >
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <div class="py-7"></div>
      </v-container>
    </section>
    <section id="offering" class="grey lighten-3">
      <div class="py-12"></div>
      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">
          WHAT IS INCLUDED IN YOUR SUBSCRIPTION?
        </h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider></v-divider>
          <v-divider></v-divider>
        </v-responsive>

        <v-row>
          <v-col
            v-for="({ icon, title, text }, i) in offerings"
            :key="i"
            cols="12"
            md="3"
          >
            <v-card
              class="py-12 px-4"
              color="grey lighten-5"
              flat
              height="100%"
            >
              <v-theme-provider dark>
                <div>
                  <v-avatar color="primary" size="88">
                    <v-icon large>{{ icon }}</v-icon>
                  </v-avatar>
                </div>
              </v-theme-provider>

              <v-card-title
                class="justify-center font-weight-black text-uppercase"
                >{{ title }}</v-card-title
              >
              <v-card-text class="text-body-1">{{ text }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <div class="py-12"></div>
    </section>

    <section id="stats">
      <div
        style="
          background-attachment: fixed;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          color: #ffffff;
        "
        class="parallax"
      >
        <v-container fill-height style="padding: 120px 0">
          <v-row class="mx-auto">
            <v-col
              v-for="[value, title] of stats"
              :key="title"
              cols="12"
              md="4"
            >
              <div class="text-center">
                <div
                  class="display-3 font-weight-bold mb-4"
                  v-text="value"
                ></div>

                <div
                  class="title font-weight-regular text-uppercase"
                  v-text="title"
                ></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
export default {
  name: "PricesView",
  data() {
    return {
      prices: [
        // {
        //   hide: true,
        // },
        {
          icon: "mdi-calendar-blank-outline",
          title: "Monthly Plan",
          text: "Choose our monthly plan and pay a monthly subscription fee. This plan is ideal for teachers who want to try out our platform and see if it suits their needs.",
          price: "From R200/month",
        },
        {
          icon: "mdi-calendar-range-outline",
          title: "3 Monthly Plan",
          text: "Choose our 3 monthly plan and pay a 3 monthly subscription fee. This plan is ideal for teachers who want to save money and have access to our platform for 3 months.",
          price: "From R550/3 months",
        },
        {
          icon: "mdi-calendar-month-outline",
          title: "6 Monthly Plan",
          text: "Choose our 6 monthly plan and pay a 6 monthly subscription fee. This plan is ideal for teachers who want to save money and have access to our platform for 6 months.",
          price: "From R1050/6 months",
        },
        {
          icon: "mdi-calendar-multiple",
          title: "Yearly Plan",
          text: "Choose our yearly plan and pay a yearly subscription fee. This plan is ideal for teachers who want to save money and have access to our platform for a year.",
          price: "From R2000/year",
          // hide: true,
        },
        // {
        //   hide: true,
        // },
      ],
      offerings: [
        {
          icon: "mdi-calendar-cursor-outline",
          title: "Access to Term Calendars",
          text: "Unlock a calendar featuring set term dates, holidays, and weeks 1-10, making it easy to plan your teaching schedule. Add lessons, assessments, sport, leave day, off period to your calendar.",
        },
        {
          icon: "mdi-notebook-outline",
          title: "Efficient Planning",
          text: "Seamlessly plan your days, weeks, and months with these user-friendly calendars designed to simplify your workload.",
        },
        {
          icon: "mdi-hammer-wrench",
          title: "Lesson Planning Tool",
          text: "Access a vast library of lesson planning templates. Customize these templates to align with your unique teaching needs.",
        },
        {
          icon: "mdi-hammer-screwdriver",
          title: "Assessment Planning Tool",
          text: "Explore an extensive collection of assessment templates aligned with the curriculum. Tailor assessments to suit your requirements effortlessly.",
        },
        {
          icon: "mdi-chair-school",
          title: "Cognitive Level Customization",
          text: "Have the flexibility to choose cognitive levels for your assessment questions. The system will automatically calculate marks for each level, streamlining the assessment process.",
        },
        {
          icon: "mdi-calendar-export-outline",
          title: "Calendar Integration",
          text: "Allocate your lessons and assessments to your calendar, ensuring a well-organized teaching schedule.",
        },
        {
          icon: "mdi-printer-outline",
          title: "Printing",
          text: "Print your calendars, lesson plans, and assessments in a user-friendly format, ready for classroom use.",
        },
        {
          icon: "mdi-face-agent",
          title: "Teaching Support",
          text: "Experience support with our Teacher Support feature. Connect with experienced educators who are ready to answer your questions, provide guidance, and share best practices for effective teaching.",
          price: "$12/month",
        },
      ],
      stats: [
        ["10K+", "Customers"],
        ["23900+", "Resources sold"],
        ["25K+", "Social Media followers"],
      ],
    };
  },
};
</script>
<style>
.parallax {
  background-image: url("@/assets/app-assets/teacher/teacher-stats-img.jpg");
}
</style>
