<template>
  <div v-if="initiallyLoaded" class="mx-4 my-2">
    <LessonTemplatePrinterExample
      :template="currentTeacherSubjectTemplate"
      :fontSize="fontSize"
      :lessonImgSrc="lessonImgSrc"
    />

    <div class="print-copyright-footer">
      <span>
        Made with &copy; {{ new Date().getFullYear() }} Miss Anri's
        ClassNetwork.
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore, Storage } from "aws-amplify";
import { TeacherSubjectTemplate, School, Subject } from "@/models";
import _ from "lodash";
import LessonTemplatePrinterExample from "@/components/global-components/LessonTemplatePrinterExample.vue";

export default {
  name: "LessonPrinter",
  components: { LessonTemplatePrinterExample },
  data() {
    return {
      initiallyLoaded: false,
      teacherSubjectTemplateSubscription: null,
      schoolSubscription: null,
      subjectSubscription: null,
      syncedTeacherSubjectTemplateArray: [],
      syncedSchoolArray: [],
      syncedSubjectArray: [],
      teacherSubjectTemplates: [],
      currentTeacherSubjectTemplate: {},
      lessonImgSrc: "",
      lessonImgSrcLocal: "",
      fontSize: 17, // default font size
      minFontSize: 14, // minimum font size
      maxFontSize: 20, // maximum font size
    };
  },
  async mounted() {
    try {
      document.addEventListener("increaseFontSize", this.increaseFontSize);
      document.addEventListener("decreaseFontSize", this.decreaseFontSize);
      document.addEventListener(
        "changeTemplateImgSrc",
        this.changeTemplateImgSrc
      );

      // Sync the TeacherSubjectTemplates from the datastore
      await this.syncTeacherSubjectTemplates();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
    }),
    // formattedTotalTime() {
    //   const totalMinutes = typeof this.teacherSubjectTemplates[0].totalMinutes !== 'string' ? this.teacherSubjectTemplates[0].totalMinutes : parseInt(this.teacherSubjectTemplates[0].totalMinutes);
    //   const hours = Math.floor(totalMinutes / 60);
    //   const minutes = totalMinutes % 60;

    //   if (hours === 0) {
    //     return `${minutes} Minute${minutes > 1 ? 's' : ''}`;
    //   } else if (minutes === 0) {
    //     return `${hours} Hour${hours > 1 ? 's' : ''}`;
    //   } else {
    //     return `${hours} Hour${hours > 1 ? 's' : ''} ${minutes} Minute${minutes > 1 ? 's' : ''}`;
    //   }
    // },
    // isNewSection() {
    //   return questionIndex => {
    //     const question = this.teacherSubjectTemplates[0].questions[questionIndex];
    //     return question && question.sectionNumber !== undefined;
    //   };
    // },
  },

  methods: {
    async changeTemplateImgSrc(event) {
      if (event.detail > "") {
        if (this.lessonImgSrcLocal > "") {
          this.lessonImgSrc = this.lessonImgSrcLocal;
        } else {
          this.lessonImgSrcLocal = await Storage.get(event.detail, {
            level: "public",
          });
          this.lessonImgSrc = this.lessonImgSrcLocal;
        }
      } else {
        this.lessonImgSrc = "";
      }
    },

    increaseFontSize() {
      if (this.fontSize < this.maxFontSize) {
        this.fontSize += 1;
      }
    },

    decreaseFontSize() {
      if (this.fontSize > this.minFontSize) {
        this.fontSize -= 1;
      }
    },

    async syncTeacherSubjectTemplates() {
      try {
        this.teacherSubjectTemplateSubscription = DataStore.observeQuery(
          TeacherSubjectTemplate,
          (tst) => tst.id("eq", this.$route.params.templateId)
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              this.syncedTeacherSubjectTemplateArray !== items
            ) {
              this.syncedTeacherSubjectTemplateArray = items;

              this.currentTeacherSubjectTemplate = _.cloneDeep(items[0]);
              // if (this.syncedSubjectArray && this.syncedSubjectArray.length > 0 && this.teacherSubjectTemplates && this.teacherSubjectTemplates.length > 0) {
              //   this.teacherSubjectTemplates.forEach((item) => {
              //     const subject = _.find(this.syncedSubjectArray, { id: item.subjectID });
              //     // const school = _.find(this.syncedSchoolArray, { id: item.schoolID });
              //     if (item.subject !== subject) item.subject = subject || { grade: "", subject: "" };
              //     // if (item.school !== school) item.school = school || { name: "", provinces: [], isGeneric: false };
              //   });
              // }

              // Sync the Schools from the datastore
              await this.syncSchools();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSchools() {
      try {
        const templateSchoolId = this.currentTeacherSubjectTemplate.schoolID;
        this.schoolSubscription = DataStore.observeQuery(School, (s) =>
          s.id("eq", templateSchoolId)
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              this.syncedSchoolArray !== items
            ) {
              this.syncedSchoolArray = items;

              // this.teacherSubjectTemplates.forEach((item) => {
              //   const school = _.find(items, { id: item.schoolID });
              //   if (item.school !== school) item.school = school || { name: "", provinces: [], isGeneric: false };
              // });

              const mutableObj = _.cloneDeep(
                this.currentTeacherSubjectTemplate
              );

              mutableObj.subject = items[0];

              this.currentTeacherSubjectTemplate = Object.assign(
                {},
                mutableObj
              );

              // Sync the Subjects from the datastore
              await this.syncSubjects();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSubjects() {
      try {
        const templateSubjectId = this.currentTeacherSubjectTemplate?.subjectID
          ? this.currentTeacherSubjectTemplate.subjectID
          : undefined;

        if (typeof templateSubjectId === "undefined") return;

        this.subjectSubscription = DataStore.observeQuery(Subject, (sub) =>
          sub.id("eq", templateSubjectId)
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              this.syncedSubjectArray !== items
            ) {
              this.syncedSubjectArray = items;

              const mutableObj = _.cloneDeep(
                this.currentTeacherSubjectTemplate
              );

              mutableObj.subject = items[0];

              this.currentTeacherSubjectTemplate = Object.assign(
                {},
                mutableObj
              );

              // this.teacherSubjectTemplates.forEach((item) => {
              //   const subject = _.find(items, { id: item.subjectID });
              //   if (item.subject !== subject) item.subject = subject || { grade: "", subject: "" };
              // });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    document.removeEventListener("increaseFontSize", this.increaseFontSize);
    document.removeEventListener("decreaseFontSize", this.decreaseFontSize);
    document.removeEventListener(
      "changeTemplateImgSrc",
      this.changeTemplateImgSrc
    );

    if (this.teacherSubjectTemplateSubscription) {
      this.teacherSubjectTemplateSubscription.unsubscribe();
    }
    if (this.subjectSubscription) {
      this.subjectSubscription.unsubscribe();
    }
    if (this.schoolSubscription) {
      this.schoolSubscription.unsubscribe();
    }
  },
};
</script>
