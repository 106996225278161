<template>
  <div id="teachersubscription">
    <v-card max-width="100%" outlined>
      <v-card-title class="text-h4">
        {{ $i18n.t("headings.subscription") }}
        <helper topic="subscription" />
        <v-progress-circular
          v-if="checkingPayfastSubscription"
          class="ml-3"
          :size="25"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="payfastForm"
          action="https://www.payfast.co.za/eng/process"
          method="POST"
          v-if="
            teacherProfileAppSyncVersion &&
            (!teacherProfilePayfastSubscriptionToken ||
              (typeof teacherProfilePayfastSubscriptionResponse?.status_text ===
                'string' &&
                teacherProfilePayfastSubscriptionResponse.status_text !==
                  'ACTIVE'))
          "
        >
          <input type="hidden" name="merchant_id" :value="merchant_id" />
          <input type="hidden" name="merchant_key" :value="merchant_key" />
          <input type="hidden" name="return_url" :value="return_url" />
          <input type="hidden" name="cancel_url" :value="cancel_url" />
          <input type="hidden" name="notify_url" :value="notify_url" />
          <input type="hidden" name="email_address" :value="userEmail" />

          <input type="hidden" name="m_payment_id" :value="m_payment_id" />
          <input type="hidden" name="amount" :value="amount" />
          <input type="hidden" name="item_name" :value="item_name" />
          <input
            type="hidden"
            name="item_description"
            :value="item_description"
          />
          <input type="hidden" name="custom_str1" :value="teacherProfileID" />
          <input
            type="hidden"
            name="custom_str2"
            :value="
              typeof teacherProfileAppSyncVersion === 'string'
                ? parseInt(teacherProfileAppSyncVersion) + 1
                : 1
            "
          />

          <input
            type="hidden"
            name="subscription_type"
            :value="subscription_type"
          />
          <input
            type="hidden"
            name="recurring_amount"
            :value="recurring_amount"
          />
          <input type="hidden" name="frequency" :value="frequency" />
          <input type="hidden" name="cycles" :value="cycles" />

          <input type="hidden" name="signature" :value="signature" />

          <v-alert
            v-if="
              typeof teacherProfilePayfastSubscriptionResponse?.status_text ===
                'string' &&
              teacherProfilePayfastSubscriptionResponse.status_text !== 'ACTIVE'
            "
            type="warning"
          >
            <v-row align="center">
              <v-col class="grow text-uppercase">
                {{ teacherProfilePayfastCancelledText }}
              </v-col>
              <v-col class="shrink">
                <button
                  type="submit"
                  ref="hiddenSubmitButton"
                  style="display: none"
                ></button>
                <v-btn
                  :disabled="
                    handlingFormSubmit ||
                    checkingPayfastSubscription ||
                    !teacherProfilePayfastSubscriptionResponse
                  "
                  :loading="
                    handlingFormSubmit ||
                    checkingPayfastSubscription ||
                    !teacherProfilePayfastSubscriptionResponse
                  "
                  class="ml-5"
                  small
                  right
                  outlined
                  @click.prevent="handleFormSubmit"
                >
                  {{ $i18n.t("status.activateMySub") }}
                </v-btn>
                <!-- <v-btn class="ml-5" small right outlined type="submit">
                activate my subscription
              </v-btn> -->
              </v-col>
            </v-row>
          </v-alert>
          <v-alert
            v-else-if="!checkingPayfastSubscription"
            type="info"
            color="primary"
          >
            <v-row align="center">
              <v-col class="grow text-uppercase">
                {{ $i18n.t("status.noActiveSub") }}
              </v-col>
              <v-col class="shrink">
                <button
                  type="submit"
                  ref="hiddenSubmitButton"
                  style="display: none"
                ></button>
                <v-btn
                  :disabled="
                    handlingFormSubmit ||
                    checkingPayfastSubscription ||
                    !validSubPackageForm ||
                    payfastFrequencyGroup === null ||
                    selectedGrades.length === 0
                  "
                  :loading="handlingFormSubmit || checkingPayfastSubscription"
                  class="ml-5"
                  small
                  right
                  outlined
                  @click.prevent="handleFormSubmit"
                >
                  {{ $i18n.t("headings.subscribe") }}
                </v-btn>

                <!-- <v-btn class="ml-5" small right outlined type="submit">
                subscribe
              </v-btn> -->
              </v-col>
            </v-row>
            <!-- {{ handlingFormSubmit }}
            {{ checkingPayfastSubscription }}
            {{ !teacherProfilePayfastSubscriptionResponse }} -->
          </v-alert>
        </v-form>
        <v-alert
          v-if="
            teacherProfilePayfastSubscriptionResponse?.status_text === 'ACTIVE'
          "
          type="success"
        >
          <v-row align="center">
            <v-col class="grow text-uppercase">
              {{ $i18n.t("status.active") }}
            </v-col>
            <v-col class="shrink">
              <v-btn
                class="ml-5"
                small
                right
                outlined
                @click="cancelPayfastSubscription"
                :loading="checkingPayfastSubscription"
                :disabled="disableCancelSubscription"
              >
                {{ $i18n.t("status.cancelMySub") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <v-alert v-else-if="payFastError" type="error">
          <v-row align="center">
            <v-col class="grow">
              {{ payFastError }}
            </v-col>
            <v-col class="shrink">
              <v-btn
                class="ml-5"
                small
                right
                outlined
                @click="checkPayfastSubscription"
                :loading="checkingPayfastSubscription"
                :disabled="checkingPayfastSubscription"
              >
                {{ $i18n.t("headings.recheck") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <v-alert
          v-else-if="teacherProfilePayfastSubscriptionToken === 'PROCESSING'"
          type="info"
        >
          <v-row align="center">
            <v-col class="grow text-uppercase"
              >{{ $i18n.t("status.processingSub") }}
            </v-col>
            <v-col class="shrink">
              <!-- <v-btn class="ml-5" small right outlined type="submit">
                subscribe
              </v-btn> -->
            </v-col>
          </v-row>
        </v-alert>
        <div>
          <v-form
            ref="subPackageForm"
            v-model="validSubPackageForm"
            @submit.prevent="showTACDialog"
          >
            <div
              v-if="
                !updatingPayfastSubscription &&
                syncedTeacherProfileModel?.payfastSubscriptionPackage
                  ?.pendingPackage &&
                !syncedTeacherProfileModel?.payfastSubscriptionPackage
                  .createSub &&
                teacherProfilePayfastSubscriptionResponse?.status_text ===
                  'ACTIVE'
              "
            >
              <v-alert type="error">
                <v-row align="center">
                  <v-col class="grow text-uppercase">
                    {{ $i18n.t("status.failedPayment") }}
                  </v-col>
                  <v-col class="shrink">
                    <v-row class="flex-row flex-nowrap" no-gutters>
                      <v-btn @click="clearPendingPackage" small right outlined>
                        {{ $i18n.t("actions.cancel") }}
                      </v-btn>
                      <v-btn
                        small
                        right
                        light
                        class="ml-5"
                        @click="retryPendingPackage()"
                      >
                        {{ $i18n.t("actions.retry") }}
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-alert>
              <!-- <span class="text-h6 text-left pb-4"> Failed Payment </span>
              <v-row class="py-4">
                <v-col cols="12" sm="6">
                  <v-btn block @click="clearPendingPackage"> Cancel </v-btn>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn block color="primary" @click="retryPendingPackage()">
                    Retry
                  </v-btn>
                </v-col>
              </v-row> -->
            </div>
            <span class="text-h6 text-left pb-4">
              {{
                `${$i18n.t("headings.subscription")} ${$i18n.t(
                  "headings.package"
                )}`
              }}</span
            >
            <!-- <v-radio-group
              v-model="payfastFrequencyGroup"
              :rules="[(v) => !!v || 'a Package is Required']"
              @change="validateForm"
            >
              <v-radio
                v-for="item in payfastPackages"
                :key="item.frequency"
                :value="item.frequency"
                :disabled="disablePackage(item.frequency)"
              >
                <template v-slot:label>
                  <div class="d-flex flex-column">
                    <div>
                      <span class="font-weight-bold">{{ item.title }}</span> /
                      <span class="text-caption"
                        >R{{
                          item.amount +
                          (item.frequency === 3
                            ? " per Month"
                            : item.frequency === 4
                            ? " every 3 Months"
                            : item.frequency === 5
                            ? " every 6 Months"
                            : item.frequency === 6
                            ? " per Year"
                            : "")
                        }}</span
                      >
                      /
                      <span class="text-caption"
                        >R{{ item.perGrade + " per additional Grade" }}</span
                      >
                      /
                      <span class="text-caption">{{
                        item.limit !== -1
                          ? "Download limit " +
                            item.limit +
                            " per Month per Grade"
                          : "Downloads Unlimited"
                      }}</span>
                    </div>
                  </div>
                </template>
              </v-radio>
            </v-radio-group> -->

            <v-carousel
              height="min-content"
              v-model="payfastFrequencyGroup"
              hide-delimiters
              :show-arrows="
                !updatingPayfastSubscription &&
                !handlingFormSubmit &&
                !checkingPayfastSubscription
              "
            >
              <v-carousel-item
                v-for="(payfastPackage, i) in payfastPackages"
                :key="i"
              >
                <v-sheet
                  height="100%"
                  tile
                  class="d-flex align-center justify-center"
                >
                  <v-card
                    height="min-content"
                    :disabled="disablePackage(payfastPackage.frequency)"
                    class="my-3"
                    width="70%"
                  >
                    <!-- <v-row class="fill-height" align="center" justify="center">
                      <div class="text-h2">Slide {{ i + 1 }}</div>
                    </v-row> -->
                    <v-card-title class="pb-0">{{
                      payfastPackage.title
                    }}</v-card-title>

                    <v-card-text>
                      <ul>
                        <li>
                          R{{
                            payfastPackage.amount +
                            (payfastPackage.frequency === 3
                              ? ` ${$i18n.t("payfastPackages.perMonth")}`
                              : payfastPackage.frequency === 4
                              ? ` ${$i18n.t("payfastPackages.every3Months")}`
                              : payfastPackage.frequency === 5
                              ? ` ${$i18n.t("payfastPackages.every6Months")}`
                              : payfastPackage.frequency === 6
                              ? ` ${$i18n.t("payfastPackages.perYear")}`
                              : "")
                          }}
                        </li>
                        <li v-show="showPackageDataGrandFathered">
                          R{{
                            payfastPackage.perGrade +
                            ` ${$i18n.t("payfastPackages.perAdditionalGrade")}`
                          }}
                        </li>
                        <li v-show="showPackageDataGrandFathered">
                          {{
                            payfastPackage.limit !== -1
                              ? `${$i18n.t("payfastPackages.downloadLimit")} ` +
                                payfastPackage.limit +
                                ` ${$i18n.t(
                                  "payfastPackages.perMonthPerGrade"
                                )}`
                              : `${$i18n.t(
                                  "payfastPackages.downloadsUnlimited"
                                )}`
                          }}
                        </li>
                      </ul>
                    </v-card-text>

                    <!-- <v-divider class="mx-4"></v-divider> -->

                    <div v-show="showPackageDataGrandFathered">
                      <v-card-title class="py-0">{{
                        $i18n.t("headings.grades")
                      }}</v-card-title>

                      <v-card-text>
                        <v-chip-group
                          v-model="selectedGrades"
                          active-class="primary white--text"
                          column
                          multiple
                        >
                          <v-chip
                            v-for="grade in gradeArray"
                            :key="grade"
                            :value="grade"
                            :disabled="
                              updatingPayfastSubscription ||
                              handlingFormSubmit ||
                              checkingPayfastSubscription
                            "
                            >{{ grade }}</v-chip
                          >
                        </v-chip-group>

                        <div
                          v-show="
                            payfastFrequencyGroup !== null &&
                            selectedGrades.length === 0
                          "
                          class="v-messages error--text"
                          role="alert"
                        >
                          <div class="v-messages__wrapper">
                            <div class="v-messages__message">
                              {{ $i18n.t("payfastPackages.min1GradeRequired") }}
                            </div>
                          </div>
                        </div>
                      </v-card-text>
                    </div>

                    <div
                      v-if="
                        !checkingPayfastSubscription &&
                        (!syncedTeacherProfileModel?.payfastSubscriptionPackage
                          ?.grandFathered ||
                          (syncedTeacherProfileModel.payfastSubscriptionPackage
                            .grandFathered &&
                            syncedTeacherProfileModel.payfastSubscriptionPackage
                              .frequency !== selectedFrequency)) &&
                        (localSubPackageChanged ||
                          !teacherProfilePayfastSubscriptionToken ||
                          (typeof teacherProfilePayfastSubscriptionResponse?.status_text ===
                            'string' &&
                            teacherProfilePayfastSubscriptionResponse.status_text !==
                              'ACTIVE'))
                      "
                    >
                      <v-card-title class="py-0">{{
                        $i18n.t("payfastPackages.newSubSummary")
                      }}</v-card-title>

                      <v-simple-table dense class="pb-4 px-4">
                        <template v-slot:default>
                          <!-- <thead>
                          <tr>
                            <th
                              colspan="2"
                              class="text-h6 text-left pb-4"
                              style="border: none"
                            >
                              New Subscription Summary
                            </th>
                          </tr>
                        </thead> -->
                          <tbody>
                            <tr
                              v-if="
                                syncedTeacherProfileModel?.payfastSubscriptionPackage &&
                                syncedTeacherProfileModel
                                  .payfastSubscriptionResponse?.run_date &&
                                calculateProRata(
                                  syncedTeacherProfileModel
                                    .payfastSubscriptionResponse.run_date,
                                  syncedTeacherProfileModel.payfastSubscriptionPackage,
                                  {
                                    ...payfastPackage,
                                    grades: selectedGrades,
                                    grandFathered:
                                      syncedTeacherProfileModel
                                        .payfastSubscriptionPackage
                                        ?.grandFathered &&
                                      selectedFrequency ===
                                        syncedTeacherProfileModel
                                          .payfastSubscriptionPackage.frequency
                                        ? true
                                        : false,
                                  }
                                ).amountToPay > 0
                              "
                            >
                              <td>
                                {{
                                  $i18n.t("payfastPackages.immediatePayment")
                                }}
                              </td>
                              <td>
                                R
                                {{
                                  calculateProRata(
                                    syncedTeacherProfileModel
                                      .payfastSubscriptionResponse.run_date,
                                    syncedTeacherProfileModel.payfastSubscriptionPackage,
                                    {
                                      ...payfastPackage,
                                      grades: selectedGrades,
                                      grandFathered:
                                        syncedTeacherProfileModel
                                          .payfastSubscriptionPackage
                                          ?.grandFathered &&
                                        selectedFrequency ===
                                          syncedTeacherProfileModel
                                            .payfastSubscriptionPackage
                                            .frequency
                                          ? true
                                          : false,
                                    }
                                  ).amountToPay.toFixed(2)
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>{{ $i18n.t("payfastPackages.amount") }}</td>
                              <td>
                                R
                                {{
                                  (selectedGrades.length > 1
                                    ? payfastPackage.perGrade *
                                        (selectedGrades.length - 1) +
                                      payfastPackage.amount
                                    : payfastPackage.amount
                                  ).toFixed(2)
                                }}
                              </td>
                            </tr>
                            <!-- <tr
                            v-if="
                              teacherProfilePayfastSubscriptionResponse.run_date
                            "
                          >
                            <td
                              v-if="
                                teacherProfilePayfastSubscriptionResponse.status_text ===
                                'ACTIVE'
                              "
                            >
                              Next Payment
                            </td>
                            <td v-else>Valid Until</td>
                            <td>
                              {{
                                teacherProfilePayfastSubscriptionResponse.run_date.split(
                                  "T"
                                )[0]
                              }}
                            </td>
                          </tr> -->
                            <tr>
                              <td>{{ $i18n.t("otherText.frequency") }}</td>
                              <td>
                                {{ payfastPackage.title }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>

                      <v-card-actions>
                        <v-btn
                          v-if="localSubPackageChanged"
                          @click="showTACDialog"
                          :disabled="
                            disablePackage(payfastPackage.frequency) ||
                            updatingPayfastSubscription ||
                            handlingFormSubmit ||
                            !validSubPackageForm ||
                            checkingPayfastSubscription ||
                            payfastFrequencyGroup === null ||
                            selectedGrades.length === 0
                          "
                          :loading="updatingPayfastSubscription"
                          color="primary"
                          >{{ $i18n.t("payfastPackages.updatePackage") }}
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </v-card>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>

            <!-- <div v-show="payfastFrequencyGroup" class="pb-3">
              <span class="text-h6 text-left pb-4"> Package Grades </span>

              <div class="d-flex flex-wrap pt-2 pb-1">
                <v-checkbox
                  v-for="grade in gradeArray"
                  dense
                  :rules="[
                    (v) =>
                      (!!v && v.length > 0) || 'Minimum 1 Grade is Required',
                  ]"
                  v-model="selectedGrades"
                  :key="grade"
                  :label="`Grade ${grade}`"
                  :value="grade"
                  :disabled="
                    updatingPayfastSubscription ||
                    handlingFormSubmit ||
                    checkingPayfastSubscription
                  "
                  hide-details
                ></v-checkbox>
              </div>
              <div
                v-show="selectedGrades.length === 0"
                class="v-messages error--text"
                role="alert"
              >
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">
                    Minimum 1 Grade is Required
                  </div>
                </div>
              </div>
            </div> -->
          </v-form>
        </div>
        <div v-if="teacherProfilePayfastSubscriptionResponse">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    colspan="2"
                    class="text-h6 text-left pl-0 pb-4"
                    style="border: none"
                  >
                    {{ $i18n.t("payfastPackages.currentSubSummary") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="teacherProfilePayfastSubscriptionResponse.amount">
                  <td>{{ $i18n.t("payfastPackages.amount") }}</td>
                  <td>
                    {{
                      syncedTeacherProfileModel?.payfastSubscriptionPackage
                        ?.paymentMethod === "EFT"
                        ? "EFT"
                        : "R " +
                          (
                            teacherProfilePayfastSubscriptionResponse.amount /
                            100
                          ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr v-if="teacherProfilePayfastSubscriptionResponse?.run_date">
                  <td
                    v-if="
                      teacherProfilePayfastSubscriptionResponse.status_text ===
                      'ACTIVE'
                    "
                  >
                    {{ $i18n.t("payfastPackages.nextPayment") }}
                  </td>
                  <td v-else>{{ $i18n.t("headings.validUntil") }}</td>
                  <td>
                    {{
                      teacherProfilePayfastSubscriptionResponse.run_date.split(
                        "T"
                      )[0]
                    }}
                  </td>
                </tr>
                <tr v-if="teacherProfilePayfastSubscriptionResponse.frequency">
                  <td>{{ $i18n.t("otherText.frequency") }}</td>
                  <td>
                    {{
                      teacherProfilePayfastSubscriptionResponse.frequency === 3
                        ? $i18n.t("payfastPackages.titleMonthly")
                        : teacherProfilePayfastSubscriptionResponse.frequency ===
                          4
                        ? $i18n.t("payfastPackages.title3Monthly")
                        : teacherProfilePayfastSubscriptionResponse.frequency ===
                          5
                        ? $i18n.t("payfastPackages.title6Monthly")
                        : teacherProfilePayfastSubscriptionResponse.frequency ===
                          6
                        ? $i18n.t("payfastPackages.titleYearly")
                        : "Unknown"
                    }}
                  </td>
                </tr>
                <tr
                  v-if="
                    syncedTeacherProfileModel?.payfastSubscriptionPackage
                      ?.grades?.length > 0
                  "
                >
                  <td>{{ $i18n.t("headings.grades") }}</td>
                  <td>
                    {{
                      syncedTeacherProfileModel?.payfastSubscriptionPackage?.grades.join(
                        ", "
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div
            v-if="
              syncedTeacherProfileModel?.payfastSubscriptionPackage &&
              !syncedTeacherProfileModel.payfastSubscriptionPackage
                .grandFathered &&
              teacherProfilePayfastSubscriptionResponse?.subscriptionStillValid &&
              syncedTeacherProfileModel.payfastSubscriptionPackage.limit !== -1
            "
          >
            <div class="py-4">
              <span class="text-h6 text-left">
                {{ $i18n.t("headings.downloadQuota") }}
              </span>
            </div>

            <v-row>
              <v-col
                cols="12"
                sm="6"
                v-for="grade in syncedTeacherProfileModel
                  .payfastSubscriptionPackage.grades"
                :key="grade"
              >
                <v-row no-gutters class="align-center flex-nowrap">
                  <div style="width: 50px; min-width: 50px" class="mr-2">
                    <span class="font-weight-bold">{{
                      $i18n.t("headings.grade") + " " + grade
                    }}</span>
                    <!-- <span class="font-weight-bold">Grade 12</span> -->
                  </div>
                  <v-progress-linear
                    :value="calculateGradeProgress(grade)"
                    color="red darken-2"
                    height="7"
                    rounded
                    background-color="primary"
                  ></v-progress-linear>
                  <div style="width: 55px" class="d-flex justify-end ml-2">
                    <span class="text-caption">{{
                      calculateGradeDownloads(grade) +
                      " / " +
                      syncedTeacherProfileModel.payfastSubscriptionPackage.limit
                    }}</span>
                  </div>
                </v-row>
              </v-col>
            </v-row>

            <!-- <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th colspan="2" class="text-h6 text-left">
                      Download Quota
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="grade in syncedTeacherProfileModel
                      .payfastSubscriptionPackage.grades"
                    :key="grade"
                  >
                    <td>Grade {{ grade }}</td>
                    <td>
                      <v-slider
                        :value="calculateGradeDownloads(grade)"
                        color="secondary"
                        readonly
                        :max="
                          syncedTeacherProfileModel.payfastSubscriptionPackage
                            .limit
                        "
                        min="0"
                      ></v-slider>
                    </td>
                    <td>
                      {{
                        calculateGradeDownloads(grade) +
                        " / " +
                        syncedTeacherProfileModel.payfastSubscriptionPackage
                          .limit
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table> -->
          </div>
        </div>
        <!-- <div v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th colspan="2" class="text-h6 text-left">
                    Monthly Subscription Package
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Amount</td>
                  <td>R 200.00</td>
                </tr>
                <tr>
                  <td>Frequency</td>
                  <td>Monthly</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div> -->
      </v-card-text>
    </v-card>
    <TermsAndConditionsDialog
      v-if="dialogTermsAndConditions"
      @acceptTAC="acceptTAC"
      @closeDialogTermsAndConditions="closeDialogTermsAndConditions"
      :subscriptionComp="subscriptionComp"
    />
  </div>
</template>

<script>
import store from "@/store";
import { DataStore } from "aws-amplify";
import { TeacherProfile } from "@/models";
import { mapState } from "vuex";
import { API, Auth } from "aws-amplify";
import MD5 from "crypto-js/md5";
import Helper from "@/components/global-components/Helper.vue";
import TermsAndConditionsDialog from "@/core/services/TermsAndConditionsDialog.vue";
import _ from "lodash";

export default {
  name: "TeacherSubscription",
  components: { Helper, TermsAndConditionsDialog },
  data: () => ({
    carouselModel: 0,
    retryingPendingPackage: false,
    validSubPackageForm: false,
    selectedFrequency: null,
    selectedGrades: [],
    gradeArray: [
      "R",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      // "8",
      // "9",
      // "10",
      // "11",
      // "12",
    ],

    payfastPackages: [
      {
        title: "Monthly",
        frequency: 3,
        amount: 200,
        perGrade: 200,
        limit: 100,
        grandFathered: false,
      },
      {
        title: "3 Monthly",
        frequency: 4,
        amount: 550,
        perGrade: 550,
        limit: 300,
        grandFathered: false,
      },
      {
        title: "6 Monthly",
        frequency: 5,
        amount: 1050,
        perGrade: 1050,
        limit: 600,
        grandFathered: false,
      },
      {
        title: "Yearly",
        frequency: 6,
        amount: 2000,
        perGrade: 2000,
        limit: -1,
        grandFathered: false,
      },
    ],

    // syncedTeacherProfileModel: null,

    payFastError: null,

    dialogTermsAndConditions: false,
    subscriptionComp: true,

    checkingPayfastSubscription: false,
    updatingPayfastSubscription: false,
    handlingFormSubmit: false,
    // payFastResponse: null,
    // teacher: null,

    merchant_id: "17705781", //live
    // merchant_id: "10002308", //sandbox/dev

    merchant_key: "e9jy62j7ixjos", //live
    // merchant_key: "ho6e8zvucaysh", //sandbox/dev

    return_url: `${window.location.origin}/app/profile`,
    cancel_url: `${window.location.origin}/app/profile`,

    notify_url:
      "https://yfoov0qf5j.execute-api.eu-west-2.amazonaws.com/production/payfast/notify", //live production
    // notify_url:
    // "https://ztd24p80lg.execute-api.eu-west-2.amazonaws.com/staging/payfast/notify", //sandbox staging

    amount: 200, // ---- CHANGE RADIO
    item_name: "Miss Anris ClassNetwork Subscription",
    item_description: "Monthly Subscription", // ---- CHANGE RADIO
    subscription_type: 1,
    recurring_amount: 200, // ---- CHANGE RADIO
    frequency: 3, // ---- CHANGE RADIO
    cycles: 0,
    passphrase: "PSWwerkboek1e", //live
    // passphrase: "MissAnrisClassNetwork.763-489-77", //sandbox/dev
  }),

  async mounted() {
    try {
      // this.syncedTeacherProfileModel = await DataStore.query(
      //   TeacherProfile,
      //   this.teacherProfileID
      // );
      for (const payfastPackage of this.payfastPackages) {
        payfastPackage.title = this.$i18n.t(
          `payfastPackages.title${payfastPackage.title.replace(/ /g, "")}`
        );
      }
    } catch (error) {
      console.error(error.message);
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,

      userEmail: (state) => state.userEmail,
      syncedTeacherProfileModel: (state) => state.syncedTeacherProfileModel,
      teacherProfileID: (state) => state.teacherProfileID,
      teacherProfilePayfastSubscriptionToken: (state) =>
        state.teacherProfilePayfastSubscriptionToken,
      teacherProfilePayfastSubscriptionResponse: (state) =>
        state.teacherProfilePayfastSubscriptionResponse,
      teacherProfileAppSyncVersion: (state) =>
        state.teacherProfileAppSyncVersion,
      // mockSubscribedGrades: (state) => state.mockSubscribedGrades,
      // mockDownloadLimit: (state) => state.mockDownloadLimit,
    }),

    showPackageDataGrandFathered() {
      const currentPackage =
        this.syncedTeacherProfileModel.payfastSubscriptionPackage;
      const show =
        !currentPackage?.grandFathered ||
        (currentPackage.grandFathered &&
          currentPackage.frequency !== this.selectedFrequency)
          ? true
          : false;
      return show;
    },

    localSubPackageChanged() {
      if (this.updatingPayfastSubscription) return true;

      const gradesChanged = !this.syncedTeacherProfileModel
        ?.payfastSubscriptionPackage?.grades
        ? false
        : !_.isEqual(
            _.sortBy(
              this.syncedTeacherProfileModel.payfastSubscriptionPackage.grades
            ),
            _.sortBy(this.selectedGrades)
          );
      // JSON.stringify(
      //   this.syncedTeacherProfileModel?.payfastSubscriptionPackage?.grades
      // ) !== JSON.stringify(this.selectedGrades);

      const frequencyChanged =
        this.syncedTeacherProfileModel?.payfastSubscriptionPackage
          ?.frequency !== this.selectedFrequency;

      return (
        (gradesChanged || frequencyChanged) &&
        (!this.teacherProfilePayfastSubscriptionToken ||
        this.teacherProfilePayfastSubscriptionToken === "" ||
        (typeof this.teacherProfilePayfastSubscriptionResponse?.status_text ===
          "string" &&
          this.teacherProfilePayfastSubscriptionResponse.status_text !==
            "ACTIVE")
          ? false
          : true)
      );
    },

    signature() {
      const keyValue =
        "merchant_id=" +
        encodeURIComponent(this.merchant_id.trim()) +
        "&merchant_key=" +
        encodeURIComponent(this.merchant_key.trim()) +
        "&return_url=" +
        encodeURIComponent(this.return_url.trim()) +
        "&cancel_url=" +
        encodeURIComponent(this.cancel_url.trim()) +
        "&notify_url=" +
        encodeURIComponent(this.notify_url.trim()) +
        "&email_address=" +
        encodeURIComponent(this.userEmail.trim()) + // Local Data
        "&m_payment_id=" +
        this.m_payment_id + // Local Data
        "&amount=" +
        this.amount + // Local Data
        "&item_name=" +
        encodeURIComponent(this.item_name.trim()) +
        "&item_description=" +
        encodeURIComponent(this.item_description.trim()) +
        "&custom_str1=" +
        encodeURIComponent(this.teacherProfileID) + // Local Data
        "&custom_str2=" +
        encodeURIComponent(
          typeof this.teacherProfileAppSyncVersion === "string"
            ? parseInt(this.teacherProfileAppSyncVersion) + 1
            : 1
        ) + // Local Data
        "&subscription_type=" +
        this.subscription_type + // Local Data
        "&recurring_amount=" +
        this.recurring_amount + // Local Data
        "&frequency=" +
        this.frequency + // Local Data
        "&cycles=" +
        this.cycles + // Local Data
        "&passphrase=" +
        encodeURIComponent(this.passphrase.trim());

      // console.info(keyValue);

      // console.info(keyValue.replace(/%20/g, "+"));

      const hash = MD5(keyValue.replace(/%20/g, "+"));
      // console.info(hash.toString());

      return hash;
    },

    m_payment_id() {
      return Date.now();
    },

    teacherProfilePayfastCancelledText() {
      return this.teacherProfilePayfastSubscriptionResponse
        ?.subscriptionStillValid
        ? `${this.$i18n.t("status.cancelled")} - ${this.$i18n.t(
            "headings.validUntil"
          )} ${
            this.teacherProfilePayfastSubscriptionResponse.run_date.split(
              "T"
            )[0]
          }`
        : this.$i18n.t("status.cancelled");
    },

    payfastFrequencyGroup: {
      get() {
        if (!this.selectedFrequency) return null;

        const frequencyValue = this.selectedFrequency - 3;
        return (
          // this.teacherProfilePayfastSubscriptionResponse?.frequency || null
          // this.syncedTeacherProfileModel?.payfastSubscriptionPackage
          //   ?.frequency || null
          frequencyValue
        );
      },
      set(value) {
        const frequencyValue = value + 3;
        this.selectedFrequency = frequencyValue;
        if (frequencyValue === 3) {
          this.amount = 200;
          this.recurring_amount = 200;
          this.frequency = 3;
          this.item_name = "Miss Anris ClassNetwork Subscription";
          this.item_description =
            "Monthly / R200 per Month / Download limit 50 per Month";
        } else if (frequencyValue === 4) {
          this.amount = 550;
          this.recurring_amount = 550;
          this.frequency = 4;
          this.item_name = "Miss Anris ClassNetwork Subscription";
          this.item_description =
            "3 Monthly / R550 every 3 Months / Download limit 100 per Month";
        } else if (frequencyValue === 5) {
          this.amount = 1050;
          this.recurring_amount = 1050;
          this.frequency = 5;
          this.item_name = "Miss Anris ClassNetwork Subscription";
          this.item_description =
            "6 Monthly / R1050 every 6 Months / Download limit 200 per Month";
        } else if (frequencyValue === 6) {
          this.amount = 2000;
          this.recurring_amount = 2000;
          this.frequency = 6;
          this.item_name = "Miss Anris ClassNetwork Subscription";
          this.item_description =
            "Yearly / R2000 per Year / Downloads unlimited";
        }
      },
    },

    activePayfastPackages() {
      const activePackages = this.payfastPackages.filter(
        (item) =>
          item.frequency >=
          this.syncedTeacherProfileModel?.payfastSubscriptionPackage?.frequency
      );
      console.log("activePackages", activePackages);
      return activePackages;
    },

    disableCancelSubscription() {
      return (this.teacherProfilePayfastSubscriptionResponse
        ?.subscriptionStillValid &&
        this.syncedTeacherProfileModel?.payfastSubscriptionPackage
          ?.paymentMethod === "EFT") ||
        this.checkingPayfastSubscription ||
        this.updatingPayfastSubscription ||
        this.syncedTeacherProfileModel?.payfastSubscriptionPackage
          ?.pendingPackage
        ? true
        : false;
    },
  },
  watch: {
    syncedTeacherProfileModel: {
      handler: function (val) {
        if (val) {
          if (val.payfastSubscriptionPackage?.grades) {
            this.selectedGrades = [...val.payfastSubscriptionPackage.grades];
          }
          if (val.payfastSubscriptionPackage?.frequency) {
            this.selectedFrequency = val.payfastSubscriptionPackage.frequency;
          }
        }
      },
      immediate: true,
    },
    teacherProfilePayfastSubscriptionToken: {
      handler: async function (val) {
        if (val) {
          // await this.checkPayfastSubscription();
        }
      },
      immediate: true,
    },
  },

  methods: {
    showTACDialog() {
      this.dialogTermsAndConditions = true;
    },
    disablePackage(frequency) {
      if (
        (this.teacherProfilePayfastSubscriptionResponse
          ?.subscriptionStillValid &&
          this.syncedTeacherProfileModel?.payfastSubscriptionPackage
            ?.paymentMethod === "EFT") ||
        this.updatingPayfastSubscription ||
        this.handlingFormSubmit ||
        this.checkingPayfastSubscription ||
        (this.syncedTeacherProfileModel?.payfastSubscriptionPackage
          ?.pendingPackage &&
          !this.syncedTeacherProfileModel?.payfastSubscriptionPackage
            .createSub &&
          (!this.teacherProfilePayfastSubscriptionResponse?.status_text ||
            this.teacherProfilePayfastSubscriptionResponse.status_text ===
              "ACTIVE"))
      )
        return true;

      if (
        this.syncedTeacherProfileModel &&
        !this.syncedTeacherProfileModel?.payfastSubscriptionPackage
      )
        return false;

      if (
        this.teacherProfilePayfastSubscriptionResponse
          ?.subscriptionStillValid &&
        this.syncedTeacherProfileModel?.payfastSubscriptionPackage?.frequency >
          frequency
      ) {
        return true;
      } else {
        return false;
      }
    },

    validateForm() {
      if (this.$refs.subPackageForm) this.$refs.subPackageForm.validate();
    },

    retryPendingPackage() {
      this.retryingPendingPackage = true;
      this.showTACDialog();
    },

    async clearPendingPackage() {
      const newPackage = this.syncedTeacherProfileModel
        .payfastSubscriptionPackage?.frequency
        ? _.cloneDeep(this.syncedTeacherProfileModel.payfastSubscriptionPackage)
        : null;

      if (newPackage.pendingPackage) {
        delete newPackage.pendingPackage;
      }
      if (newPackage.upgrade) {
        delete newPackage.upgrade;
      }

      await DataStore.save(
        TeacherProfile.copyOf(this.syncedTeacherProfileModel, (updateModel) => {
          updateModel.payfastSubscriptionPackage = newPackage;
        })
      );
    },

    async updatePayfastSubscriptionPackage(pendingPackage) {
      this.updatingPayfastSubscription = true;

      try {
        const newPackage = pendingPackage
          ? pendingPackage
          : {
              ...this.payfastPackages.find(
                (item) => item.frequency === this.selectedFrequency
              ),
              grades: [...new Set(this.selectedGrades)],
              // createdAt: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
              // run_date: this.getRunDate(this.selectedFrequency),
            };

        if (
          this.syncedTeacherProfileModel.payfastSubscriptionPackage
            ?.grandFathered &&
          newPackage.frequency ===
            this.syncedTeacherProfileModel.payfastSubscriptionPackage.frequency
        ) {
          newPackage.grandFathered = true;
        }

        console.log(
          "updatePayfastSubscriptionPackage : newPackage",
          newPackage
        );

        const {
          amountToPay,
          daysInNewPackage,
          // , remainingCredit
        } = this.calculateProRata(
          this.syncedTeacherProfileModel.payfastSubscriptionResponse.run_date,
          this.syncedTeacherProfileModel.payfastSubscriptionPackage,
          newPackage
        );

        console.log(
          "calculateProRata",
          amountToPay,
          daysInNewPackage
          // remainingCredit
        );

        this.amount = amountToPay;
        console.log("this.amount", this.amount);

        this.recurring_amount = (
          (newPackage.grades.length > 1
            ? newPackage.perGrade * (newPackage.grades.length - 1) +
              newPackage.amount
            : newPackage.amount) * 100
        ).toFixed(0);
        console.log("recurring_amount", this.recurring_amount);

        console.log(
          "Update Package Action: ",
          amountToPay > 0 ? "Upgrade" : "Downgrade"
        );

        if (amountToPay > 0) {
          if (!pendingPackage) {
            await DataStore.save(
              TeacherProfile.copyOf(
                this.syncedTeacherProfileModel,
                (updateModel) => {
                  updateModel.payfastSubscriptionPackage =
                    typeof this.syncedTeacherProfileModel
                      .payfastSubscriptionPackage === "object"
                      ? {
                          ...this.syncedTeacherProfileModel
                            .payfastSubscriptionPackage,
                          pendingPackage: newPackage,
                          upgrade: true,
                        }
                      : { pendingPackage: newPackage, upgrade: true };
                }
              )
            ).then(() => {
              this.payWithPayfast(amountToPay);
            });
          } else {
            this.payWithPayfast(amountToPay, true);
          }
          // bodyToUpdate.amount = (amountToPay * 100).toFixed(0);
          // return;
        } else {
          const bodyToUpdate = {
            teacherProfileID: this.teacherProfileID,
            package: newPackage,
            payfastSubscriptionToken:
              this.teacherProfilePayfastSubscriptionToken,
            recurring_amount: this.recurring_amount,
          };

          console.log("bodyToUpdate", bodyToUpdate);

          await API.post("payfastsubscriptionapi", "/payfast/update", {
            body: bodyToUpdate,
          })
            .catch((error) => {
              // Log any errors
              console.log("error:", error);

              DataStore.save(
                TeacherProfile.copyOf(
                  this.syncedTeacherProfileModel,
                  (updateModel) => {
                    updateModel.payfastSubscriptionPackage =
                      typeof this.syncedTeacherProfileModel
                        .payfastSubscriptionPackage === "object"
                        ? {
                            ...this.syncedTeacherProfileModel
                              .payfastSubscriptionPackage,
                            pendingPackage: newPackage,
                          }
                        : { pendingPackage: newPackage };
                  }
                )
              );
            })
            .then(async (response) => {
              // console.log("response:", response);
              // console.log(
              //   "response.success:",
              //   response.success ? "true" : "false"
              // );

              if (
                response?.success
                //  && (!amountToPay || amountToPay <= 0)
              ) {
                DataStore.save(
                  TeacherProfile.copyOf(
                    this.syncedTeacherProfileModel,
                    (updateModel) => {
                      updateModel.payfastSubscriptionPackage = newPackage;
                    }
                  )
                );
              } else {
                DataStore.save(
                  TeacherProfile.copyOf(
                    this.syncedTeacherProfileModel,
                    (updateModel) => {
                      updateModel.payfastSubscriptionPackage =
                        typeof this.syncedTeacherProfileModel
                          .payfastSubscriptionPackage === "object"
                          ? {
                              ...this.syncedTeacherProfileModel
                                .payfastSubscriptionPackage,
                              pendingPackage: newPackage,
                            }
                          : { pendingPackage: newPackage };
                    }
                  )
                );
              }

              this.updatingPayfastSubscription = false;
              await this.checkPayfastSubscription();
            });
        }
      } catch (error) {
        console.error(error);
        this.updatingPayfastSubscription = false;
        // } finally {
        //   this.updatingPayfastSubscription = false;
      }
    },

    payWithPayfast(amount, retryingPayment) {
      console.log("this.userEmail", this.userEmail);
      const data = {
        merchant_id: this.merchant_id.trim(),
        merchant_key: this.merchant_key.trim(),
        return_url: this.return_url.trim(),
        cancel_url: this.cancel_url.trim(),
        notify_url: this.notify_url.trim(),
        email_address: this.userEmail.trim(),
        m_payment_id: this.m_payment_id,
        amount: amount,
        item_name: "Miss Anris ClassNetwork Pro-Rata Payment",
        custom_str1: this.teacherProfileID, // Local Data
        custom_str2:
          typeof this.teacherProfileAppSyncVersion === "string"
            ? retryingPayment
              ? parseInt(this.teacherProfileAppSyncVersion)
              : parseInt(this.teacherProfileAppSyncVersion) + 1
            : 1,
      };

      // Generate the signature using the same logic as the computed property
      const keyValue =
        "merchant_id=" +
        encodeURIComponent(data.merchant_id) +
        "&merchant_key=" +
        encodeURIComponent(data.merchant_key) +
        "&return_url=" +
        encodeURIComponent(data.return_url) +
        "&cancel_url=" +
        encodeURIComponent(data.cancel_url) +
        "&notify_url=" +
        encodeURIComponent(data.notify_url) +
        "&email_address=" +
        encodeURIComponent(data.email_address) +
        "&m_payment_id=" +
        data.m_payment_id +
        "&amount=" +
        data.amount +
        "&item_name=" +
        encodeURIComponent(data.item_name) +
        "&custom_str1=" +
        encodeURIComponent(data.custom_str1) +
        "&custom_str2=" +
        encodeURIComponent(data.custom_str2) +
        "&passphrase=" +
        encodeURIComponent(this.passphrase.trim());

      console.info("keyValue", keyValue);
      console.info(
        'keyValue.replace(/%20/g, "+")',
        keyValue.replace(/%20/g, "+")
      );

      const paymentSignature = MD5(keyValue.replace(/%20/g, "+"));
      console.info("paymentSignature.toString()", paymentSignature.toString());

      data["signature"] = paymentSignature.toString();

      const form = document.createElement("form");
      form.action = "https://www.payfast.co.za/eng/process"; //live
      // form.action = "https://sandbox.payfast.co.za/eng/process"; //sandbox/dev
      form.method = "post";
      form.style.display = "none";

      for (let key in data) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
      }

      document.body.appendChild(form);
      form.submit();
    },

    async createPayfastSubscription() {
      try {
        this.handlingFormSubmit = true;

        const newPackage = {
          ...this.payfastPackages.find(
            (item) => item.frequency === this.selectedFrequency
          ),
          grades: [...new Set(this.selectedGrades)],
          // createdAt: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
          // run_date: this.getRunDate(this.selectedFrequency),
        };

        console.log("acceptTAC", newPackage);
        await DataStore.save(
          TeacherProfile.copyOf(
            this.syncedTeacherProfileModel,
            (updateModel) => {
              updateModel.payfastSubscriptionPackage =
                typeof this.syncedTeacherProfileModel
                  .payfastSubscriptionPackage === "object"
                  ? {
                      ...this.syncedTeacherProfileModel
                        .payfastSubscriptionPackage,
                      pendingPackage: newPackage,
                      createSub: true,
                    }
                  : { pendingPackage: newPackage, createSub: true };
            }
          )
        );

        console.log(
          "createPayfastSubscription",
          newPackage,
          this.selectedGrades
        );

        if (
          !this.syncedTeacherProfileModel?.payfastSubscriptionPackage ||
          this.teacherProfilePayfastSubscriptionResponse
            ?.subscriptionStillValid !== true
        ) {
          const newAmount =
            newPackage.grades.length > 1
              ? newPackage.perGrade * (newPackage.grades.length - 1) +
                newPackage.amount
              : newPackage.amount;
          this.amount = newAmount;
          this.recurring_amount = newAmount;
        } else {
          const { amountToPay, daysInNewPackage, remainingCredit } =
            this.calculateProRata(
              this.syncedTeacherProfileModel.payfastSubscriptionResponse
                .run_date,
              this.syncedTeacherProfileModel.payfastSubscriptionPackage,
              newPackage
            );

          this.amount = amountToPay;

          this.recurring_amount =
            newPackage.grades.length > 1
              ? newPackage.perGrade * (newPackage.grades.length - 1) +
                newPackage.amount
              : newPackage.amount;
        }

        console.log("this.amount", this.amount);
        console.log("recurring_amount", this.recurring_amount);

        this.$nextTick(() => {
          // If everything checks out, submit the form programmatically
          this.$refs.hiddenSubmitButton.click();
        });
        // this.submitForm();
      } catch (error) {
        console.log("error", error);
      }
    },

    getRunDate(frequency) {
      // Ensure the input is within the range of 3 to 6
      if (frequency < 3 || frequency > 6) {
        throw new Error("Input frequency must be between 3 and 6");
      }

      // Get today's date
      let currentDate = new Date(new Date().setHours(0, 0, 0, 0));

      // Calculate the future date based on the input frequency
      switch (frequency) {
        case 3:
          currentDate.setMonth(currentDate.getMonth() + 1);
          break;
        case 4:
          currentDate.setMonth(currentDate.getMonth() + 3);
          break;
        case 5:
          currentDate.setMonth(currentDate.getMonth() + 6);
          break;
        case 6:
          currentDate.setFullYear(currentDate.getFullYear() + 1);
          break;
      }

      // Format the date as 'YYYY-MM-DD'
      let year = currentDate.getFullYear();
      let month = String(currentDate.getMonth() + 1).padStart(2, "0");
      let day = String(currentDate.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },

    // calculateProRata(run_date, oldPackage, newPackage) {
    //   console.log("ProRata Packages", oldPackage, newPackage);
    //   console.log("ProRata Run_date", run_date);

    //   const runDate = new Date(run_date);
    //   // const runDateNew = new Date(newPackage.run_date);
    //   const upgradeAt = new Date(new Date().setHours(0, 0, 0, 0)); // assuming the upgrade happens now; adjust if needed
    //   console.log("runDate", runDate);
    //   // console.log("runDateNew", runDateNew);
    //   console.log("upgradeAt", upgradeAt);
    //   console.log(
    //     "runDate.getTime() - upgradeAt.getTime()",
    //     runDate.getTime() - upgradeAt.getTime()
    //   );

    //   // // Calculate days used in the old package
    //   // const daysUsed = Math.floor((runDateOld - upgradeAt) / (1000 * 3600 * 24));
    //   // console.log("daysUsed", daysUsed);

    //   // // Calculate end date of the current package based on its frequency
    //   // const oldPackageEndDate = this.addMonths(
    //   //   runDateOld,
    //   //   this.getMonthsFromFrequency(oldPackage.frequency)
    //   // );
    //   // console.log("oldPackageEndDate", oldPackageEndDate);
    //   let daysInOldPackage = 0;

    //   let oldPackageStart = new Date(run_date);

    //   console.log("oldPackageStart", oldPackageStart);

    //   if (oldPackage.frequency === 6) {
    //     oldPackageStart.setFullYear(oldPackageStart.getFullYear() - 1);
    //   } else {
    //     oldPackageStart.setMonth(
    //       oldPackageStart.getMonth() -
    //         (oldPackage.frequency === 3
    //           ? 1
    //           : oldPackage.frequency === 4
    //           ? 3
    //           : oldPackage.frequency === 5
    //           ? 6
    //           : 0)
    //     );
    //   }

    //   console.log("oldPackageStart2", oldPackageStart);
    //   // After adjusting oldPackageStart, calculate daysInOldPackage
    //   daysInOldPackage = Math.floor(
    //     (runDate.getTime() - oldPackageStart.getTime()) / (1000 * 3600 * 24)
    //   );
    //   console.log("daysInOldPackage", daysInOldPackage);

    //   // console.log("daysInOldPackage", daysInOldPackage);
    //   const daysRemaining = Math.floor(
    //     (runDate.getTime() - upgradeAt.getTime()) / (1000 * 3600 * 24)
    //   );
    //   console.log("daysRemaining", daysRemaining);

    //   // Calculate total cost of the old package based on grades
    //   const totalOldPackageCost =
    //     oldPackage.grades.length > 1
    //       ? oldPackage.perGrade * (oldPackage.grades.length - 1) +
    //         oldPackage.amount
    //       : oldPackage.amount;
    //   const dailyRateOldPackage = totalOldPackageCost / daysInOldPackage;

    //   // Calculate remaining credit
    //   const remainingCredit = daysRemaining * dailyRateOldPackage;

    //   // Calculate the total days for the new package from the upgrade date
    //   // const newPackageEndDate = this.addMonths(
    //   //   upgradeAt,
    //   //   this.getMonthsFromFrequency(newPackage.frequency)
    //   // );
    //   // console.log("newPackageEndDate", newPackageEndDate);
    //   const daysInNewPackage = Math.floor(
    //     (runDate.getTime() - upgradeAt.getTime()) / (1000 * 3600 * 24)
    //   );

    //   // Calculate total cost of the new package based on grades
    //   const totalNewPackageCost =
    //     newPackage.grades.length > 1
    //       ? newPackage.perGrade * (newPackage.grades.length - 1) +
    //         newPackage.amount
    //       : newPackage.amount;

    //   // Calculate the final amount to pay for the new package
    //   const amountToPay = totalNewPackageCost - remainingCredit;

    //   console.log("runDate.getTime()", runDate.getTime());
    //   // Ensure the amount to pay doesn't go negative
    //   return {
    //     amountToPay: parseFloat(Math.max(amountToPay, 0).toFixed(0)), // Ensuring the value returned is also fixed to 0 decimal places
    //     daysInNewPackage: daysInNewPackage,
    //     remainingCredit: parseFloat(remainingCredit.toFixed(0)), // Ensure it's a number with 0 decimals
    //   };
    // },

    calculateProRata(run_date, oldPackage, newPackage) {
      console.log("ProRata Packages", oldPackage, newPackage);
      console.log("ProRata Run_date", run_date);

      const runDate = new Date(new Date(run_date).setHours(0, 0, 0, 0));
      const upgradeAt = new Date(new Date().setHours(0, 0, 0, 0)); // assuming the upgrade happens today

      console.log("runDate", runDate);
      console.log("upgradeAt", upgradeAt);

      // Calculate remaining days in the current package period
      const daysRemaining = Math.ceil(
        (runDate.getTime() - upgradeAt.getTime()) / (1000 * 3600 * 24)
      );
      console.log("daysRemaining", daysRemaining);

      let newPackageNextRunDate = null;
      if (daysRemaining <= 0) {
        newPackageNextRunDate = new Date(upgradeAt);

        if (newPackage.frequency === 6) {
          newPackageNextRunDate.setFullYear(
            newPackageNextRunDate.getFullYear() + 1
          );
        } else {
          newPackageNextRunDate.setMonth(
            newPackageNextRunDate.getMonth() +
              (newPackage.frequency === 3
                ? 1
                : newPackage.frequency === 4
                ? 3
                : newPackage.frequency === 5
                ? 6
                : 0)
          );
        }
      } else {
        newPackageNextRunDate = runDate;
      }

      console.log("newPackageNextRunDate", newPackageNextRunDate);

      let daysInNewPackage = 0;

      if (daysRemaining > 0) {
        daysInNewPackage = daysRemaining;
      } else {
        daysInNewPackage = Math.floor(
          (newPackageNextRunDate.getTime() - upgradeAt.getTime()) /
            (1000 * 3600 * 24)
        );
      }

      console.log("daysInNewPackage", daysInNewPackage);

      // Calculate the total days for the new package from the upgrade date
      const newPackageTheoreticalEndDate = this.addMonths(
        upgradeAt,
        this.getMonthsFromFrequency(newPackage.frequency)
      );

      const totalDaysInNewPackage = Math.floor(
        (newPackageTheoreticalEndDate.getTime() - upgradeAt.getTime()) /
          (1000 * 3600 * 24)
      );
      console.log("totalDaysInNewPackage", totalDaysInNewPackage);
      // Calculate total cost of the new package based on grades
      const totalNewPackageCost =
        newPackage.grades.length > 1
          ? newPackage.perGrade * (newPackage.grades.length - 1) +
            newPackage.amount
          : newPackage.amount;
      console.log("totalNewPackageCost", totalNewPackageCost);

      const dailyRateNewPackage = totalNewPackageCost / totalDaysInNewPackage;

      // Calculate cost for any additional grades in the new package not present in the old package
      // const newGradesCost = newPackage.grades
      //   // .filter((grade) => !oldPackage.grades.includes(grade))
      //   .reduce(
      //     (acc, curr) => acc + dailyRatePerNewGrade * daysInNewPackage,
      //     0
      //   );

      // Calculate the final amount to pay
      let amountToPay = dailyRateNewPackage * daysInNewPackage;

      // // Add cost of new grades
      // amountToPay += newGradesCost;

      if (daysRemaining > 0 && oldPackage) {
        let oldPackageStart = new Date(new Date(run_date).setHours(0, 0, 0, 0));

        console.log("oldPackageStart", oldPackageStart);

        if (oldPackage.frequency === 6) {
          oldPackageStart.setFullYear(oldPackageStart.getFullYear() - 1);
        } else {
          oldPackageStart.setMonth(
            oldPackageStart.getMonth() -
              (oldPackage.frequency === 3
                ? 1
                : oldPackage.frequency === 4
                ? 3
                : oldPackage.frequency === 5
                ? 6
                : 0)
          );
        }

        console.log("oldPackageStart2", oldPackageStart);
        // After adjusting oldPackageStart, calculate totalDaysInOldPackage
        const totalDaysInOldPackage = Math.floor(
          (runDate.getTime() - oldPackageStart.getTime()) / (1000 * 3600 * 24)
        );
        console.log("totalDaysInOldPackage", totalDaysInOldPackage);

        // Calculate total cost of the old package based on grades
        const totalOldPackageCost =
          oldPackage.grades?.length > 1
            ? oldPackage.perGrade * (oldPackage.grades.length - 1) +
              oldPackage.amount
            : oldPackage.amount;
        const dailyRateOldPackage = totalOldPackageCost / totalDaysInOldPackage;

        // Calculate remaining credit
        const remainingCredit = daysRemaining * dailyRateOldPackage;
        console.log("remainingCredit", remainingCredit);
        if (remainingCredit > 0) {
          amountToPay -= remainingCredit;
        }
      }

      // If the base cost of the new package is higher, add the difference multiplied by the days remaining
      // if (daysRemaining > 0 && newPackage.amount > oldPackage.amount) {
      //   amountToPay +=
      //     ((newPackage.amount - oldPackage.amount) / daysInNewPackage) *
      //     daysRemaining;
      // }

      // console.log("dailyRatePerNewGrade", dailyRatePerNewGrade);
      // console.log("newGradesCost", newGradesCost);

      return {
        amountToPay: parseFloat(Math.max(amountToPay, 0).toFixed(0)), // Ensure the amount to pay doesn't go negative
        daysRemaining: daysRemaining,
        // dailyRateNewPackage: dailyRateNewPackage,
      };
    },

    getMonthsFromFrequency(frequency) {
      switch (frequency) {
        case 3:
          return 1; // Monthly
        case 4:
          return 3; // Every 3 Months
        case 5:
          return 6; // Every 6 Months
        case 6:
          return 12; // Yearly
        default:
          return 0; // Error case
      }
    },

    addMonths(date, months) {
      const result = new Date(date);
      result.setMonth(result.getMonth() + months);
      return result;
    },

    // subscriptionPackageGradeValue(grade) {
    //   return this.syncedTeacherProfileModel?.payfastSubscriptionPackage.grades.includes(
    //     grade
    //   );
    // },

    calculateGradeProgress(grade) {
      if (
        !this.syncedTeacherProfileModel?.productSubscriptions ||
        this.syncedTeacherProfileModel?.productSubscriptions.length === 0 ||
        this.syncedTeacherProfileModel?.payfastSubscriptionPackage?.limit === -1
      )
        return 0;
      const now = new Date();
      const currentYear = now.getFullYear();
      const currentMonth = now.getMonth();

      // Filter the subscriptions that match the given grade and have a timestamp within the current month
      const downloadsThisMonth =
        this.syncedTeacherProfileModel.productSubscriptions.filter(
          (subscription) => {
            if (!subscription.grade || subscription.grade !== grade) {
              return false;
            }

            const timestampDate = new Date(subscription.timeStamp);

            return (
              timestampDate.getFullYear() === currentYear &&
              timestampDate.getMonth() === currentMonth
            );
          }
        );

      const downloadProgressThisMonth =
        (downloadsThisMonth.length /
          this.syncedTeacherProfileModel.payfastSubscriptionPackage.limit) *
        100;

      return downloadProgressThisMonth;
    },

    calculateGradeDownloads(grade) {
      if (!this.syncedTeacherProfileModel?.productSubscriptions?.length > 0)
        return 0;
      const now = new Date();
      const currentYear = now.getFullYear();
      const currentMonth = now.getMonth();

      // Filter the subscriptions that match the given grade and have a timestamp within the current month
      const downloadsThisMonth =
        this.syncedTeacherProfileModel.productSubscriptions.filter(
          (subscription) => {
            if (!subscription.grade || subscription.grade !== grade) {
              return false;
            }

            const timestampDate = new Date(subscription.timeStamp);

            return (
              timestampDate.getFullYear() === currentYear &&
              timestampDate.getMonth() === currentMonth
            );
          }
        );

      return downloadsThisMonth.length;
    },

    closeDialogTermsAndConditions() {
      this.dialogTermsAndConditions = false;
      this.retryingPendingPackage = false;
      // this.termsAndConditionsAccepted = false;
    },

    async acceptTAC() {
      this.dialogTermsAndConditions = false;

      try {
        if (this.localSubPackageChanged || this.retryingPendingPackage) {
          if (this.retryingPendingPackage) {
            await this.updatePayfastSubscriptionPackage(
              this.syncedTeacherProfileModel.payfastSubscriptionPackage
                .pendingPackage
            );
          } else {
            await this.updatePayfastSubscriptionPackage();
          }
        } else {
          await this.createPayfastSubscription();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.retryingPendingPackage = false;
      }
    },

    // async submitForm() {
    //   // if (!this.teacherProfileAppSyncVersion ||
    //   //     this.teacherProfilePayfastSubscriptionToken &&
    //   //     this.teacherProfilePayfastSubscriptionResponse.status_text === 'CANCELLED') {
    //   //   return;
    //   // }

    //   let formData = new FormData();
    //   formData.append("merchant_id", this.merchant_id);
    //   formData.append("merchant_key", this.merchant_key);
    //   formData.append("return_url", this.return_url);
    //   formData.append("cancel_url", this.cancel_url);
    //   formData.append("notify_url", this.notify_url);
    //   formData.append("email_address", this.userEmail);
    //   formData.append("m_payment_id", this.m_payment_id);
    //   formData.append("amount", this.amount);
    //   formData.append("item_name", this.item_name);
    //   formData.append("item_description", this.item_description);
    //   formData.append("custom_str1", this.teacherProfileID);
    //   formData.append(
    //     "custom_str2",
    //     typeof this.teacherProfileAppSyncVersion === "string"
    //       ? parseInt(this.teacherProfileAppSyncVersion) + 1
    //       : 1
    //   );
    //   formData.append("subscription_type", this.subscription_type);
    //   formData.append("recurring_amount", this.recurring_amount);
    //   formData.append("frequency", this.frequency);
    //   formData.append("cycles", this.cycles);
    //   formData.append("signature", this.signature);

    //   await fetch("https://sandbox.payfast.co.za/eng/process", {
    //     method: "POST",
    //     mode: "no-cors", // Setting the mode to no-cors
    //     // headers: {
    //     //   "Content-Type": "application/x-www-form-urlencoded",
    //     // },
    //     body: formData,
    //   });
    //   // .then((response) => response.json())
    //   // .then((data) => console.log(data))
    //   // .catch((error) => console.error("Error:", error));

    //   await DataStore.save(
    //     TeacherProfile.copyOf(this.syncedTeacherProfileModel, (updateModel) => {
    //       updateModel.payfastSubscriptionPackage = {
    //         grades: this.selectedGrades,
    //         ...this.payfastPackages.find(
    //           (item) => item.frequency === this.selectedFrequency
    //         ),
    //       };
    //     })
    //   );

    //   // console.log("submitForm", this.$refs.hiddenSubmitButton);
    //   // If everything checks out, submit the form programmatically
    //   // this.$refs.hiddenSubmitButton.click();
    // },

    // async queryTeacherProfile() {
    //   this.teacher = await DataStore.query(
    //     TeacherProfile,
    //     this.teacherProfileID
    //   );

    //   // console.log("queryTeacherProfile", this.teacher, this.teacher._version);
    // },
    // async processingPayfastSubscription() {

    //   if (typeof this.teacher !== "undefined") {
    //     const updatedTeacher = await DataStore.save(
    //       TeacherProfile.copyOf(this.teacher, (updateModel) => {
    //         updateModel.payfastSubscriptionToken = "PROCESSING";
    //       })
    //     );
    //     console.log(
    //       "processingPayfastSubscription",
    //       updatedTeacher,
    //       updatedTeacher._version
    //     );
    //     return true;
    //   }
    //   return false;
    // },
    async handleFormSubmit() {
      this.dialogTermsAndConditions = true;
      // const result = await this.processingPayfastSubscription();
      // if (!result) {
      //   return;
      // }
      // Your logic or checks here...
      // console.log("handleFormSubmit", this.$refs.hiddenSubmitButton);
      // // If everything checks out, submit the form programmatically
      // this.$refs.hiddenSubmitButton.click();
    },
    async checkPayfastSubscription() {
      console.log(
        "TeacherSubscription.checkPayfastSubscription",
        this.teacherProfilePayfastSubscriptionToken
      );

      // this.payFastResponse = null;

      if (!this.teacherProfilePayfastSubscriptionToken) {
        return;
      } else if (this.teacherProfilePayfastSubscriptionToken === "PROCESSING") {
        return;
      }

      this.checkingPayfastSubscription = true;

      let subscriptionStillValid = false;

      await API.get(
        "payfastsubscriptionapi",
        `/payfast/status/${this.teacherProfilePayfastSubscriptionToken}`
      )
        .then(async (response) => {
          console.log("checkPayfastSubscription response", response);
          if (
            this.teacherProfilePayfastSubscriptionResponse !==
            response.success.data.response
          ) {
            await DataStore.save(
              TeacherProfile.copyOf(
                this.syncedTeacherProfileModel,
                (updateModel) => {
                  updateModel.payfastSubscriptionResponse =
                    typeof response.success.data.response === "object"
                      ? response.success.data.response
                      : // {
                        //     ...response.success.data.response,
                        //     run_date: "2024-07-21T00:00:00+02:00",
                        //   }
                        null;
                }
              )
            );

            // store.commit(
            //   "storeTeacherProfilePayfastSubscriptionResponse",
            //   response.success.data.response
            // );
          }

          const user = await Auth.currentAuthenticatedUser().catch(() => {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
          });

          if (user) {
            if (response.success.data.response.run_date) {
              const runDate = new Date(response.success.data.response.run_date);
              const currentDate = new Date();
              subscriptionStillValid = runDate > currentDate;
              // Compare the dates
            }

            await API.post("cognitolambdaapi", "/item/listUserGroups", {
              body: {
                userPoolId: user.pool.userPoolId,
                userName: user.username,
              },
            })
              .then(async (cognitoListUserGroupsResponse) => {
                if (cognitoListUserGroupsResponse.list) {
                  let userGroups = JSON.parse(
                    cognitoListUserGroupsResponse.list
                  );

                  if (
                    response.success.data.response?.status_text === "ACTIVE" ||
                    subscriptionStillValid
                  ) {
                    if (!userGroups.includes("teacher")) {
                      // User is not part of the 'teacher' group
                      // console.log("User is not a teacher");

                      await API.post(
                        "cognitolambdaapi",
                        "/item/addUserToGroup",
                        {
                          body: {
                            groupName: "teacher",
                            userPoolId: user.pool.userPoolId,
                            userName: user.username,
                          },
                        }
                      )
                        .then(() => {
                          userGroups.push("teacher");
                          store.commit("storeUserGroups", userGroups);
                        })
                        .catch((error) => {
                          // Log any errors
                          console.log("error:", error);
                        });
                    }
                  } else if (userGroups.includes("teacher")) {
                    await API.post(
                      "cognitolambdaapi",
                      "/item/removeUserFromGroup",
                      {
                        body: {
                          groupName: "teacher",
                          userPoolId: user.pool.userPoolId,
                          userName: user.username,
                        },
                      }
                    )
                      .then(() => {
                        let index = userGroups.indexOf("teacher");

                        if (index !== -1) {
                          userGroups.splice(index, 1);
                        }

                        store.commit("storeUserGroups", userGroups);
                      })
                      .catch((error) => {
                        // Log any errors
                        console.log("error:", error);
                      });
                  }
                }
              })
              .catch((error) => {
                // Log any errors
                console.log("error:", error);
              });
          }

          this.payFastError = null;
          // this.payFastResponse = response.success.data.response;

          store.commit("storeTeacherProfilePayfastSubscriptionResponse", {
            ...response.success.data.response,
            // run_date: "2024-07-21T00:00:00+02:00",
            subscriptionStillValid,
          });

          // Handle successful response from the API
          this.checkingPayfastSubscription = false;
          // console.log(response);
        })
        .catch((error) => {
          // Log any errors
          this.payFastError = error.message;
          this.checkingPayfastSubscription = false;
          console.error(error.message);

          store.commit(
            "storeTeacherProfilePayfastSubscriptionResponse",
            this.syncedTeacherProfileModel
              ? {
                  ...this.syncedTeacherProfileModel.payfastSubscriptionResponse,
                  subscriptionStillValid,
                }
              : null
          );
        });
    },
    async cancelPayfastSubscription() {
      // this.payFastResponse = null;
      // const result = await this.processingPayfastSubscription();
      // if (!result) {
      //   return;
      // }

      if (!this.teacherProfilePayfastSubscriptionToken) {
        return;
      } else if (this.teacherProfilePayfastSubscriptionToken === "PROCESSING") {
        return;
      }

      // console.log("checkPayfastSubscription");

      this.checkingPayfastSubscription = true;

      await API.put(
        "payfastsubscriptionapi",
        `/payfast/cancel/${this.teacherProfilePayfastSubscriptionToken}`
      )
        .then((response) => {
          if (
            this.teacherProfilePayfastSubscriptionResponse !==
            response.success.data.response
          ) {
            store.commit(
              "storeTeacherProfilePayfastSubscriptionResponse",
              response.success.data.response
            );
          }
          this.payFastError = null;
          // this.payFastResponse = response.success.data.response;

          // Handle successful response from the API
          this.checkingPayfastSubscription = false;
          // console.log(response.success.data.response);
        })
        .catch((error) => {
          // Log any errors
          this.payFastError = error.message;
          this.checkingPayfastSubscription = false;
          console.error(error.message);
        });

      await this.checkPayfastSubscription();
    },
  },
};
</script>
