<template>
  <div id="adminsubjects">
    <div class="pa-2">
      <v-row justify="space-between">
        <v-col cols="12">
          <Subjects />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import Subjects from "@/components/admin-components/teacher/Subjects.vue";
export default {
  name: "AdminSubjects",
  components: { Subjects },
  data: () => ({}),
};
</script>